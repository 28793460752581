import { TOKEN_KEY, SKIP_TUTORIAL_KEY } from '~/settings';
import { clearIndexedDB } from '~/idb';

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const isAuth = loginMinutes => {
  if (getToken() !== null) {
    if (loginMinutes) {
      if (lastLoginTimestamp()) {
        if (lastLoginTimestamp() + loginMinutes * 60 < nowTimestamp()) {
          logoff();
          return false;
        } else {
          return true;
        }
      } else {
        logoff();
        return false;
      }
    } else {
      return true;
    }
  }
  return false;
};

const nowTimestamp = () => new Date().getTime() / 1000;

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem('last_login_timestamp', nowTimestamp());
  clearIndexedDB(); // Removendo databases do indexedDB
};

export const upadateTokenKey = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

const lastLoginTimestamp = () => {
  var aux = localStorage.getItem('last_login_timestamp');
  if (aux) {
    return parseInt(aux);
  }
};

export const logoff = () => {
  localStorage.removeItem('days_access');
  localStorage.removeItem(SKIP_TUTORIAL_KEY);
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem('last_login_timestamp');
  // localStorage.removeItem(`@${REACT_APP_SITE}/organizationId`);
  localStorage.removeItem('@USEFLOW/mobile-embedded-session');
  clearIndexedDB(); // Removendo databases do indexedDB
};
