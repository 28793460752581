import styled from 'styled-components';

export const StyledCodeInput = styled.div.attrs(() => ({
  className: 'code-input'
}))`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .input-wrapper {
    display: flex;
    flex-direction: column;

    label {
      margin-left: 4px;
    }
  }

  .react-code-input {
    input {
      font-family: 'CircularStd', sans-serif !important;
      border-radius: 4px !important;
      border: 1px solid var(--border-color) !important;
      color: var(--text-color) !important;
      background-color: var(--white-color) !important;
      box-shadow: none !important;

      &:disabled {
        background: var(--background-color) !important;
        opacity: 0.8;
      }

      &[data-valid='false'] {
        border-color: var(--failure-color) !important;
      }
    }
  }
`;
