import React from 'react';

import Button from '~/components/button';
import Input from '~/components/form/input';

import startIcon from '~/assets/images/star-model.svg';
import pointIcon from '~/assets/images/point-model.svg';

import StyledPerformanceQuestionPreview from './styles';
import { useTranslation } from 'react-i18next';

function PerformanceQuestionPreview({
  questionNumber,
  statement,
  modelImage,
  options,
  answer,
  descriptiveAnswer,
  openAnswerQuestion,
  answerByManaged,
  descriptiveAnswerByManaged,
  questions,
  disabled
}) {
  const models = {
    radio: pointIcon,
    star: startIcon
  };

  const { t } = useTranslation();

  return (
    <StyledPerformanceQuestionPreview>
      <div className="question-header">
        <div className="info">
          <div className="question-number">{questionNumber}</div>
          <p className="statement">{statement}</p>
        </div>
      </div>
      <div className="question-options">
        {options.map(option => (
          <Button
            key={option.pk}
            color="primary"
            className={`
              outline
              ${answer === option.pk ? ' selected' : ''}
              ${answerByManaged === option.pk ? ' answer-by-managed' : ''}
            `}
            disabled={disabled}
          >
            <img src={models[modelImage]} alt="model" />
            <p>{option.text}</p>
          </Button>
        ))}
      </div>
      {descriptiveAnswerByManaged && (
        <div className="descriptive-answer-by-managed">
          <h5>
            {openAnswerQuestion} {t('(Liderado)')}
          </h5>
          <Input
            textarea
            rows={1}
            value={descriptiveAnswerByManaged || ''}
            disabled={disabled}
          />
        </div>
      )}
      {openAnswerQuestion && (
        <div className="descriptive-answer">
          <h5>
            {openAnswerQuestion} {t('(Líder)')}
          </h5>
          <Input
            textarea
            rows={1}
            value={descriptiveAnswer || ''}
            disabled={disabled}
          />
        </div>
      )}
    </StyledPerformanceQuestionPreview>
  );
}

export default PerformanceQuestionPreview;
