import api from './api';

export const getFullLibrary = (organizationId, page) =>
  api.get('/library/', {
    params: {
      organization: organizationId,
      page
    }
  });

export const getFolder = (folderId, organizationId, page) =>
  api.get(`/library/${folderId}/`, {
    params: {
      organization: organizationId,
      page
    }
  });
