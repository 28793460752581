import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import NewButton from '~/components/new-ui/Button';
import Separator from '~/components/separator';

import sucessImage from '~/assets/images/success-illustration.svg';
import logoImage from '~/contexts/site-provider/flow-assets/images/logo-composed.svg';

import { StyledRecoverySent } from './styles';

const RecoverySentScreen = ({ location }) => {
  const email = queryString.parse(location.search).email;

  return (
    <StyledRecoverySent>
      <Link to="/sign-in">
        <img src={logoImage} alt="USEFLOW" className="logo" />
      </Link>

      <img src={sucessImage} alt="Success" />

      <h2>Solicitação enviada</h2>
      <p>Siga as instruções enviadas para o e-mail {email}</p>

      <Separator />

      <p>Não recebeu as instruções?</p>

      <Link to="/recovery">
        <NewButton className="re-sent-button">Reenvie a solicitação</NewButton>
      </Link>
      <Link to="/sign-in">
        <NewButton theme="white" className="back-to-login-button">
          Voltar para login
        </NewButton>
      </Link>
    </StyledRecoverySent>
  );
};

export default RecoverySentScreen;
