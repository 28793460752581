import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const AccountMenu = ({ account }) => {
  const { t } = useTranslation();

  const { has_custom_fields } = account.data.profile;

  const itens = [
    {
      label: t('Meus dados'),
      url: '/account'
    }
    // {
    //   label: 'Redes sociais',
    //   url: '/account/social-networks'
    // }
  ];

  if (has_custom_fields) {
    itens.push({
      label: 'Meu perfil',
      url: '/account/my-profile'
    });
  }

  if (!account.data.is_sso) {
    itens.push({
      label: 'Alterar senha',
      url: '/account/change-password'
    });
  }

  return (
    <div className="account-menu">
      {itens.map(item => (
        <NavLink key={item.url} to={item.url} exact activeClassName="active">
          {item.label}
        </NavLink>
      ))}
    </div>
  );
};

const mapStateToProps = ({ account }) => ({ account });

export default connect(mapStateToProps)(withRouter(AccountMenu));
