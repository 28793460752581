import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getContacts } from '~/api/contact';

import ProfileInfo from '~/components/profile-info';

import './style.scss';

function ChatList({ subjectMatters }) {
  const [contactList, setContactList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getContactList = useCallback(() => {
    setLoading(true);

    getContacts()
      .then(response => {
        setContactList(response.data);
      })
      .catch(e => {
        alert('Erro');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getSubject = contact => {
    const subjectList = subjectMatters.filter(item => item.id === contact.type);

    if (subjectList.length > 0) {
      return subjectList[0].subject;
    }

    return null;
  };

  useEffect(() => {
    getContactList();
  }, []);

  return (
    <div className="chat-list">
      {!loading && contactList.length === 0 && <h3>Não há mensagens</h3>}

      {loading && <p className="loading">Carregando...</p>}

      {!loading &&
        contactList.length > 0 &&
        contactList.map(contact => (
          <Link
            key={contact.id}
            to={{
              pathname: '/contact-chat',
              state: {
                contactId: contact.id,
                description: contact.description,
                owner: contact.owner,
                subject:
                  subjectMatters && subjectMatters.length > 0
                    ? getSubject(contact)
                    : null,
                date: contact.created_at
              }
            }}
          >
            <div className="contact">
              <ProfileInfo
                name={contact.owner.name}
                image={contact.owner.avatar_128x0}
                info=""
                rightImage={null}
              />
              <div className="comment-preview">{contact.description}</div>
              <div className="contact-footer">
                <div className="subject">
                  {contact.type && subjectMatters.length > 0 && (
                    <>
                      <span>Assunto: </span>
                      {getSubject(contact)}
                    </>
                  )}
                </div>

                <p className="created-at">
                  {moment(contact.created_at).format(
                    'D [de] MMM [de] YYYY [-] HH:mm'
                  )}
                </p>
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
}

export default ChatList;
