import api from './api';

export const getMetlifeProfile = orgId =>
  api.get(`/organization/${orgId}/metlife/me/profile`);

export const getMetlifeDepartments = (
  orgId,
  type,
  departmentVar,
  page = 1,
  filters
) =>
  api.get(
    `/organization/${orgId}/metlife/${type}/department/${
      departmentVar ? `${departmentVar}/` : ''
    }`,
    {
      params: {
        page,
        search: filters.search,
        group: filters.group
      }
    }
  );

export const getEditorialLines = () => api.get(`/editorial/`);
