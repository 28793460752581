import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  PublicWrapper,
  PublicHeader,
  PublicBanner,
  PublicFooter
} from '~/components/public';
import Card from '~/components/card';

import StyledPerformanceGuestCompleted from './styles';
import { useTranslation } from 'react-i18next';

function PerformanceGuestCompleted() {
  const { t } = useTranslation();

  return (
    <StyledPerformanceGuestCompleted>
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card">
            <h3>{t('Avaliação de desempenho')}</h3>
            <p>{t('Avaliação de desempenho respondida!')}</p>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner></PublicBanner>
          <div className="hide-on-mobile" style={{ marginBottom: 200 }} />
        </div>
        <PublicFooter />
      </PublicWrapper>
    </StyledPerformanceGuestCompleted>
  );
}

export default withRouter(PerformanceGuestCompleted);
