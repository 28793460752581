import React from 'react';
import moment from 'moment';

import Card from '~/components/card';
import Icon from '~/components/icon';
import PDIStatus from '~/components/pdi-status';

import { StyledPDIItem } from './styles';
import { useTranslation } from 'react-i18next';

function PDIItem({
  title,
  status,
  pillar,
  isLate,
  startAt,
  finishAt,
  onClick
}) {
  const { t } = useTranslation();

  return (
    <StyledPDIItem onClick={onClick}>
      <Card className="pdi-item">
        <div className="info-side">
          <div className="text-info">
            <h3 className="title">{title || t('Não informado')}</h3>
            <div className="tags">
              <div className="period-card">
                <Icon name="calendar" />
                <p>
                  {t('{{startAt}} até {{finishAt}}', {
                    startAt: moment(startAt).format('DD/MM/YYYY'),
                    finishAt: moment(finishAt).format('DD/MM/YYYY')
                  })}
                </p>
              </div>

              <div className="pillar-tag">
                <p>{pillar}</p>
              </div>

              <PDIStatus status={status.value} description={status.text} />

              {isLate && status !== 'canceled' && (
                <PDIStatus status="is_late" description={t('Atrasado')} />
              )}
            </div>
          </div>
        </div>
        <div className="action-side"></div>
      </Card>
    </StyledPDIItem>
  );
}

export default PDIItem;
