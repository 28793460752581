import React from 'react';

import store from '~/store';
import { openDefaultModal } from '~/store/ducks/default-modal';

import Icon from '../icon';
import Button from '../button';
import DownloadInstructionsModal from '~components/download-instructions-modal';

import { StyledTaskDoableMobileFooter } from './styles';

function TaskDoableMobileFooter() {
  const openAppDownloadInstructions = () => {
    store.dispatch(openDefaultModal(<DownloadInstructionsModal />));
  };

  return (
    <StyledTaskDoableMobileFooter>
      <div className="information-task">
        <p>
          Aviso! Esta ação só pode ser realizada através do USEFLOW no seu
          telefone, acesse o app para continuar.
        </p>
      </div>
      <footer className="card-footer">
        <div className="text-center">
          <Button color="primary" onClick={openAppDownloadInstructions}>
            <Icon name="arrow-right" marginRight />
            <span>Baixar aplicativo</span>
          </Button>
        </div>
      </footer>
    </StyledTaskDoableMobileFooter>
  );
}

export default TaskDoableMobileFooter;
