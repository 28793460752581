import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { login, upadateTokenKey } from '~/services/auth';

import { getSSO3pIdentities } from '~/api/sso-auth';
import { getAccount } from '~/api/account';
import { tokenRefresh } from '~/api/auth';

import { showMessage, closeMessage } from '~/store/ducks/messageBar';
import { setOrganization } from '~/store/ducks/organization';

import CustomLoading from '~/components/custom-loading';

import { StyledSSOLogin } from './styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getOrganizationStored, storeOrganizationId } from '~/utils';
import _ from 'lodash';

function SSOLogin(props) {
  const identity = props.location.search.replace('?identity=', '');

  const handleSignIn = token => {
    login(token);

    getAccount().then(response => {
      const organizations = response.data.organization_is_active_set;
      const organizationStored = getOrganizationStored(organizations);
      let activeOrganization = organizationStored || organizations[0];

      tokenRefresh({
        organization: activeOrganization.id,
        token: token
      }).then(response => {
        login(response.data.token);
        upadateTokenKey(response.data.token);
        setOrganization(activeOrganization.id);
        storeOrganizationId(activeOrganization.id);

        props.history.push(_.get(props, 'location.state.from') || '/');
      });
    });
  };

  useEffect(() => {
    getSSO3pIdentities(identity)
      .then(response => {
        handleSignIn(`flow ${response.data.token}`);
      })
      .catch(() => {
        props.history.push('/');
      });
  }, []);

  return (
    <StyledSSOLogin>
      <CustomLoading color="#4746d8" type="spin" width="64px" />
    </StyledSSOLogin>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage,
      closeMessage,
      setOrganization
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(SSOLogin));
