import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '~/components/icon';

class ProfileImage extends PureComponent {
  state = {
    previewUrl: null
  };

  loadPreview = file => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ previewUrl: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({ previewUrl: null });
    }
  };

  render() {
    const {
      t,
      input: { value, onChange }
    } = this.props;

    const { previewUrl } = this.state;

    let url = previewUrl || value.default;
    return (
      <>
        <img src={url} alt="imagem" className="rounded profile-img" />

        <button className="outline button secondary ml10 relative">
          <input
            className="file-button-hidden"
            accept="image/*"
            type="file"
            onChange={e => {
              this.loadPreview(e.target.files[0]);
              onChange({
                ...value,
                file: e.target.files[0]
              });
            }}
          />
          <Icon name="camera" />
          {t('Escolher')}
        </button>
      </>
    );
  }
}

export default withTranslation()(ProfileImage);
