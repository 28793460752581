import styled from 'styled-components';

export const StyledPDIParticipantItem = styled.div.attrs(() => ({
  className: 'pdi-participant-item'
}))`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  margin-bottom: 16px;
  padding: 0px;

  .card {
    width: 100%;

    margin: 0px;
    padding: 16px 32px;

    background-color: ${props => (!props.isActive ? 'var(--gray)' : 'none')};
    border: ${props =>
      !props.isActive ? '1px solid var(--white-color)' : 'none'};

    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pdi-item-left {
      display: flex;
      align-items: flex-start;
      flex-direction: row;

      .avatar {
        margin-right: 16px;
        min-width: max-content;
        opacity: ${props => (!props.isActive ? 0.5 : 1)};

        img {
          width: 32px;
          min-width: 32px;
          height: 32px;
          border-radius: 50%;
        }
      }

      .participant-info {
        opacity: ${props => (!props.isActive ? 0.5 : 1)};
        h3 {
          text-align: start;
        }

        p {
          color: var(--secondary-text-color);
          text-align: start;
        }

        .email {
          color: var(--primary-color);
        }
      }
    }
  }
`;
