import styled from 'styled-components';

export const StyledCarousel = styled.div`
  .control-dots {
    .dot {
      width: 100px;
      height: 8px;
      border-radius: 100px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
  }
`;

export const StyledCarouselImage = styled.div`
  height: 100%;
  width: 100%;
  background-color: #4746d8;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center center;
  position: relative;

  .slide-balloon {
    position: absolute;
    top: 280px;
    right: 10%;
    z-index: 20;
  }

  @media (max-width: 800px) {
    background-position: right bottom;

    .slide-balloon {
      left: 60%;
      top: 20%;
    }
  }

  @media (max-width: 600px) {
    background-position: right bottom;

    .slide-balloon {
      left: 25%;
      top: 20%;
    }
  }
`;
