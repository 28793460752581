import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getFeedbacksHistoric } from '~/api/feedback';

import { showMessage } from '~/store/ducks/messageBar';

import Select from '~/components/form/select';
import EmptyList from '~/components/empty-list';
import PageHeader from '~/components/page-header';
import FeedbackHistoricItem from './components/feedback-historic-item';
import CustomLoading from '~/components/custom-loading';
import SearchInput from '~/components/form/search-input';
import InfiniteScroll from '~/components/infinite-scroll';

import { StyledFeedbackHistoricList } from './styles';
import Button from '~/components/button';
import Icon from '~/components/icon';

function FeedbackHistoricList({ organization, history, showMessage, match }) {
  const { activeOrganizationId } = organization;
  const targetId = match.params.target;
  const firstPage = 1;

  const { t } = useTranslation();

  const [feedbackHistoricList, setFeedbackHistoricList] = useState([]);
  const [managed, setManaged] = useState({});
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('all');
  const [currentTimeOut, setCurrentTimeOut] = useState(null);

  const [order, setOrder] = useState('-sent_at');

  const getFeedbackHistoricList = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    getFeedbacksHistoric(
      activeOrganizationId,
      targetId,
      filters,
      page,
      null,
      null
    )
      .then(response => {
        setFeedbackHistoricList(() => response.data.results);
        setManaged(response.data.managed);
        setNextPage(response.data.next);
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          history.push('/page-not-found');
          return;
        }

        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      getFeedbackHistoricList({
        name: value,
        status: status !== 'all' ? status : '',
        ordering: order
      });
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const handleStatus = event => {
    const { value } = event.target;

    setStatus(value);

    getFeedbackHistoricList({
      name: search,
      status: value !== 'all' ? value : '',
      ordering: order
    });
  };

  const handleOrder = event => {
    const { value } = event.target;

    getFeedbackHistoricList({
      name: search,
      status: status !== 'all' ? status : '',
      ordering: value
    });

    setOrder(value);
  };

  const loadMoreFeedbackHistoric = () => {
    setLoadingMore(true);

    const filters = {
      name: search,
      status: status !== 'all' ? status : '',
      ordering: order
    };

    getFeedbacksHistoric(
      activeOrganizationId,
      targetId,
      filters,
      currentPage + 1,
      null,
      null
    )
      .then(response => {
        setFeedbackHistoricList(lastFeedbackHistoricList => [
          ...lastFeedbackHistoricList,
          ...response.data.results
        ]);

        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    getFeedbackHistoricList({
      name: search,
      status: status !== 'all' ? status : '',
      ordering: order
    });
  }, [activeOrganizationId]);

  return (
    <StyledFeedbackHistoricList>
      <div className="btn-back left-side">
        <Button color="primary" className="link" onClick={handleBack}>
          <Icon name="back" />
          Voltar
        </Button>
      </div>
      <PageHeader>
        <div className="text-info">
          <h1>{t('Histórico de feedbacks')}</h1>
          <div className="breadcrumb-wrapper">
            <span>{managed.name}</span>
            <span>-</span>
            <span>{managed.department}</span>
            <span>-</span>
            <span>{managed.profile}</span>
          </div>
        </div>
      </PageHeader>

      <div className="filters">
        <SearchInput
          value={search}
          onChange={handleSearch}
          placeholder={t('Buscar feedback por título')}
        />
        <Select value={status} onChange={handleStatus}>
          <option value="all">{t('Todos')}</option>
          <option value="active">{t('Ativos')}</option>
          <option value="finished">{t('Finalizados')}</option>
        </Select>
        <Select value={order} onChange={handleOrder}>
          <option value="-sent_at">{t('Mais recente')}</option>
          <option value="sent_at">{t('Mais antigo')}</option>
        </Select>
      </div>

      {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      <div className="feedback-list">
        {!loading &&
          feedbackHistoricList.map(feedback => (
            <FeedbackHistoricItem
              key={feedback.pk}
              title={feedback.title}
              status={feedback.feedback_status}
              formatedDate={feedback.formated_date}
              onClick={() => {
                history.push({
                  pathname: `/development/feedback/${feedback.pk}/form`,
                  state: {
                    targetId: feedback.pk,
                    feedbackId: feedback.feedback_pk,
                    isAccessedThroughHistory: true
                  }
                });
              }}
              isManager={feedback.is_manager}
              answerDate={feedback.answer_date}
              managerName={feedback.manager_name}
            />
          ))}

        {!loading && feedbackHistoricList.length === 0 && (
          <EmptyList message={t('Nenhum feedback encontrado.')} />
        )}
      </div>

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )}

      <InfiniteScroll
        fetchMore={loadMoreFeedbackHistoric}
        disabled={!nextPage || loading || loadingMore}
      />
    </StyledFeedbackHistoricList>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeedbackHistoricList));
