import { ReactionBarSelector } from '@charkour/react-reactions';
import React, { useEffect, useState } from 'react';

import Icon from '~/components/icon';
import { abbreviateNumberFormatter } from '~/utils';

import reaction01 from '~/assets/images/reactions/01.svg';
import reaction02 from '~/assets/images/reactions/02.svg';
import reaction03 from '~/assets/images/reactions/03.svg';
import reaction04 from '~/assets/images/reactions/04.svg';

import './style.scss';
import {
  like,
  postReaction,
  removeLike,
  removeUnlike,
  unlike
} from '~/api/post';

const PostReactions = ({
  className,
  postId,
  reactions,
  userLike,
  likes,
  unlikes
}) => {
  const [showReactionsBar, setShowReactionsBar] = useState(false);

  // new admin post state
  const [reactionCount, setReactionCount] = useState(
    reactions ? reactions.reaction_counter : 0
  );
  const [reactionTypes, setReactionTypes] = useState(
    reactions ? reactions.types : null
  );
  const [userReaction, setUserReaction] = useState(
    reactions ? reactions.user_choice : null
  );

  // old admin post state
  const [likeCount, setLikeCount] = useState(likes || 0);
  const [unlikeCount, setUnlikeCount] = useState(unlikes || 0);
  const [userLiked, setUserLiked] = useState(userLike);

  const updateNewReactions = type => {
    let newReaction = type;
    const newReactionIndex = reactionTypes.findIndex(
      e => e.type === newReaction
    );
    const oldReactionIndex = reactionTypes.findIndex(
      e => e.type === userReaction
    );
    const newReactionTypes = [...reactionTypes];

    if (userReaction) {
      newReactionTypes[oldReactionIndex]['count'] =
        newReactionTypes[oldReactionIndex]['count'] - 1;

      if (newReactionIndex === oldReactionIndex) {
        // removing reaction?
        newReaction = null;
        setReactionCount(reactionCount - 1);
      } else {
        // swapping reaction?
        newReactionTypes[newReactionIndex]['count'] =
          newReactionTypes[newReactionIndex]['count'] + 1;
      }
    } else {
      newReactionTypes[newReactionIndex]['count'] =
        newReactionTypes[newReactionIndex]['count'] + 1;
      setReactionCount(reactionCount + 1);
    }

    if (newReaction === 'like' || newReaction === 'dislike') {
      setUserLiked(newReaction === 'like');
    } else {
      setUserLiked(null);
    }
    setUserReaction(newReaction);
    setReactionTypes(newReactionTypes);
  };

  const setNewReaction = async type => {
    try {
      // update instantly with data that we have right now
      updateNewReactions(type);

      // then we will update with info that will come from server
      postReaction(postId, type);
    } catch (error) {
      console.error(error);
    }
  };

  const setOldReaction = async isLike => {
    let response = null;

    try {
      if (isLike) {
        if (userLiked) {
          setUserLiked(null);
          response = await removeLike(postId);
        } else {
          setUserLiked(true);
          response = await like(postId);
        }
      } else {
        if (userLiked === false) {
          setUserLiked(null);
          response = await removeUnlike(postId);
        } else {
          setUserLiked(false);
          response = await unlike(postId);
        }
      }

      const { like_counter, unlike_counter } = response.data;
      setLikeCount(like_counter);
      setUnlikeCount(unlike_counter);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectReaction = async type => {
    if (reactions) {
      setNewReaction(type);
    } else {
      setOldReaction(type === 'like');
    }

    setShowReactionsBar(false);
  };

  const getReactionImage = type => {
    const images = {
      like: reaction01,
      celebrate: reaction02,
      thinking: reaction03,
      dislike: reaction04
    };
    return images[type] || null;
  };

  const getPostReactions = () => {
    const userReaction = getUserReactionType();

    // new admin post
    if (reactions) {
      return reactionTypes.map((reaction, index) => ({
        node: (
          <div
            key={index}
            className={`reaction ${
              userReaction === reaction.type ? 'reacted' : ''
            }`}
          >
            <img src={getReactionImage(reaction.type)} alt="" />
            <span>{abbreviateNumberFormatter(reaction.count)}</span>
          </div>
        ),
        key: reaction.type
      }));
    }

    // old admin post
    return [
      {
        node: (
          <div
            className={`reaction ${userReaction === 'like' ? 'reacted' : ''}`}
          >
            <img src={reaction01} alt="" />
            <span>{abbreviateNumberFormatter(likeCount)}</span>
          </div>
        ),
        key: 'like'
      },
      {
        node: (
          <div
            className={`reaction ${
              userReaction === 'dislike' ? 'reacted' : ''
            }`}
          >
            <img src={reaction04} alt="" />
            <span>{abbreviateNumberFormatter(unlikeCount)}</span>
          </div>
        ),
        key: 'dislike'
      }
    ];
  };

  const getPostReactionsCount = () =>
    reactions ? reactionCount : likeCount + unlikeCount;

  const getUserReactionType = () => {
    if (!isUserReactedToPost()) return null;
    let type = userReaction ? userReaction : null; // new admin
    if (!userReaction) type = userLiked ? 'like' : 'dislike'; // old admin
    return type;
  };

  const isUserReactedToPost = () => userReaction !== null || userLiked !== null;

  const renderPostReactionIcon = () => {
    const reactionType = getUserReactionType();
    if (reactionType) {
      return <img src={getReactionImage(reactionType)} alt="" />;
    }

    // no reaction, default image
    return <Icon name="emoticon-happy-line" />;
  };

  useEffect(() => {
    if (showReactionsBar) {
      const reactions = document.querySelectorAll('.reaction');
      if (reactions) {
        for (const reaction of reactions) {
          // Replaces &:has() that does not have support on Firefox
          const target = reaction.parentNode.parentNode;

          if (target) {
            target.style.borderRadius = '24px';

            if (reaction.classList.contains('reacted')) {
              target.style.background = '#EAEAF0';
            } else {
              target.style.background = '#FFFFFF';
            }
          }
        }
      }
    }
  }, [showReactionsBar]);

  return (
    <div className={`reactions ${className}`}>
      <div className={`reactions-bar ${showReactionsBar ? 'show' : 'hide'}`}>
        <ReactionBarSelector
          style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}
          reactions={getPostReactions()}
          onSelect={handleSelectReaction}
        />
      </div>
      <div
        className={`reactions-button ${
          isUserReactedToPost() || showReactionsBar ? 'reacted' : ''
        }`}
        onClick={() => setShowReactionsBar(!showReactionsBar)}
      >
        {renderPostReactionIcon()}
        <span>{abbreviateNumberFormatter(getPostReactionsCount())}</span>
      </div>
    </div>
  );
};

export default PostReactions;
