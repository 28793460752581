import React from 'react';
import YouTube from 'react-youtube';

import MarkAsDoneButton from '~/components/mark-as-done-button';

import { StyledReadVideo } from './styles';

function ReadVideo({
  contentType,
  title,
  videoUrl,
  isDone,
  canManuallyAnswer,
  markAsDone
}) {
  const getYoutubeVideoId = url => {
    try {
      // eslint-disable-next-line no-useless-escape
      const regex = /(?:youtu\.be\/|youtube\.com(?:\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/|embed\/|v\/|m\/|watch\?(?:[^=]+=[^&]+&)*?v=))([^"&?\/\s]{11})/gm;
      return regex.exec(url)[1];
    } catch {
      return null;
    }
  };

  const youtubeHasDone = event => {
    markAsDone({});
  };

  return (
    <StyledReadVideo>
      <div className="step-section">
        <div className="title">
          <h3>{title}</h3>
          <MarkAsDoneButton
            isDone={isDone}
            canManuallyAnswer={canManuallyAnswer}
            onClick={() =>
              markAsDone({
                ignoreAction: true
              })
            }
          />
        </div>
      </div>
      <div className="step-section">
        <YouTube
          videoId={getYoutubeVideoId(videoUrl)}
          containerClassName="youtube-video-player"
          onEnd={youtubeHasDone}
        />
      </div>
    </StyledReadVideo>
  );
}

export default ReadVideo;
