import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import EmptyScreen from '~/components/empty-screen';
import ForumFeed from '~/components/forum-feed';
import LoadingMessage from '~/components/loading-message';
import useAccount from '~/hooks/use-account';
import { load, loadMore } from '~/store/ducks/forum';

import './style.scss';

const EmptyTrails = () => <EmptyScreen name="forum" />;

let FeedScreen = ({ location }) => {
  const dispatch = useDispatch();
  const search = queryString.parse(location.search).termo;

  const mapStateToProps = useCallback(
    ({ forum: { data, loading, nextUrl, total, loadingMore } }) => ({
      forum: data,
      loading,
      loadingMore,
      nextUrl,
      total
    }),
    []
  );

  const { forum, loading, loadingMore, nextUrl, total } = useMappedState(
    mapStateToProps
  );

  useEffect(() => {
    dispatch(load(search));
  }, [search, location.key]);

  const onLoadMore = useCallback(() => {
    if (loadingMore || !nextUrl) {
      return;
    }

    dispatch(loadMore(nextUrl));
  }, [loadingMore, nextUrl]);

  if (loading && forum.length === 0) {
    return <LoadingMessage />;
  }

  if (!loading && forum.length === 0 && !search) {
    return <EmptyTrails />;
  }

  return (
    <>
      {loading && <LoadingMessage />}

      {!loading && (
        <ForumFeed
          total={total}
          search={search}
          results={forum}
          onScrollFinish={onLoadMore}
          onFinishItem={() => {}}
        />
      )}

      {loadingMore && <LoadingMessage />}
    </>
  );
};

FeedScreen = withRouter(FeedScreen);

let ForumScreen = ({ location }) => {
  const account = useAccount();

  return <FeedScreen key="feed" account={account.data} />;
};

ForumScreen = withRouter(ForumScreen);

const ForumScreenGuaranteeAccount = () => {
  const { loaded } = useAccount();

  if (!loaded) {
    return <LoadingMessage />;
  }

  return (
    <div className="forum">
      <ForumScreen />
    </div>
  );
};

export default ForumScreenGuaranteeAccount;
