import React from 'react';
import LinkPreviewMedia from '~/components/link-preview-media';

const TaskBody = props => (
  <div className="card-body">
    {props.channel !== 'profile' ? (
      <>
        {props.cover && (
          <LinkPreviewMedia
            channel={props.channel}
            type={props.type}
            id={props.id}
          >
            <img src={props.cover} alt="Cover" />
          </LinkPreviewMedia>
        )}
        {props.title && (
          <h3 className="text-center">
            <LinkPreviewMedia
              channel={props.channel}
              type={props.type}
              id={props.id}
            >
              {props.title}
            </LinkPreviewMedia>
          </h3>
        )}
        {props.description && (
          <p className="text-center">
            <LinkPreviewMedia
              channel={props.channel}
              type={props.type}
              id={props.id}
            >
              {props.description}
            </LinkPreviewMedia>
          </p>
        )}
      </>
    ) : (
      ''
    )}
  </div>
);

export default TaskBody;
