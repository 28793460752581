import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';
import { getInfo } from '~/api/tasks';
import Task from '~/components/feed/task';
import useAccount from '~/hooks/use-account';
import { taskLoaded } from '~/store/ducks/actions';

export const ViewTaskControl = ({ id, render }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getInfo(id)
      .then(({ data }) => {
        dispatch(taskLoaded(data));
        setData(data);
      })
      .catch(e => {
        setError(e);
      });
  }, []);

  return render({
    data,
    error,
    onChange: setData
  });
};

const connectRequired = ['facebook', 'instagram', 'twitter', 'linkedin'];

const TaskScreen = ({ match, history }) => {
  const account = useAccount();

  return (
    <ViewTaskControl
      id={match.params.id}
      render={({ data, error, onChange }) => {
        if (!account) {
          return null;
        }

        if (data) {
          const { channel } = data;

          let connected = true;

          if (!!~connectRequired.indexOf(channel)) {
            connected =
              account.data.externalaccount_set.filter(s => s.type === channel)
                .length > 0;
          }

          return (
            <Task
              {...data}
              onChange={onChange}
              connected={connected}
              onFinish={() => {
                history.push('/');
              }}
            />
          );
        } else if (error) {
          return <p>Houve um erro ao carregar a task</p>;
        }

        return null;
      }}
    />
  );
};

export default withRouter(TaskScreen);
