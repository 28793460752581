import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Card from '~/components/card';
import Button from '~/components/button';
import PageHeader from '~/components/page-header';

import { feedback as FeedbackIcon } from '~/components/svg-icons/svg-icons';
import { forms_survey as SurveyIcon } from '~/components/svg-icons/svg-icons';
import { forms_performance as PerformanceIcon } from '~/components/svg-icons/svg-icons';

import { getConfig } from '~/utils';

import { StyledDevelopment } from './styles';

const Development = ({ account, organization, history }) => {
  const { organization_is_active_set } = account.data;
  const { activeOrganizationId } = organization;
  const { t } = useTranslation();

  const config = getConfig(organization_is_active_set, activeOrganizationId);

  useEffect(() => {
    if (
      !config.moduleFeedback &&
      !config.modulePDI &&
      !config.modulePerformance
    ) {
      history.push('/');
    }
  }, []);

  return (
    <StyledDevelopment>
      <PageHeader>
        <div className="text-info">
          <h1>{t('Desempenho')}</h1>
          <p>
            {t(
              'Responda avaliações de desempenho, realize feedbacks e acompanhe os seus PDIs e dos demais colaboradores da sua empresa.'
            )}
          </p>
        </div>
      </PageHeader>
      <div className="module-list">
        {config.modulePerformance && (
          <Button color="white" onClick={() => history.push('/performances')}>
            <Card className="module-item">
              <div className="beta-tag">Beta</div>
              <PerformanceIcon className="module-item-img" />
              <h3>{t('Avaliações')}</h3>
              <p>
                {t(
                  'Configuração, liberação e acompanhamento de formulários de avaliação de desempenho.'
                )}
              </p>
            </Card>
          </Button>
        )}

        {config.moduleFeedback && (
          <Button
            color="white"
            onClick={() => history.push('/development/feedback')}
          >
            <Card className="module-item">
              <div className="beta-tag">Beta</div>
              <FeedbackIcon className="module-item-img" />
              <h3>{t('Feedbacks')}</h3>
              <p>
                {t(
                  'Realização e acompanhamento de feedbacks junto às lideranças e colaboradores da organização.'
                )}
              </p>
            </Card>
          </Button>
        )}

        {config.modulePDI && (
          <Button
            color="white"
            onClick={() => history.push('/development/pdi')}
          >
            <Card className="module-item">
              <div className="beta-tag">Beta</div>
              <SurveyIcon className="module-item-img" />
              <h3>{t('PDIs')}</h3>
              <p>
                {t(
                  'Construção e acompanhamento de PDIs junto aos colaboradores da organização.'
                )}
              </p>
            </Card>
          </Button>
        )}
      </div>
    </StyledDevelopment>
  );
};

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default withRouter(connect(mapStateToProps)(Development));
