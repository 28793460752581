import React from 'react';
import _ from 'lodash';
import { addWololo } from '~/services/tasks';
import { BASE_ENDPOINT } from '~/settings';
import store from '~/store';

import { openMessageModal } from '~/store/ducks/message-modal';

import AlertModal from '~/components/alert-modal';

const wololoLinkedin = (id, isSubtask) => {
  if (isSubtask) {
    return { id };
  }

  return addWololo(id, {}, true, null, false).catch(error => {
    if (
      _.get(error, 'data.errors') &&
      _.get(error, 'data.code') === 'oauth_token_invalid'
    ) {
      return new Promise(() => {
        const { account } = store.getState();
        const baseUrl = window.location.origin;
        const callback = `${baseUrl}/wololo-success/${id}`;

        const url = `${BASE_ENDPOINT}/accounts/me/oauthlinkedin/?id=${account.data.id}&callback_url=${callback}`;
        window.location.href = url;
      });
    } else {
      store.dispatch(
        openMessageModal(
          <AlertModal
            title="Erro ao realizar a ação"
            content="Aconteceu algo inesperado ao realizar a ação. Recarregue a página ou tente mais tarde."
          />
        )
      );
    }
  });
};

const linkedin = {
  share({ id, isSubtask }) {
    return wololoLinkedin(id, isSubtask);
  },

  message({ id, isSubtask }) {
    return wololoLinkedin(id, isSubtask);
  },

  image({ id, isSubtask }) {
    return wololoLinkedin(id, isSubtask);
  },

  movie({ id, isSubtask }) {
    return wololoLinkedin(id, isSubtask);
  }
};

export default linkedin;
