import api from './api';

export const getSurvey = researchId => {
  const url = `/researches/${researchId}`;
  return api.get(url);
};

export const saveSurveyQuestions = (researchId, data) => {
  const url = `/researches/${researchId}/wololos`;
  return api.post(url, data);
};
