import styled from 'styled-components';

const StyledCollaboratorCardPreviousCycle = styled.div.attrs(() => ({
  className: 'collaborator-card-previous-cycle'
}))`
  width: 100%;
  background: var(--white-color);
  border-radius: 4px;

  & > div {
    width: 100%;
    padding: 18px 32px;
  }

  .segmentation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
  }

  .profile-info {
    padding: 0;

    .profiles {
      width: 100%;
      display: flex;

      & > div {
        display: flex;
        width: 50%;
        color: var(--text-color);
      }

      .managed {
        padding: 20px 0px 20px 30px;
      }

      .manager {
        border-left: 1px solid var(--border-color);
        padding: 20px 0px 20px 30px;
      }
    }

    .text {
      width: max-content;

      h3 {
        max-width: 200px;
      }
    }

    .avatar {
      width: 52px;
      height: 52px;
      min-width: 52px;
      min-height: 52px;
      margin-right: 28px;

      img {
        max-width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .custom-content {
    width: 100%;
    margin-left: 16px;
  }

  @media (max-width: 720px) {
    .profile-info {
      padding: 0;

      .profiles {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > div {
          width: 100%;
        }

        .manager {
          padding: 20px 0px 20px 30px;
          border-top: 1px solid var(--border-color);
        }

        .managed {
          padding: 20px 0px 20px 30px;
        }
      }

      .custom-content {
        margin: 0px;
      }
    }
  }
`;

export default StyledCollaboratorCardPreviousCycle;
