import axios from 'axios';
import { SSO_APP_ID, SSO_API_ID } from '../settings';

const api = axios.create({
  baseURL: 'https://ms.auth.useflow.tech'
});

api.interceptors.request.use(async config => {
  config.auth = {
    username: SSO_APP_ID,
    password: SSO_API_ID
  };
  return config;
});

export default api;
