import React from 'react';

import Icon from '~/components/icon';

import StyledEmptyList from './styles';
import { useTranslation } from 'react-i18next';

function EmptyList({ message, icon, className, hideIcon }) {
  const { t } = useTranslation();

  return (
    <StyledEmptyList className={className}>
      {!hideIcon && <Icon name={icon || 'triangle-alert'} />}

      <p>{message || t('Não há itens')}</p>
    </StyledEmptyList>
  );
}

export default EmptyList;
