import styled from 'styled-components';

export const StyledCustomMultipleChoice = styled.div.attrs(() => ({
  className: 'custom-multiple-choice'
}))`
  width: 100%;
  background: var(--white-color);

  .custom-field-options {
    width: 100%;

    label {
      color: var(--text-color);
      background: var(--white-color);

      &:hover {
        background: var(--background-color);
      }
    }
  }

  &.has-error {
    select {
      border-color: var(--failure-color);
    }
  }

  .error-message {
    color: var(--failure-color);
  }
`;
