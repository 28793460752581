import api from './api';

export const getPost = id => api.get(`/posts/${id}`);
export const getComments = post => api.get(`/posts/${post}/comment`);

export const postComment = (post, comment, parent = null) =>
  api.post(`/posts/${post}/comment`, {
    comment,
    parent
  });

export const postPoll = (post, yes) =>
  api.post(`/posts/${post}/vote`, {
    yes
  });

export const postShare = post => api.post(`/posts/${post}/share`);
export const postView = post => api.post(`/posts/${post}/view`);

export const like = post => api.post(`/posts/${post}/like`);
export const unlike = post => api.post(`/posts/${post}/unlike`);
export const removeLike = post => api.delete(`/posts/${post}/like`);
export const removeUnlike = post => api.delete(`/posts/${post}/unlike`);

export const editComment = (id, comment, parent = null) =>
  api.put(`/comment/${id}`, {
    comment,
    parent
  });

export const destroyComment = id => api.delete(`/comment/${id}`);

export const postReaction = (id, type) =>
  api.post(`/content/${id}/reaction/`, { type });
