import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { login } from '~/services/auth';
import queryString from 'query-string';
import { getOrganizationFromToken, storeOrganizationId } from '~/utils';
import { setOrganization } from '~/store/ducks/organization';
import { useDispatch } from 'redux-react-hook';

const TokenLoginScreen = ({ history, location: { search } }) => {
  const dispatch = useDispatch();

  const setOrganizationId = organizationId => {
    dispatch(setOrganization(organizationId));
    storeOrganizationId(organizationId);
  };

  useEffect(() => {
    const { token } = queryString.parse(search);

    if (!token) {
      history.push('/');
      return;
    }

    login(token);

    const organization = getOrganizationFromToken(token);
    setOrganizationId(organization);

    history.push('/');
  }, []);

  return null;
};

export default withRouter(TokenLoginScreen);
