import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';

const colors = [
  'primary',
  'secondary',
  'success',
  'danger',
  'dark',
  'facebook',
  'whatsapp',
  'instagram',
  'linkedin',
  'twitter',
  'x',
  'youtube',
  'publishing',
  'black',
  'white'
];

const Button = ({
  to,
  href,
  children,
  color,
  style,
  className,
  disabled,
  type,
  ...props
}) => {
  const booleans = ['small', 'fill', 'outline', 'circle', 'disabled'];
  let classes = ['button', color];

  booleans.forEach(i => {
    if (props[i]) {
      classes.push(i);
    }
  });

  if (className) {
    classes.push(className);
  }

  classes = classes.join(' ');

  return href ? (
    <a
      href={href}
      className={classes}
      style={style}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : to ? (
    <Link disabled={disabled} to={to} className={classes} style={style}>
      {children}
    </Link>
  ) : (
    <button
      disabled={disabled}
      className={classes}
      style={style}
      onClick={props.onClick}
      type={props.type}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  fill: false,
  small: false,
  outline: false,
  circle: false,
  disabled: false,
  className: '',
  style: {}
};

Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  fill: PropTypes.bool,
  small: PropTypes.bool,
  outline: PropTypes.bool,
  circle: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(colors).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object
};

export default Button;
