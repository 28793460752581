import useSite from '~/hooks/use-site';
import React from 'react';
import './style.scss';

const Copyright = () => {
  const { name } = useSite();

  return (
    <div className="copyright">
      <b>® {name} </b>
      <span>usa a tecnologia Flow.</span>
      <span>
        {process.env.REACT_APP_VERSION
          ? ` - v${process.env.REACT_APP_VERSION}`
          : ''}
      </span>
    </div>
  );
};

export default Copyright;
