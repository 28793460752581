import React, { useState } from 'react';

import { createContact } from '~/api/contact';

import { Select, Input, Check } from '~/components/form';
import Button from '~/components/button';

import './style.scss';

function ChatForm({ toggleSection, organization, subjectMatters }) {
  const [subject, setSubject] = useState(
    subjectMatters && subjectMatters[0] ? subjectMatters[0].id : null
  );
  const [message, setMessage] = useState('');
  const [anonymous, setAnonymous] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target }) => {
    const { name, value } = target;

    if (name === 'anonymous') {
      const { checked } = target;
      setAnonymous(checked);
      return;
    }

    if (name === 'subject') {
      setSubject(value);
      return;
    }

    setMessage(value);
  };

  const submit = () => {
    if (message === '') {
      alert('O campo da mensagem não pode ser vazio');
      return;
    }

    setLoading(true);

    createContact(organization, message, anonymous, subject)
      .then(() => {
        toggleSection('list');
      })
      .catch(e => {
        alert('Erro');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="chat-form">
      <h2>O que você gostaria de nos dizer?</h2>
      {subjectMatters && subjectMatters.length > 0 && (
        <Select name="subject" onChange={handleChange} value={subject}>
          {subjectMatters.map(item => (
            <option key={item.id} value={item.id}>
              {item.subject}
            </option>
          ))}
        </Select>
      )}
      <Input textarea value={message} onChange={handleChange} name="message" />
      <Check
        type="checkbox"
        name="anonymous"
        checked={anonymous}
        onChange={handleChange}
      >
        Enviar anonimamente
      </Check>

      <div className="submit-area">
        <Button onClick={submit} disabled={loading} color="primary">
          {!loading ? <span>Enviar</span> : <span>Enviando...</span>}
        </Button>
      </div>
    </div>
  );
}

export default ChatForm;
