import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getScorm } from '~/api/training';

import { showMessage } from '~/store/ducks/messageBar';

import CustomLoading from '~/components/custom-loading';

import { StyledCourseScorm } from './styles';

function CourseScorm({ account, organization, history, location }) {
  const { activeOrganizationId } = organization;
  const courseId = location.pathname
    .replace('/training/course/', '')
    .replace('/scorm', '');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadContent = () => {
    setLoading(true);

    getScorm(activeOrganizationId, courseId)
      .then(response => {
        window.location.href = response.data.scorm_link;
      })
      .catch(error => {
        setError(true);
      });
  };

  useEffect(() => {
    if (account.loaded && account.data) {
      loadContent();
    }
  }, []);

  return (
    <StyledCourseScorm>
      {loading && (
        <div className="loading-content">
          <CustomLoading
            type="spin"
            height={56}
            width={56}
            fluid
            className="screen-loading"
          />
          <span>Por favor, aguarde enquanto o conteúdo é carregado...</span>
        </div>
      )}

      {!loading && error && (
        <div className="loading-content">
          <span className="error">
            <p>Não foi possível carregar o conteúdo.</p>
            <p>Por favor, tente novamente mais tarde.</p>
          </span>
        </div>
      )}
    </StyledCourseScorm>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseScorm));
