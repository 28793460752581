import styled from 'styled-components';

export const StyledDriveModal = styled.div.attrs(() => ({
  className: 'drive-modal'
}))`
  height: 100%;

  iframe {
    border: none;
  }
`;
