import api from './api';

export const getPDIs = (orgId, filters, page, participantId, admin) => {
  const url = participantId
    ? `/organization/${orgId}/pdi/${participantId}/`
    : `/organization/${orgId}/pdi/me/`;
  return api.get(url, {
    params: {
      status: filters.status,
      is_late: filters.is_late,
      order: filters.order,
      is_admin: admin,
      page
    }
  });
};

export const createPDI = (orgId, targetId, data, admin) => {
  data.is_admin = admin;
  return api.post(`/organization/${orgId}/pdi/${targetId}/`, data);
};

export const getMyTeam = (orgId, filters, page, admin) =>
  api.get(`/organization/${orgId}/pdi/`, {
    params: {
      search: filters.search,
      departments: filters.departments,
      profiles: filters.profiles,
      managers: filters.managers,
      is_admin: admin,
      page
    }
  });

export const getPDI = (orgId, pdiId, participantId) => {
  const url = participantId
    ? `/organization/${orgId}/pdi/${participantId}/${pdiId}/`
    : `/organization/${orgId}/pdi/me/${pdiId}/`;

  return api.get(url);
};

export const updatePDI = (orgId, pdiId, participantId, data, admin) => {
  const url = participantId
    ? `/organization/${orgId}/pdi/${participantId}/${pdiId}/`
    : `/organization/${orgId}/pdi/me/${pdiId}/`;
  data.is_admin = admin;
  return api.put(url, data);
};

export const getTimeline = (orgId, pdiId, page) =>
  api.get(`/organization/${orgId}/pdi/${pdiId}/timeline/`, {
    params: {
      page
    }
  });

export const createComment = (orgId, pdiId, data, admin) => {
  data.is_admin = admin;
  return api.post(`/organization/${orgId}/pdi/${pdiId}/timeline/`, data);
};

export const getPillars = (orgId, page, search) => {
  api.get(`/organization/${orgId}/pillar`, {
    params: {
      page,
      search
    }
  });
};
