import styled from 'styled-components';

export const StyledDevelopment = styled.div.attrs(() => ({
  className: 'development'
}))`
  width: 100%;

  .page-header {
    padding-left: 8px;
    p {
      font-size: 14px;
    }
  }

  .module-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .button {
      width: 50%;
      min-height: 258px;

      padding: 8px;
      border-radius: none;
      background: none;
      margin: 0px;

      &:hover .card {
        box-shadow: 1px 1px 16px var(--background-placeholder-color);
      }

      .card {
        height: 100%;

        transition: all 0.5s;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 46px 54px;

        svg {
          height: 72px;
        }

        h3 {
          font-size: 24px;
          margin: 16px 0px 10px 0px;
        }

        p {
          color: var(--secondary-text-color);
          text-align: start;
          margin: 0px;
        }

        .module-item-img {
          fill: var(--primary-color) !important;
        }
      }

      .beta-tag {
        background: var(--background-color);
        padding: 4px 8px;
        width: 100px;
        transform: rotate(45deg);
        text-transform: uppercase;
        font-size: 12px;
        position: absolute;
        top: 13px;
        right: -26px;
      }
    }
  }

  @media (max-width: 720px) {
    .module-list {
      margin-bottom: 56px;

      .button {
        width: 100%;
      }
    }
  }
`;
