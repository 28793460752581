import React from 'react';
import { connect } from 'react-redux';

import { getConfig, isMobileEmbeddedSession } from '~/utils';

import Logo from '~/components/logo';

import StyledCustomHeader from './styles';
import ChangeLanguage from '../change-language';

function CustomHeader({
  expanded,
  account,
  organization,
  organizationConfig,
  children
}) {
  const config =
    organizationConfig ||
    getConfig(
      account.data.organization_is_active_set,
      organization.activeOrganizationId
    );

  const isEmbedded = isMobileEmbeddedSession();

  return (
    <StyledCustomHeader className={`${config.appLogo ? 'has-app-logo' : ''}`}>
      <div className={`main-content ${expanded ? 'expanded' : ''}`}>
        {!isEmbedded && (
          <div className="logo-side">
            <Logo customLogo={config.appLogo} />
          </div>
        )}
        <div className={`custom-side ${isEmbedded ? 'mobile-embedded' : ''}`}>
          {children}
        </div>

        {process.env.REACT_APP_CURRENT_ENVIRONMENT !== 'prod' && (
          <div>
            <ChangeLanguage />
          </div>
        )}
      </div>
    </StyledCustomHeader>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(CustomHeader);
