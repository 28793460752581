import React from 'react';
import moment from 'moment';

import Icon from '~/components/icon';

import { BASE_ENDPOINT } from '~/settings';

import './styles.scss';
import { downloadWithToken } from '~/services/download';
import store from '~/store';
import { openMessageModal } from '~/store/ducks/message-modal';
import AlertModal from '~/components/alert-modal';

function LibraryLink({
  title,
  url,
  image,
  createdAt,
  setModalContent,
  setShowViewLink
}) {
  const handleOpenUrl = event => {
    event.preventDefault();

    downloadWithToken(BASE_ENDPOINT.split('/v1')[0] + url)
      .then(response => {
        new Blob([response.data]).text().then(text => {
          let content = text.split('[content]')[1];
          if (content) {
            let new_content = {
              title: title,
              content: content
            };
            setModalContent(new_content);
            setShowViewLink(true);
            return false;
          }
          const link = document.createElement('a');
          link.href = text;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      })
      .catch(() => {
        store.dispatch(
          openMessageModal(
            <AlertModal
              title="Erro ao abrir link"
              content="Tente novamente mais tarde"
            />
          )
        );
      });
  };

  return (
    <div className="library-link">
      <div className="preview">
        {image ? (
          <img className="file-image" src={image} alt="File" />
        ) : (
          <Icon name="globe" />
        )}
      </div>
      <div className="description">
        <div className="title">
          <a
            href={' '}
            onClick={event => handleOpenUrl(event)}
            rel="noopener noreferrer"
            className="library-file"
          >
            {title || url}
          </a>
        </div>
        <div className="info">
          <p className="created_at">
            {moment(createdAt).format('D [de] MMM [de] YYYY')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default LibraryLink;
