import React from 'react';
import store from '~/store';
import { addWololo } from '~/services/tasks';
import {
  openGenericModal,
  closeGenericModal
} from '~/store/ducks/generic-modal';

import { openMessageModal } from '~/store/ducks/message-modal';

import {
  ExternalActionExplanation,
  ExternalActionConfirm
} from '~/components/external-action-explanation';
import AlertModal from '~/components/alert-modal';

export const external = ({ id, externalUrl, isSubtask, onFinish }) => {
  return new Promise((resolve, reject) => {
    const cancel = () => {
      reject();
      store.dispatch(closeGenericModal());
    };

    const confirm = () => {
      if (isSubtask) {
        onFinish(id);
        store.dispatch(closeGenericModal());
        resolve();
        store.dispatch(
          openMessageModal(<AlertModal title="Obrigado." content="" />)
        );
        return;
      }

      addWololo(id, {}, false)
        .then(() => {
          onFinish(id);
          resolve();
          store.dispatch(
            openMessageModal(<AlertModal title="Obrigado." content="" />)
          );
        })
        .catch(reject);
      store.dispatch(closeGenericModal());
    };

    const next = ({ hide }) => {
      if (hide) {
        window.localStorage.hideExternalActionConfirm = true;
      }

      window.open(externalUrl);

      store.dispatch(
        openGenericModal(
          <ExternalActionConfirm onCancel={cancel} onConfirm={confirm} />
        )
      );
    };

    if (window.localStorage.hideExternalActionConfirm) {
      next({});
    } else {
      store.dispatch(
        openGenericModal(
          <ExternalActionExplanation onContinue={next} onCancel={cancel} />
        )
      );
    }
  });
};

const defaultAction = ({ id, wololo_target_url, isSubtask, onFinish }) => {
  return external({ id, externalUrl: wololo_target_url, isSubtask, onFinish });
};

const instagram = {
  like(...args) {
    return defaultAction(...args);
  },

  comment(...args) {
    return defaultAction(...args);
  }

  // publicar videos e imagens direto no Instagram não funciona na web
};

export default instagram;
