import React from 'react';

import Button from '~/components/button';
import MarkAsDoneButton from '~/components/mark-as-done-button';
import CodeInput from '~/components/form/code-input';

import { StyledOpenQRCode } from './styles';

function OpenQRCode({
  title,
  markAsDone,
  contentData,
  setContentData,
  isDone
}) {
  const totalFields = 8;

  const handleSend = () => {
    markAsDone({
      code: contentData.answer
    });
  };

  const setText = text => {
    setContentData({
      ...contentData,
      answer: text
    });
  };

  return (
    <StyledOpenQRCode>
      <div className="step-section">
        <div className="title">
          <h3>{title}</h3>
          {isDone && (
            <MarkAsDoneButton
              isDone={isDone}
              onClick={() =>
                markAsDone({
                  ignoreAction: true
                })
              }
            />
          )}
        </div>
      </div>
      <div className="step-section align-left">
        <CodeInput
          value={contentData.answer === null ? '' : contentData.answer}
          type={!isDone ? 'text' : 'password'}
          onChange={setText}
          fields={totalFields}
          label="Insira o QR Code"
          disabled={isDone}
        />

        {!isDone && (
          <Button
            color="primary"
            onClick={() => handleSend()}
            disabled={
              !contentData.answer ||
              contentData.answer.trim().length < totalFields
            }
          >
            Enviar
          </Button>
        )}
      </div>
    </StyledOpenQRCode>
  );
}

export default OpenQRCode;
