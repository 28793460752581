import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// import StoreLinks from '~/components/store-links';
import { showLoading, closeLoading } from '~/store/ducks/loading';
import { showMessage } from '~/store/ducks/messageBar';
import { recovery } from '~/api/auth';
import NewButton from '~/components/new-ui/Button';
import CustomInput from '~/components/new-ui/CustomInput';
import logoImage from '~/contexts/site-provider/flow-assets/images/logo-composed.svg';
import Carousel from '~/components/carousel';
import { StyledCarouselImage } from '~/components/carousel/styles';
import loginSlide1Image from '~/assets/images/login-slide-1.png';
import loginSlide2Image from '~/assets/images/login-slide-2.png';
import loginSlide3Image from '~/assets/images/slide-4.svg';
import unionBottom from '~/assets/images/union-bottom.svg';
import unionTop from '~/assets/images/union-top.svg';
import { StyledRecovery } from './styles';
// import CustomLoading from "~/components/custom-loading";
import SlideBalloon from '~/components/new-ui/SlideBalloon';

class RecoveryScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      email_invalid: false,
      client: props.location.search.replace('?client=', '') || '',
      client_invalid: false,
      formDisabled: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  handleChange({ target }) {
    const { name, value } = target;
    this.setState({
      [name]: value,
      [`${name}_invalid`]: false
    });
  }

  handleSubmit() {
    const { client, email } = this.state;
    const { showLoading, closeLoading } = this.props;

    if (!client || !this.validateEmail(email)) {
      if (!client) {
        this.setState({ client_invalid: true });
      }

      if (!this.validateEmail(email)) {
        this.setState({ email_invalid: true });
      }
      return;
    }

    showLoading();
    this.setState({ formDisabled: true });

    if (email && client) {
      recovery(client, email).finally(() => {
        this.props.history.push(`/recovery-sent?email=${email}`);
        this.setState({ formDisabled: false });
        closeLoading();
      });
    }
  }

  render() {
    return (
      <StyledRecovery>
        <div className="content-section">
          <div className="forms-wrapper">
            <div className="recovery-form">
              <Link to="/sign-in">
                <img src={logoImage} alt="USEFLOW" />
              </Link>
              <h2>Recupere seu acesso</h2>

              <div className="recovery-info">
                <h3>Para recuperar seu acesso, preencha os campos a seguir</h3>
                <p>
                  Vamos enviar instruções de recuperação de senha para o seu
                  contato registrado em nosso sistema.
                </p>
              </div>

              <CustomInput
                label="Empresa"
                icon="home-line"
                placeholder="Sua empresa"
                className="client-field"
                theme="dark"
                name="client"
                value={this.state.client}
                onChange={this.handleChange}
                id="client"
                hasError={this.state.client_invalid}
                errorMessage={
                  this.state.client_invalid
                    ? 'O nome da empresa é obrigatório'
                    : ''
                }
                onKeyUp={event => {
                  if (event.key === 'Enter' || event.keyCode === 13) {
                    this.handleSubmit();
                  }
                }}
                disabled={this.formDisabled}
              />
              <CustomInput
                label="E-mail"
                icon="user-line"
                placeholder="Seu e-mail"
                theme="dark"
                value={this.state.email}
                onChange={this.handleChange}
                id="email"
                name="email"
                hasError={this.state.email_invalid}
                errorMessage={this.state.email_invalid ? 'E-mail inválido' : ''}
                onKeyUp={event => {
                  if (event.key === 'Enter' || event.keyCode === 13) {
                    this.handleSubmit();
                  }
                }}
                disabled={this.formDisabled}
              />

              <NewButton
                className="continue-button"
                onClick={() => this.handleSubmit()}
                disabled={this.formDisabled}
              >
                Continuar
              </NewButton>
              <Link to="/sign-in">
                <NewButton theme="white" className="back-to-login-button">
                  Voltar para login
                </NewButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="carousel-section">
          <img src={unionTop} alt="" className="union union-top" />
          <img src={unionBottom} alt="" className="union union-bottom" />

          <Carousel
            slideElements={[
              <StyledCarouselImage
                className="carousel-image"
                image={loginSlide1Image}
              >
                <SlideBalloon>
                  Ambiente digital para todos da sua organização
                </SlideBalloon>
              </StyledCarouselImage>,
              <StyledCarouselImage
                className="carousel-image"
                image={loginSlide2Image}
              >
                <SlideBalloon>
                  Colaboração e eficiência na conexão digital
                </SlideBalloon>
              </StyledCarouselImage>,
              <StyledCarouselImage
                className="carousel-image"
                image={loginSlide3Image}
              >
                <SlideBalloon>
                  Seguro, simples e acessível para os colaboradores
                </SlideBalloon>
              </StyledCarouselImage>
            ]}
          />
        </div>
      </StyledRecovery>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showLoading, closeLoading, showMessage }, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(RecoveryScreen));
