import React, { useState } from 'react';
import { CommentBoxAndButtons, TaskCommentBox } from '~/components/comments';
import { WololoRewardLine } from '~/components/rewards';

const DefaultTaskFooter = props => {
  const [commentCounter, setCommentCounter] = useState(props.comment_counter);

  const incrementCommentCounter = () => {
    setCommentCounter(prev => prev + 1);
  };

  return (
    <>
      {props.can_comment && props.me_create_wololo && (
        <CommentBoxAndButtons
          id={props.id}
          type="task"
          commentCounter={commentCounter}
          incrementCommentCounter={incrementCommentCounter}
          childCommentsProps="taskcomment_set"
          CommentBoxComponent={TaskCommentBox}
        />
      )}

      {props.wololo_reward && <WololoRewardLine {...props} />}
    </>
  );
};

export default DefaultTaskFooter;
