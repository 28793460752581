import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from '~/components/external-link';
import Icon from '~/components/icon';

import './style.scss';

const info = {
  home: {
    message: 'Ops! Sua home está vazia.'
  },
  trail: {
    message: 'Ops! Não há trilhas a serem exibidas.'
  },
  forum: {
    message: 'Ops! Não há itens a serem exibidos.'
  },
  default: {
    message: 'Ops! Não há itens a serem exibidos.'
  }
};

const EmptyScreen = props => {
  const data = info[props.name];
  return (
    <div style={{ textAlign: 'center' }}>
      <Icon name="list-empty" />
      <h3>{data.message}</h3>
      {props.children && <p>{props.children}</p>}
      {props.showExternalLink && (
        <p>
          <span>Se você acredita que esta mensagem é um erro, </span>
          <ExternalLink to="https://example.com">clique aqui</ExternalLink>.
        </p>
      )}
    </div>
  );
};

EmptyScreen.propTypes = {
  // name: PropTypes.oneOf(['home']).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default EmptyScreen;
