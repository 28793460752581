import styled from 'styled-components';

export const StyledCourseScorm = styled.div.attrs(() => ({
  className: 'course-datails-page'
}))`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;

  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    span {
      font-size: 20px;
      width: 380px;
      text-align: center;
    }

    .error {
      color: var(--failure-color);
    }
  }

  .screen-loading {
    margin-top: 64px;
  }
`;
