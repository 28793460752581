import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCompetencePreview } from '~/api/performance';

import { showMessage } from '~/store/ducks/messageBar';

import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Card from '~/components/card';
import CollaboratorCard from '~/components/collaborator-card';
import PageControl from '~/components/page-control';
import PerformanceQuestionPreview from '~/components/performance-question-preview';
import OpenAnswer from '~/components/open-answer';
import Fab from '~/components/fab';
import CustomLoading from '~/components/custom-loading';
import AlertBar from '~/components/alert-bar';

import StyledPerformanceAnswers from './styles';
import { useTranslation } from 'react-i18next';

function PerformanceAnswers({ organization, showMessage, location, history }) {
  const { activeOrganizationId } = organization;
  const performanceId = location.pathname
    .split('/feedback/')[0]
    .replace('/performance/', '');
  const feedbackId = location.pathname
    .split('/feedback/')[1]
    .split('/competence/')[0];
  const competencePage = location.pathname.split('/competence/')[1];
  const firstPage = competencePage ? parseInt(competencePage, 10) : 1;

  const { t } = useTranslation();

  const [competenceConfig, setCompetenceConfig] = useState(null);
  const [evaluatedConfig, setEvaluatedConfig] = useState(null);
  const [performanceConfig, setPerformanceConfig] = useState(null);
  const [questionsConfig, setQuestionsConfig] = useState([]);

  const [manager, setManager] = useState({});
  const [isManager, setIsManager] = useState(false);
  const [calibration9box, setCalibration9box] = useState(null);
  const [hasManagedAnswer, setHasManagedAnswer] = useState(false);
  const [hasManagerAnswer, setHasManagerAnswer] = useState(false);

  const [currentPage, setCurrentPage] = useState(firstPage);
  const [loading, setLoading] = useState(true);
  const [questionsLoading, setQuestionsLoading] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    history.push(`/performance/${performanceId}/feedback/${feedbackId}`);
  };

  const changePage = page => {
    setQuestionsLoading(true);

    getCompetencePreview(activeOrganizationId, performanceId, feedbackId, page)
      .then(response => {
        setCurrentPage(page);
        const {
          managed,
          performance,
          questions,
          is_manager,
          manager,
          calibration_9box,
          has_managed_answer,
          has_manager_answer
        } = response.data;

        setIsManager(is_manager);
        setManager(manager);
        setCalibration9box(calibration_9box);
        setHasManagedAnswer(has_managed_answer);
        setHasManagerAnswer(has_manager_answer);

        delete response.data['evaluated'];
        delete response.data['performance'];
        delete response.data['questions'];

        setCompetenceConfig(response.data);
        setEvaluatedConfig(managed);
        setPerformanceConfig(performance);
        setQuestionsConfig(questions);

        window.history.pushState(
          'competence',
          'competence',
          `${location.pathname.split('/competence')[0]}/competence/${page}`
        );
      })
      .catch(error => {
        history.push('/unexpected-error');
      })
      .finally(() => {
        setQuestionsLoading(false);
      });
  };

  useEffect(() => {
    getCompetencePreview(
      activeOrganizationId,
      performanceId,
      feedbackId,
      firstPage
    )
      .then(response => {
        const {
          managed,
          performance,
          questions,
          is_manager,
          manager,
          calibration_9box,
          has_managed_answer,
          has_manager_answer
        } = response.data;

        setIsManager(is_manager);
        setManager(manager);
        setCalibration9box(calibration_9box);
        setHasManagedAnswer(has_managed_answer);
        setHasManagerAnswer(has_manager_answer);

        delete response.data['evaluated'];
        delete response.data['performance'];
        delete response.data['questions'];

        setCompetenceConfig(response.data);
        setEvaluatedConfig(managed);
        setPerformanceConfig(performance);
        setQuestionsConfig(questions);
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          showMessage(t('Esta avaliação não está disponível.'), 'danger', 3000);
          history.push(`/performance/${performanceId}/feedback/${feedbackId}`);
          return;
        }

        history.push('/unexpected-error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <StyledPerformanceAnswers>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              {t('Voltar')}
            </Button>
          </div>
        </div>
      </CustomHeader>

      <div className="page-content">
        {!loading && (
          <>
            <div className="performance-header">
              <CollaboratorCard
                name={evaluatedConfig.name}
                avatar={evaluatedConfig.avatar_128x0}
                department={evaluatedConfig.department}
                profile={evaluatedConfig.profile}
                manager={manager}
              />
            </div>

            {calibration9box && (
              <Card
                className="box-card"
                style={{ background: calibration9box.box.color }}
              >
                <h3>{calibration9box.box.name}</h3>
                <p>{calibration9box.reason}</p>
              </Card>
            )}

            {!hasManagerAnswer && (
              <AlertBar icon="triangle-alert" theme="warning">
                {t('Não existe resposta do líder para esta avaliação.')}
              </AlertBar>
            )}

            {!hasManagedAnswer && (
              <AlertBar icon="triangle-alert" theme="warning">
                {t('Não existe resposta do liderado para esta avaliação.')}
              </AlertBar>
            )}

            <PageControl
              pageTitle={competenceConfig.title}
              pageDescription={competenceConfig.description}
              counterLabel={`${currentPage}/${competenceConfig.count}`}
              onPrevious={() =>
                changePage(
                  currentPage > 1 ? currentPage - 1 : competenceConfig.count
                )
              }
              onNext={() =>
                changePage(
                  currentPage !== competenceConfig.count
                    ? currentPage + 1
                    : firstPage
                )
              }
              disabled={questionsLoading || competenceConfig.count === 1}
            />

            <div className="performance-questions">
              {!questionsLoading &&
                questionsConfig.map((question, index) => (
                  <PerformanceQuestionPreview
                    key={question.pk}
                    questionNumber={index + 1}
                    statement={question.title}
                    modelImage={performanceConfig.answer_model}
                    options={question.options}
                    answer={
                      isManager
                        ? question.manager_answer_option
                        : question.managed_answer_option
                    }
                    descriptiveAnswer={question.manager_open_answer}
                    openAnswerQuestion={question.open_answer_question}
                    answerByManaged={
                      isManager
                        ? question.managed_answer_option
                        : question.manager_answer_option
                    }
                    descriptiveAnswerByManaged={question.managed_open_answer}
                    canViewAnswers={true}
                    questions={questionsConfig}
                    disabled={true}
                  />
                ))}

              {!questionsLoading &&
                competenceConfig &&
                competenceConfig.open_answer_question && (
                  <OpenAnswer
                    title={`${competenceConfig.open_answer_question} ${t(
                      '(Liderado)'
                    )}`}
                    descriptiveAnswer={competenceConfig.managed_open_answer}
                    disabled={true}
                  />
                )}

              {!questionsLoading &&
                competenceConfig &&
                competenceConfig.open_answer_question && (
                  <OpenAnswer
                    title={`${competenceConfig.open_answer_question} ${t(
                      '(Líder)'
                    )}`}
                    descriptiveAnswer={competenceConfig.manager_open_answer}
                    disabled={true}
                  />
                )}

              {!questionsLoading &&
                performanceConfig &&
                performanceConfig.open_answer_question &&
                currentPage === competenceConfig.count && (
                  <OpenAnswer
                    title={`${performanceConfig.open_answer_question} ${t(
                      '(Liderado)'
                    )}`}
                    descriptiveAnswer={performanceConfig.managed_open_answer}
                    disabled={true}
                  />
                )}

              {!questionsLoading &&
                performanceConfig &&
                performanceConfig.open_answer_question &&
                currentPage === competenceConfig.count && (
                  <OpenAnswer
                    title={`${performanceConfig.open_answer_question} ${t(
                      '(Líder)'
                    )}`}
                    descriptiveAnswer={performanceConfig.manager_open_answer}
                    disabled={true}
                  />
                )}

              {questionsLoading && (
                <CustomLoading type="spin" height={56} width={56} fluid />
              )}
            </div>

            <Fab>
              <Button
                color="dark"
                className="rounded go-to-top"
                onClick={scrollToTop}
              >
                <Icon name="arrow-up" />
              </Button>
            </Fab>
          </>
        )}

        {loading && <CustomLoading type="spin" height={56} width={56} fluid />}
      </div>
    </StyledPerformanceAnswers>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PerformanceAnswers));
