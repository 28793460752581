import styled from 'styled-components';

export const StyledNPS = styled.div.attrs(() => ({
  className: 'nps-step'
}))`
  width: 100%;

  .step-section {
    padding: 32px 42px;
    border-bottom: 1px solid var(--border-color);

    h3,
    h2 {
      font-weight: normal;
    }

    .marker {
      background-color: yellow;
    }

    > p:not([class]) {
      margin-bottom: 20px;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        width: 100%;
        padding-right: 16px;
        font-weight: bold;
      }

      button {
        min-width: max-content;
      }

      i {
        font-size: 16px;
        font-weight: bold;

        margin-right: 14px;

        &.correct {
          color: var(--success-color);
        }

        &.incorrect {
          color: var(--failure-color);
        }
      }
    }
  }

  .nps-options {
    width: 100%;
    padding-bottom: 16px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .option-wrapper {
      position: relative;
      width: max-content;
      margin: 16px;
      height: 34px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .button {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        padding: 0px;
        border: 1px solid var(--border-color);
        color: var(--secondary-text-color);
        text-align: center;
        line-height: 32px;

        display: flex;
        justify-content: center;
        align-items: center;

        :hover {
          background: var(--background-color);
          color: var(--primary-color);
        }

        &.selected {
          border-color: var(--primary-color);
          color: var(--primary-color);
        }
      }

      .description {
        position: absolute;
        width: 64px;
        max-width: 64px;
        line-height: 1;
        text-align: start;
        color: var(--secondary-text-color);
        font-size: 12px;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .option-wrapper:first-child {
      .description {
        left: -72px;
        text-align: end;
      }
    }

    .option-wrapper:last-child {
      .description {
        right: -72px;
      }
    }
  }

  .align-left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
