import React, { useState } from 'react';

import { Input } from '~/components/form';

import { StyledCustomString } from './styles';

function CustomString({
  id,
  label,
  answer,
  data,
  setData,
  readOnly,
  errorMessage,
  multiLine,
  maxLenght
}) {
  const [count, setCount] = useState(answer ? answer.length : 0);

  const setText = value => {
    value = value.substring(0, maxLenght);
    setCount(value.length);

    setData(lastData => {
      const newData = [...lastData];

      const currentCustomFieldIndex = newData.findIndex(
        customField => customField.pk === id
      );

      newData[currentCustomFieldIndex].answer = value;
      return newData;
    });
  };

  return (
    <StyledCustomString className={errorMessage ? 'has-error' : ''}>
      <label>{label}</label>
      <Input
        value={answer || ''}
        onChange={event => setText(event.target.value)}
        disabled={readOnly}
        textarea={multiLine}
      />
      <div className="counter">
        <span>{count}</span>/<span>{maxLenght}</span>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </StyledCustomString>
  );
}

export default CustomString;
