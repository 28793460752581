import React from 'react';

import Card from '~/components/card';

import { useTranslation } from 'react-i18next';

import { StyledPerformanceHistoricItem } from './styles';

function PerformanceHistoricItem({
  title,
  department,
  managed,
  manager,
  onClick
}) {
  const { t } = useTranslation();

  return (
    <StyledPerformanceHistoricItem onClick={onClick}>
      <Card className="historic-item">
        <div className="historic-item-header padding-card-deafult">
          <h3>
            {department} - {title}
          </h3>
        </div>

        <div className={`participants-info ${!!manager ? 'grid-layout' : ''}`}>
          <div className="participant padding-card-deafult managed">
            <div className="avatar">
              <img src={managed.avatar} alt="Avatar" />
            </div>
            <div className="text">
              <p>{t('Avaliação de')}</p>
              <h3>{managed.name}</h3>
              <p>{managed.profile}</p>
            </div>
          </div>

          {!!manager ? (
            <div className="participant padding-card-deafult manager">
              <div className="avatar">
                <img src={manager.avatar} alt="Avatar" />
              </div>
              <div className="text">
                <p>{t('Avaliado por')}</p>
                <h3>{manager.name}</h3>
                <p>{manager.profile}</p>
              </div>
            </div>
          ) : null}
        </div>
      </Card>
    </StyledPerformanceHistoricItem>
  );
}

export default PerformanceHistoricItem;
