import _ from 'lodash';
import React, {
  useCallback,
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo
} from 'react';
import useAccount from '~/hooks/use-account';
import moment from 'moment';
import './style.scss';
import Card from '~/components/card';
import ProfileInfo from '~/components/profile-info';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'redux-react-hook';
import { InputFinalForm } from '~/components/form';
import Modal from '~/components/modal';
import Loading from '~/components/loading';
import { maxminLengthValueValidator } from '~/components/form/validators';
import useComments from '~/hooks/use-comments';
import {
  loadComments,
  loadNextComments,
  createComment,
  destroyComment,
  editComment
} from '~/store/ducks/comments';

const FormConfig = createContext(null);

const CommentForm = ({ onSubmit, initialValues = {}, placeholder }) => (
  <Form onSubmit={onSubmit} initialValues={initialValues}>
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Field
          name="comment"
          validate={maxminLengthValueValidator}
          pattern=".+"
          placeholder={placeholder}
          component={InputFinalForm}
        />
      </form>
    )}
  </Form>
);

const Comment = ({
  isCurrentUser,
  comment,
  comment: { owner, created_at },
  renderForm,
  newCommentLoading,
  onNewComment,
  onShowCommentsOptions,
  onCloseCommentsOptions,
  showCommentsOptions,
  onShowEditComment,
  showEditComment,
  onEditComment,
  onChange,
  onDestroy,
  mapParentChildComments
}) => {
  const children = mapParentChildComments[comment.id];

  return (
    <Card>
      <ProfileInfo
        name={owner.name}
        image={owner['avatar_128x0'] || owner.avatar}
        info={moment(created_at).fromNow()}
        right={
          isCurrentUser &&
          comment.status !== 'removed' && (
            <div className="relative">
              <button
                onClick={onShowCommentsOptions}
                className="button link secondary"
              >
                ...
              </button>

              {showCommentsOptions && (
                <Modal onClose={onCloseCommentsOptions} hideClose>
                  <div className="text-center">
                    <button
                      onClick={onShowEditComment}
                      className="button link black"
                    >
                      Editar
                    </button>
                  </div>

                  <div className="text-center">
                    <button className="button link danger" onClick={onDestroy}>
                      Apagar
                    </button>
                  </div>
                </Modal>
              )}
            </div>
          )
        }
      />

      {!showEditComment && comment.status !== 'removed' && (
        <p>{comment.comment}</p>
      )}

      {!showEditComment && comment.status === 'removed' && (
        <p className="removed-message">
          Este comentário foi removido por conter palavras bloqueadas pela
          comunidade
        </p>
      )}

      {showEditComment && (
        <CommentForm
          initialValues={{
            comment: comment.comment
          }}
          onSubmit={onEditComment}
        />
      )}

      {children &&
        children.map(childComment => (
          <CommentEnhanced
            parentCommentId={comment.id}
            key={childComment.id}
            comment={childComment}
            mapParentChildComments={mapParentChildComments}
          />
        ))}

      <Loading visible={newCommentLoading} inline />

      {renderForm && !showEditComment && comment.status !== 'removed' && (
        <CommentForm
          placeholder={`Responda a ${owner.name}`}
          onSubmit={onNewComment}
        />
      )}
    </Card>
  );
};

const CommentEnhanced = props => {
  const [newCommentLoading, setNewCommentLoading] = useState(false);
  const [editCommentLoading, setEditCommentLoading] = useState(false);
  const [showCommentsOptions, setShowCommentsOptions] = useState(false);
  const [showEditComment, setShowEditComment] = useState(false);
  const dispatch = useDispatch();

  const selfId = props.comment.id;

  const account = useAccount();

  const { objectId, objectType } = useContext(FormConfig);

  const onNewComment = useCallback(
    ({ comment }, form) => {
      // já tem comentários sendo submetido
      if (newCommentLoading) {
        return;
      }

      setNewCommentLoading(true);

      const owner = {
        id: account.data.id,
        name: account.data.profile.name,
        avatar: account.data.profile.avatar,
        avatar_128x0: account.data.profile.avatar_128x0
      };

      dispatch(
        createComment(objectType, objectId, {
          parentId: selfId,
          comment,
          owner
        })
      )
        .then(() => form.reset())
        .finally(() => {
          setNewCommentLoading(false);
        });
    },
    [newCommentLoading, account, objectId, objectType, selfId]
  );

  const onDestroy = () => {
    if (window.confirm('Tem certeza? Essa ação não pode ser desfeita')) {
      dispatch(destroyComment(objectType, objectId, selfId));
    }
  };

  const onEditComment = useCallback(
    ({ comment }, form) => {
      // já tem comentários sendo submetido
      if (editCommentLoading) {
        return;
      }

      setEditCommentLoading(true);

      dispatch(
        editComment(objectType, objectId, {
          parentId: props.comment.parent,
          commentId: selfId,
          comment
        })
      )
        .then(() => {
          setShowEditComment(false);
        })
        .finally(() => {
          setEditCommentLoading(false);
        });
    },
    [editCommentLoading]
  );

  return (
    <Comment
      {...props}
      isCurrentUser={account.data.id === props.comment.owner.id}
      onNewComment={onNewComment}
      newCommentLoading={newCommentLoading}
      onShowCommentsOptions={() => setShowCommentsOptions(true)}
      onCloseCommentsOptions={() => setShowCommentsOptions(false)}
      showCommentsOptions={showCommentsOptions}
      onShowEditComment={() => {
        setShowEditComment(true);
        setShowCommentsOptions(false);
      }}
      onHideEditComment={() => setShowEditComment(false)}
      showEditComment={showEditComment}
      onEditComment={onEditComment}
      onDestroy={onDestroy}
    />
  );
};

export const CommentBox = ({
  loading,
  newCommentLoading,
  comments,
  onChange,
  onDestroy,
  onNewComment,
  mapParentChildComments,
  onNext,
  hasNext
}) => (
  <section className="comment-box">
    <h2 className="comment-box-title">Comentários</h2>

    <div className="comments-list">
      <CommentForm
        placeholder="Escreva seu comentário"
        onSubmit={onNewComment}
      />

      <Loading visible={newCommentLoading} inline />

      {comments.length === 0 && !loading && (
        <p>Sem comentários no momento. Que tal enviar um?</p>
      )}

      {comments.length > 0 &&
        comments.map(comment => (
          <CommentEnhanced
            mapParentChildComments={mapParentChildComments}
            key={comment.id}
            comment={comment}
            renderForm
            onChange={newComment => {
              const commentsCopy = [...comments];

              commentsCopy.splice(comments.indexOf(comment), 1, newComment);

              onChange(commentsCopy);
            }}
          />
        ))}

      <Loading visible={loading} inline />

      {hasNext && (
        <button className="button secondary fill" onClick={onNext}>
          Carregar mais
        </button>
      )}
    </div>
  </section>
);

const CommentProvider = props => {
  const [newCommentLoading, setNewCommentLoading] = useState(false);

  const { loading, comments, count, nextUrl } = useComments(
    props.actionType,
    props.id
  );

  const dispatch = useDispatch();
  const account = useAccount();

  useEffect(() => {
    dispatch(loadComments(props.actionType, props.id));
  }, []);

  const onNext = useCallback(() => {
    if (loading || !nextUrl) {
      return;
    }

    dispatch(loadNextComments(props.actionType, props.id, nextUrl));
  }, [loading, nextUrl]);

  const onNewComment = useCallback(
    ({ comment }, form) => {
      // já tem comentários sendo submetido
      if (newCommentLoading) {
        return;
      }

      setNewCommentLoading(true);

      const owner = {
        id: account.data.id,
        name: account.data.profile.name,
        avatar: account.data.profile.avatar,
        avatar_128x0: account.data.profile.avatar_128x0
      };

      dispatch(createComment(props.actionType, props.id, { comment, owner }))
        .then(() => {
          // props.incrementCommentCounter();
          form.reset();
        })
        .finally(() => setNewCommentLoading(false));
    },
    [comments, newCommentLoading]
  );

  const onDestroy = useCallback(comment => {
    dispatch(destroyComment(props.actionType, props.id, comment.id));
  }, []);

  const sortedComments = useMemo(
    () =>
      _.sortBy(comments, c => {
        return -c.id;
      }),
    [comments]
  );

  const mapParentChildComments = useMemo(
    () =>
      // parent é um id
      _.groupBy(sortedComments, comment => comment.parent),
    [sortedComments]
  );

  const rootComments = useMemo(
    () => sortedComments.filter(comment => !comment.parent),
    [sortedComments]
  );

  return (
    <FormConfig.Provider
      value={{
        objectType: props.actionType,
        objectId: props.id
      }}
    >
      {props.render({
        mapParentChildComments: mapParentChildComments,
        comments: rootComments,
        loading: loading,
        onChange: () => {},
        onNext: onNext,
        hasNext: !!nextUrl,
        onDestroy: onDestroy,
        newCommentLoading: newCommentLoading,
        onNewComment: onNewComment,
        commentCount: count
      })}
    </FormConfig.Provider>
  );
};

export const TaskCommentBox = props => {
  return (
    <CommentProvider
      actionType="task"
      {...props}
      render={data => <CommentBox {...data} />}
    />
  );
};

export const PostCommentBox = props => {
  return (
    <CommentProvider
      actionType="post"
      {...props}
      render={data => <CommentBox {...data} />}
    />
  );
};

export const ForumPostCommentBox = props => {
  return (
    <CommentProvider
      actionType="forumpost"
      {...props}
      render={data => <CommentBox {...data} />}
    />
  );
};
