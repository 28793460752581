import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';
import moment from 'moment';

import { getForumPost } from '~/api/forum';
import { forumpostLoaded } from '~/store/ducks/actions';
// import useComments from '~/hooks/use-comments';

import Card from '~/components/card';
import Modal from '~/components/modal';
import FakeLink from '~/components/fake-link';
// import PostHeader from '~/components/post-header';
import PostImage from '~/components/post-image';
import PostComment from '~/components/post-comment';
import ViewForumPost from '~/components/view-forum-post';
import ProfileInfo from '~/components/profile-info';

import './style.scss';

export const ViewForumPostControl = ({ id, render }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getForumPost(id)
      .then(({ data }) => {
        dispatch(forumpostLoaded(data));
        setData(data);
      })
      .catch(e => {
        setError(e);
      });
  }, []);

  return render({
    data,
    error
  });
};

const ForumPost = ({ forumPost, owner }) => {
  const [showViewPost, setShowViewPost] = useState(false);
  // const [postContent, setPostContent] = useState(null);

  // const comment = useComments('forumpost', forumPost.id);

  const onViewPostClose = useCallback(() => setShowViewPost(null));

  const viewPost = useCallback(() => {
    setShowViewPost(true);
  }, []);

  // useEffect(() => {
  //   const forumPostContent = forumPost.content;
  //   const tagClosingIndex = forumPostContent.indexOf('</style>');
  //   const styleTag = forumPostContent.substr(0, tagClosingIndex + 8);
  // }, []);

  return (
    <div className="forum-post">
      {showViewPost && (
        <Modal
          className="modal forum-modal"
          overlayClassName="modal-overlay"
          onClose={onViewPostClose}
        >
          <ViewForumPostControl
            id={forumPost.id}
            render={({ data }) => data && <ViewForumPost data={data} />}
          />
        </Modal>
      )}
      <FakeLink
        to={`/post?id=${forumPost.id}`}
        onClick={viewPost}
        data={{
          id: forumPost.id
        }}
      >
        <Card>
          <ProfileInfo
            name={owner.name}
            image={owner.avatar_128x0}
            info=""
            rightImage={null}
          />
          <div className="card-body">
            {forumPost.image && <PostImage url={forumPost.image} />}
            <h3>{forumPost.title}</h3>
            <p>{forumPost.description}</p>
          </div>
          <footer className="card-footer">
            <div>
              {forumPost.can_comment && (
                <PostComment className="inline-block" onClick={viewPost}>
                  {forumPost.comment_counter} Respostas
                </PostComment>
              )}
            </div>
            {forumPost.updated_at && (
              <div style={{ textAlign: 'right' }}>
                <small>
                  <i>
                    {moment(forumPost.updated_at).format(
                      'D [de] MMM [de] YYYY [-] HH:mm'
                    )}
                  </i>
                </small>
              </div>
            )}
          </footer>
        </Card>
      </FakeLink>
    </div>
  );
};

export default ForumPost;
