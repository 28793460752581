import React, { useState, useEffect } from 'react';

import { StyledEditorialLineIndicator } from './styles';

function EditorialLineIndicator({ orgEditorialLines, currentEditorialId }) {
  const [currentEditorialTitle, setCurrentEditorialTitle] = useState(null);

  useEffect(() => {
    setCurrentEditorialTitle(null);
    if (currentEditorialId && orgEditorialLines) {
      for (let line of orgEditorialLines) {
        if (line.id.toString() === currentEditorialId.toString()) {
          setCurrentEditorialTitle(line.title);
          break;
        }
      }
    }
  }, []);

  return (
    <>
      {currentEditorialTitle ? (
        <StyledEditorialLineIndicator>
          <span>{currentEditorialTitle}</span>
        </StyledEditorialLineIndicator>
      ) : null}
    </>
  );
}

export default EditorialLineIndicator;
