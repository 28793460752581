import React from 'react';
import moment from 'moment';
import './style.scss';

import { useTranslation } from 'react-i18next';

const TaskExpire = ({ time }) => {
  const { i18n } = useTranslation();
  const userLanguage = i18n.language;

  return (
    <div>
      {moment
        .parseZone(time)
        .locale(userLanguage)
        .fromNow(true)}
    </div>
  );
};

export default TaskExpire;
