import React from 'react';
import { withRouter } from 'react-router-dom';
import ViewPost from '~/components/view-post';
import { ViewPostControl } from '~/components/feed/post/post';
import Card from '~/components/card';

const PostScreen = ({ match }) => {
  return (
    <Card>
      <ViewPostControl
        id={match.params.id}
        render={({ data, error }) => {
          if (data) {
            return <ViewPost data={data} />;
          } else if (error) {
            return <p>Houve um erro ao carregar o post</p>;
          }

          return null;
        }}
      />
    </Card>
  );
};

export default withRouter(PostScreen);
