import React from 'react';
import MaskedInput from 'react-text-mask';
import './style.scss';

const Input = ({
  invalid,
  textCenter,
  small,
  textarea,
  rows = 5,
  pattern = '',
  step = 1,
  ...props
}) => {
  const preventNonNumericInput = event => {
    const allowedCharacters = [
      '-',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '.',
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight'
    ];
    if (!allowedCharacters.includes(event.key.toString())) {
      event.preventDefault();
    }
  };

  const classes = ['input'];
  if (invalid) classes.push('invalid');
  if (textCenter) classes.push('text-center');
  if (small) classes.push('small');

  if (props.name) {
    classes.push(`input-${props.name}`);
  }

  const data = {
    className: classes.join(' '),
    type: 'text',
    ...props
  };

  if (pattern !== '') {
    data.pattern = pattern;
  }

  if (props.type === 'number') {
    return <input {...data} onKeyDown={preventNonNumericInput} type="number" />;
  }

  if (props.mask) {
    return <MaskedInput {...data} />;
  }

  if (textarea) {
    return <textarea rows={rows} {...data} />;
  }

  return <input {...data} step={step} />;
};

export default Input;
