import styled from 'styled-components';

export const StyledPerformanceHistoricItem = styled.button.attrs(() => ({
  className: 'performance-historic-item',
  type: 'button'
}))`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  margin-bottom: 16px;
  padding: 0px;

  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 16px var(--background-placeholder-color);
  }

  .card {
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;

    .historic-item-header {
      width: 100%;

      h3 {
        max-width: 64ch;

        font-size: 18px;
        font-weight: normal;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        text-align: left;
      }
    }

    .participants-info {
      width: 100%;

      border-top: 1px solid var(--border-color);

      &.grid-layout {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
      }

      & > div + div {
        border-left: 1px solid var(--border-color);
      }

      .participant {
        gap: 16px;
        display: flex;

        .avatar {
          img {
            width: 36px;
            min-width: 36px;

            height: 36px;

            border-radius: 50%;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;

          p:last-child {
            color: var(--secondary-text-color);
          }
        }
      }
    }
  }

  .padding-card-deafult {
    padding: 16px 32px;
  }

  @media (max-width: 500px) {
    .participants-info {
      &.grid-layout {
        grid-template-columns: 1fr !important;
      }

      & > div + div {
        border-left: none !important;
        border-top: 1px solid var(--border-color);
      }
    }
  }
`;
