import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';
import { connect } from 'react-redux';

import { getToken, upadateTokenKey } from '~/services/auth';
import { setUrlToRedirect } from '~/services/navigation';

import { tokenRefresh } from '~/api/auth';

import { setOrganization } from '~/store/ducks/organization';

import { storeOrganizationId } from '~/utils';

import CustomLoading from '~/components/custom-loading';

import StyledConsumeFeedLink from './styles';

function ConsumeFeedLink({ location, history }) {
  const dispatch = useDispatch();

  const organizationId = location.pathname.split('org/')[1].split('/')[0];
  let taskId = null;
  let postId = null;

  if (location.pathname.indexOf('/task/') !== -1) {
    taskId = location.pathname.split('/task/')[1].replace('/', '');
  }

  if (location.pathname.indexOf('/post/') !== -1) {
    postId = location.pathname.split('/post/')[1].replace('/', '');
  }

  const setOrganizationId = organizationId => {
    dispatch(setOrganization(organizationId));
    storeOrganizationId(organizationId);
  };

  useEffect(() => {
    const token = getToken();
    tokenRefresh({
      organization: organizationId,
      token: token
    })
      .then(response => {
        upadateTokenKey(response.data.token);
        setOrganizationId(organizationId);
        const url = taskId ? `/?task=${taskId}` : `/?post=${postId}`;

        setUrlToRedirect(url);

        window.location.href = url;
      })
      .catch(() => {
        history.push('/unexpected-error');
      });
  }, []);

  return (
    <StyledConsumeFeedLink>
      <CustomLoading type="spin" height={56} width={56} fluid />
      <p>Carregando...</p>
    </StyledConsumeFeedLink>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default withRouter(connect(mapStateToProps)(ConsumeFeedLink));
