import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getPerformanceRedirect } from '~/api/performance';
import { tokenRefresh } from '~/api/auth';

import { getToken, upadateTokenKey } from '~/services/auth';

import { storeOrganizationId } from '~/utils';

import CustomLoading from '~/components/custom-loading';
import CustomHeader from '~/components/custom-header';

import StyledDoPerformance from './styles';
import { useTranslation } from 'react-i18next';

function DoPerformance({ account, location, history }) {
  const { pathname } = location;

  const { t } = useTranslation();

  const cycleId = pathname.split('/do-performance/')[1];
  const organizationId = pathname
    .split('/do-performance/')[0]
    .replace('/org/', '');

  const setOrganizationId = organizationId => {
    storeOrganizationId(organizationId);
  };

  useEffect(() => {
    getPerformanceRedirect(organizationId, cycleId)
      .then(response => {
        if (account.loaded) {
          const token = getToken();
          tokenRefresh({
            organization: organizationId,
            token: token
          })
            .then(refreshTokenResponse => {
              upadateTokenKey(refreshTokenResponse.data.token);
              setOrganizationId(organizationId);

              const url = new URL(response.data.url);
              window.location.href = `${url.pathname}${url.search}`;
            })
            .catch(() => {
              history.push('/unexpected-error');
            });
        }
      })
      .catch(() => {
        history.push('/page-not-found');
      });
  }, []);

  return (
    <StyledDoPerformance>
      <CustomHeader expanded />
      <CustomLoading type="spin" height={56} width={56} fluid />
      <p>{t('Carregando a avaliação...')}</p>
    </StyledDoPerformance>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(withRouter(DoPerformance));
