import React from 'react';
import RewardBadge from '~/assets/images/Reward-Badge.png';
import './style.scss';

const WololoRewardLine = ({ wololo_reward }) => (
  <div className="reward-line">
    <img src={RewardBadge} alt="star" className="reward-icon" />
    <span>{wololo_reward}</span>
  </div>
);

export default WololoRewardLine;
