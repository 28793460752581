import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';

import { setLanguage } from '../../services/language';

import enIcon from '../../assets/images/languages/en.svg';
import esIcon from '../../assets/images/languages/es.svg';
import ptBRIcon from '../../assets/images/languages/pt.svg';
import ptPTIcon from '../../assets/images/languages/pt-pt.svg';

import './style.scss';

function ChangeLanguage() {
  const { i18n } = useTranslation();

  const [activeLanguage, setActiveLanguage] = useState(i18n.language);

  const options = [
    {
      value: 'en',
      label: (
        <div className="language-item">
          <img src={enIcon} alt="" />
          <p>EN</p>
        </div>
      )
    },
    {
      value: 'es',
      label: (
        <div className="language-item">
          <img src={esIcon} alt="" />
          <p>ES</p>
        </div>
      )
    },
    {
      value: 'pt',
      label: (
        <div className="language-item">
          <img src={ptBRIcon} alt="" />
          <p>PT-BR</p>
        </div>
      )
    },
    {
      value: 'pt-PT',
      label: (
        <div className="language-item">
          <img src={ptPTIcon} alt="" />
          <p>PT</p>
        </div>
      )
    }
  ];

  function changeLanguage(language) {
    i18n.changeLanguage(language);
    setActiveLanguage(language);
    setLanguage(language);
    window.location.reload();
  }

  return (
    <div className="change-language">
      <Dropdown
        options={options}
        value={options.filter(option => option.value === activeLanguage)[0]}
        onChange={e => changeLanguage(e.value)}
        placeholder="Select an option"
      />
    </div>
  );
}

export default ChangeLanguage;
