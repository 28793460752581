export const APP_ID = '628921817880793';

let endpoint;

if (process.env.REACT_APP_API) {
  endpoint = process.env.REACT_APP_API;
} else {
  endpoint =
    process.env.NODE_ENV === 'development'
      ? 'https://edukative.dev.talkative.media/v1'
      : 'https://edukative.talkative.media/v1';
}

export const REACT_APP_SITE = process.env.REACT_APP_SITE || 'edukative';

export const TOKEN_KEY = 'ktoken';
export const SKIP_TUTORIAL_KEY = 'skpt';

export const BASE_ENDPOINT = endpoint;

export const I18NEXT_LNG = 'i18nextLng';

export const CRISP_WEBSITE_ID = '6db8e69f-140e-4fa5-89dd-43f267ee3f3f';

export const kalturaPlayerConfig = {
  embedUrl: process.env.REACT_APP_KPLAYER_EMBED_URL,
  partnerId: process.env.REACT_APP_KPLAYER_PARTNER_ID,
  uiConfId: process.env.REACT_APP_KPLAYER_UI_CONF_ID
};

const {
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_HOMO_SSO_APP_ID,
  REACT_APP_HOMO_SSO_API_ID,
  REACT_APP_PROD_SSO_APP_ID,
  REACT_APP_PROD_SSO_API_ID
} = process.env;

const ssoAppIdEnvironments = {
  local: REACT_APP_HOMO_SSO_APP_ID,
  homo: REACT_APP_HOMO_SSO_APP_ID,
  prod: REACT_APP_PROD_SSO_APP_ID
};

const ssoApiIdEnvironments = {
  local: REACT_APP_HOMO_SSO_API_ID,
  homo: REACT_APP_HOMO_SSO_API_ID,
  prod: REACT_APP_PROD_SSO_API_ID
};

export const SSO_APP_ID = ssoAppIdEnvironments[REACT_APP_CURRENT_ENVIRONMENT];
export const SSO_API_ID = ssoApiIdEnvironments[REACT_APP_CURRENT_ENVIRONMENT];
