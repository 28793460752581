import React from 'react';

import { StyledSwitchButton } from './styles';

const SwitchButton = ({ className, id, checked, onChange, disabled }) => {
  return (
    <StyledSwitchButton
      className={`switch-button ${className} ${disabled ? 'disabled' : ''}`}
    >
      <input
        id={id}
        className="switch switch--shadow"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}></label>
    </StyledSwitchButton>
  );
};

export default SwitchButton;
