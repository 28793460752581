import React from 'react';
import PropTypes from 'prop-types';
import Bronze from '~/assets/values/bronze.png';
import Silver from '~/assets/values/silver.png';
import Gold from '~/assets/values/gold.png';
import Emerald from '~/assets/values/emerald.png';
import Diamond from '~/assets/values/diamond.png';
import './style.scss';

const types = {
  10: {
    name: 'Bronze',
    image: Bronze
  },
  150: {
    name: 'Bronze',
    image: Bronze
  },
  200: {
    name: 'Bronze',
    image: Bronze
  },
  400: {
    name: 'Prata',
    image: Silver
  },
  600: {
    name: 'Ouro',
    image: Gold
  },
  800: {
    name: 'Esmeralda',
    image: Emerald
  },
  1000: {
    name: 'Diamante',
    image: Diamond
  }
};

const Points = ({ value }) => {
  if (!types[value]) {
    return null;
  }

  return (
    <div className="points">
      <span>{value}</span>
      <img src={types[value].image} alt={types[value].name} />
    </div>
  );
};

Points.propTypes = {
  value: PropTypes.oneOf([10, 150, 200, 400, 600, 800, 1000]).isRequired
};

export default Points;
