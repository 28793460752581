import React from 'react';
import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { StyledCarousel } from './styles';

function Carousel({
  slideImages = [],
  slideElements = [],
  width,
  showArrows = false,
  showStatus = false,
  showThumbnails = false,
  stopOnHover = false,
  useKeyboardArrows = false,
  autoPlay = true,
  playInterval = 5,
  infiniteLoop = true
}) {
  const renderSlides = () => {
    if (slideElements.length !== 0) {
      return slideElements.map(element => (
        <div className="carousel-slide">{element}</div>
      ));
    }

    return slideImages.map(slide => {
      return (
        <div className="carousel-slide">
          <img src={slide} alt="" />
        </div>
      );
    });
  };

  return (
    <StyledCarousel className="carousel">
      <ReactResponsiveCarousel
        width={width}
        dynamicHeight={true}
        interval={playInterval * 1000 /* s -> ms */}
        autoPlay={autoPlay}
        infiniteLoop={infiniteLoop}
        showArrows={showArrows}
        showStatus={showStatus}
        showThumbs={showThumbnails}
        stopOnHover={stopOnHover}
        useKeyboardArrows={useKeyboardArrows}
      >
        {renderSlides()}
      </ReactResponsiveCarousel>
    </StyledCarousel>
  );
}

export default Carousel;
