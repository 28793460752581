import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Prompt, withRouter } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';

import { showMessage } from '~/store/ducks/messageBar';
import { openDefaultModal } from '~/store/ducks/default-modal';

import { getCustomFields, saveCustomFields } from '~/api/account';

import Loading from '~/components/loading';
import AccountMenu from '~/screens/account/account-menu';
import Icon from '~/components/icon';
import customFieldsFactory from '~/components/custom-fields/custom-fields-factory';
import PendingModificationModal from '~/components/pending-modification-modal';

import StyledMyProfile from './styles';
import { useFormDirtyCheck } from '~/hooks/use-form-dirty-check';

function MyProfile({ showMessage, history }) {
  const dispatch = useDispatch();
  const { isDirty, setIsDirty, setPageToNavigate } = useFormDirtyCheck(history);

  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState([]);

  const handleChangeCustomFields = data => {
    setIsDirty(true);
    setCustomFields(data);
  };

  const handlePendingChanges = nextLocation => {
    dispatch(
      openDefaultModal(
        <PendingModificationModal
          onAction={() => {
            setIsDirty(false);
            setPageToNavigate(nextLocation);
          }}
        />
      )
    );
  };

  const handleSave = () => {
    setIsDirty(false);
    setLoading(true);

    setCustomFields(lastCustomFields =>
      lastCustomFields.map(customField => {
        customField.error = null;
        return customField;
      })
    );

    const filteredCustomFields = customFields.filter(
      customField => !customField.read_only
    );
    const data = filteredCustomFields.map(customField => ({
      key: customField.pk,
      value: customField.answer
    }));

    saveCustomFields({
      account_custom_fields: data
    })
      .then(() => {
        showMessage('Perfil atualizado', 'success', 3000);
        loadCustomFields();
      })
      .catch(error => {
        const { status, data } = error.response;

        if (status === 400) {
          setCustomFields(lastCustomFields => {
            const newCustomFields = lastCustomFields.map(customField => {
              if (
                data.errors.some(
                  dataError =>
                    parseInt(dataError.key) === parseInt(customField.pk)
                )
              ) {
                const [fieldError] = data.errors.filter(
                  error => parseInt(error.key) === parseInt(customField.pk)
                );

                customField.error = fieldError.error;
              }
              return customField;
            });
            return newCustomFields;
          });

          return;
        }

        showMessage('Ocorreu um erro ao salvar.', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadCustomFields = () => {
    setLoading(true);

    getCustomFields()
      .then(response => {
        const data = response.data.map(customfield => {
          if (customfield.type === 'custom_decimal') {
            customfield.answer = parseFloat(customfield.answer);
          }
          return customfield;
        });

        setCustomFields(data);
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao carregar o perfil.', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadCustomFields();
  }, []);

  return (
    <StyledMyProfile>
      <Prompt
        when={isDirty}
        message={location => {
          handlePendingChanges(location);
          return false;
        }}
      />
      <form onSubmit={() => {}}>
        <Loading visible={loading} />
        <div className="account-screen">
          <AccountMenu />
          <div className="section-wrapper">
            <h2>Meu perfil</h2>
            <p>Mantenha suas informações de perfil atualizadas.</p>
          </div>

          <div className="form-section section-wrapper">
            <div style={{ maxWidth: '500px', width: '100%', flex: 'initial' }}>
              {customFields.map(customField => (
                <div key={customField.pk} className="input-group">
                  {customFieldsFactory(
                    customField.pk,
                    customField.label,
                    customField.options,
                    customField.answer,
                    customField.type,
                    customField.read_only,
                    customFields,
                    handleChangeCustomFields,
                    customField.error,
                    customField.multipleline,
                    customField.max_length
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="section-wrapper mobile-buttons-full actions">
            <button
              type="button"
              className="button primary ml10"
              onClick={handleSave}
            >
              <Icon name="floppy-disk" /> Salvar
            </button>
          </div>
        </div>
      </form>
    </StyledMyProfile>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(MyProfile));
