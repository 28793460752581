import styled from 'styled-components';

const StyledPerformanceGuestCompleted = styled.div.attrs(() => ({
  className: 'performance-guest-completed-page'
}))`
  .separator {
    span {
      padding: 0px;
    }
  }
`;

export default StyledPerformanceGuestCompleted;
