import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import Icon from '../icon';
import './style.scss';

const Poll = props => (
  <div className="poll text-center">
    <h3>{props.children}</h3>
    <div>
      <Button
        color="success"
        disabled={
          props.pollState.poll_yes_percent !== null &&
          props.pollState.yes !== null &&
          !props.pollState.yes
        }
        circle
        onClick={() => props.onChange(true)}
      >
        {props.pollState.yes === null && <Icon name="positive" />}

        {props.pollState.yes !== null && (
          <p>{parseInt(props.pollState.poll_yes_percent, 10)}%</p>
        )}
      </Button>
      <Button
        color="danger"
        disabled={
          props.pollState.poll_no_percent !== null &&
          props.pollState.yes !== null &&
          props.pollState.yes
        }
        circle
        onClick={() => props.onChange(false)}
      >
        {props.pollState.yes === null && <Icon name="negative" />}

        {props.pollState.yes !== null && (
          <p>{parseInt(props.pollState.poll_no_percent, 10)}%</p>
        )}
      </Button>
    </div>
  </div>
);

Poll.propTypes = {
  children: PropTypes.string,
  onChange: PropTypes.func
};

export default Poll;
