import styled from 'styled-components';

export const StyledSlideBalloon = styled.div`
  position: relative;

  .text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px 20px;
    gap: 4px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 150px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    max-width: 186px;
    color: var(--white-color);
    text-align: start;
    font-weight: 700;
    font-size: 16px;

    border-radius: 0px 20px 20px 20px;
    z-index: 10;
  }

  .float-border {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px 20px;
    gap: 4px;
    height: 96px;

    border: 1px solid rgba(255, 255, 255, 0.5);
    filter: drop-shadow(0px 4px 150px rgba(0, 0, 0, 0.25));

    border-radius: 0px 20px 28px;

    position: absolute;
    left: 30px;
    top: 35px;
    width: 186px;
  }
`;
