import React from 'react';
import Card from '~/components/card';
import PostHeader from '~/components/post-header';
import Tags from '~/components/tags';
import PostImage from '~/components/post-image';
import RumorInfo from '~/components/rumor-info';

const Rumor = props => {
  return (
    <Card>
      <PostHeader
        info="Rumor"
        icon_type="rumor"
        organization={props.original_data.organization}
        meTrail={props.me_trail}
      />
      <div className="card-body">
        <PostImage url={props.cover}>
          <RumorInfo isTrue={props.is_true} />
        </PostImage>
        <h3 style={{ textAlign: 'center' }}>{props.title}</h3>
        <p style={{ textAlign: 'center' }}>{props.description}</p>
        {props.tags && <Tags>{props.tags}</Tags>}
      </div>
    </Card>
  );
};

export default Rumor;
