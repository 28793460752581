import React, { useState } from 'react';
import moment from 'moment';
import store from '~/store';
import { openMessageModal } from '~/store/ducks/message-modal';

import {
  downloadWithToken,
  downloadFile as handleDownloadFile
} from '~/services/download';

import AlertModal from '~/components/alert-modal';
import { DownloadIcon } from '~/screens/downloads/downloads';
import Button from '~/components/button';

import { BASE_ENDPOINT } from '~/settings';

import defaultFileIcon from '~/assets/images/file-regular.svg';

import './styles.scss';

function LibraryFile({
  title,
  extension,
  size,
  image,
  file,
  createdAt,
  downloadFile
}) {
  const environment = process.env.REACT_APP_CURRENT_ENVIRONMENT;
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);

    downloadWithToken(BASE_ENDPOINT.split('/v1')[0] + file)
      .then(response => {
        new Blob([response.data]).text().then(text => {
          const fileUrl =
            environment === 'prod' || environment === 'homo'
              ? text
              : BASE_ENDPOINT.split('/v1')[0] + text;

          handleDownloadFile(fileUrl)
            .then(responseDownloadFile => {
              const url = window.URL.createObjectURL(
                new Blob([responseDownloadFile.data])
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${title}${extension}`);
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            })
            .catch(e => {
              store.dispatch(
                openMessageModal(
                  <AlertModal
                    title="Erro ao fazer download"
                    content="Tente novamente mais tarde"
                  />
                )
              );
            })
            .finally(() => {
              setLoading(false);
            });
        });
      })
      .catch(e => {
        store.dispatch(
          openMessageModal(
            <AlertModal
              title="Erro ao fazer download"
              content="Tente novamente mais tarde"
            />
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenUrl = event => {
    event.preventDefault();
    setLoading(true);

    downloadWithToken(BASE_ENDPOINT.split('/v1')[0] + file)
      .then(response => {
        new Blob([response.data]).text().then(text => {
          const link = document.createElement('a');
          link.href =
            environment === 'prod' || environment === 'homo'
              ? text
              : BASE_ENDPOINT.split('/v1')[0] + text;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      })
      .catch(() => {
        store.dispatch(
          openMessageModal(
            <AlertModal
              title="Erro ao fazer download"
              content="Tente novamente mais tarde"
            />
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="library-file-container">
      {downloadFile && (
        <div className="download-file">
          <Button
            color="primary"
            className="circle download-file-button"
            onClick={() => handleDownload()}
          >
            {loading ? '...' : <DownloadIcon />}
          </Button>
        </div>
      )}

      <a
        href={' '}
        onClick={event => handleOpenUrl(event)}
        rel="noopener noreferrer"
        className="library-file"
      >
        <div className="preview">
          {image ? (
            <img className="file-image" src={image} alt="File" />
          ) : (
            <img
              src={defaultFileIcon}
              alt="File"
              className="default-file-icon"
            />
          )}
        </div>
        <div className="description">
          <div className="title">
            <p className="text">{title}</p>
            <p className="extension">{extension}</p>
          </div>
          <div className="info">
            <p className="size">{(size * Math.pow(10, -6)).toFixed(2)} mb</p>
            <p className="created_at">
              {moment(createdAt).format('D [de] MMM [de] YYYY')}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
}

export default LibraryFile;
