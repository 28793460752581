import styled from 'styled-components';

export const StyledResearchQuestion = styled.div.attrs(() => ({
  className: 'research-question'
}))`
  width: 100%;
  padding: 16px 40px;

  .option-wrapper {
    cursor: pointer;
    background: #f6f6f6;
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;

    .check-list {
      display: flex;
      flex-direction: column;
      .check-item {
        display: flex;
        padding: 5px;

        input {
          margin-right: 10px;
        }

        input,
        label {
          vertical-align: middle;
        }
      }
    }
  }

  .field-label {
    align-items: flex-start;
    gap: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .question-number {
      width: 30px;
      min-width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      background-color: var(--background-color);
      margin-right: 8px;
      font-size: 16px;
    }

    .question-title {
      .question {
        font-size: 16px;
        span {
          color: red;
        }
      }
    }
  }

  .error {
    color: var(--failure-color);
    font-size: 11px;
  }

  .limited-textarea.failure {
    textarea {
      border-color: var(--failure-color);
      &::placeholder {
        color: var(--failure-color);
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: var(--failure-color);
      }

      &::-ms-input-placeholder {
        color: var(--failure-color);
      }
    }
  }

  .option-wrapper.failure {
    border: 1px solid var(--failure-color);
  }
`;
