import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import renderHTML from 'react-render-html';

import { getPreviousCycleCompetence } from '~/api/performance';

import { showMessage } from '~/store/ducks/messageBar';

import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import PageControl from '~/components/page-control';
import OpenAnswer from '~/components/open-answer';
import Fab from '~/components/fab';
import CustomLoading from '~/components/custom-loading';
import PerformanceQuestionPreviousCycle from '~/components/performance-question-previous-cycle';
import CollaboratorCardPreviousCycle from '~/components/collaborator-card-previous-cycle';

import { useTranslation } from 'react-i18next';

import StyledPerformancePreviousCycle from './styles';

function PerformancePreviousCycle({
  organization,
  showMessage,
  location,
  history,
  match
}) {
  const { activeOrganizationId } = organization;
  const performanceId = match.params.id;
  const competencePage = match.params.page;
  // isHistoryView - serve para saber se é uma visualização a partir do histórico de avaliação
  const isHistoryView =
    location.pathname.indexOf('/development/pdi/my-team/') !== -1;
  const backUrl = isHistoryView
    ? location.state.backUrl
    : `/performance/${performanceId}/competence/1`;

  const firstPage = competencePage ? parseInt(competencePage, 10) : 1;
  const [competenceConfig, setCompetenceConfig] = useState(null);
  const [evaluatedConfig, setEvaluatedConfig] = useState(null);
  const [performanceConfig, setPerformanceConfig] = useState(null);
  const [questionsConfig, setQuestionsConfig] = useState([]);
  const [manager, setManager] = useState({});

  const [currentPage, setCurrentPage] = useState(firstPage);
  const [loading, setLoading] = useState(true);
  const [questionsLoading, setQuestionsLoading] = useState(false);

  const { t } = useTranslation();

  const handleCompetenceDescriptiveAnswer = ({ target }) => {
    setCompetenceConfig(competence => ({
      ...competence,
      open_answer: target.value
    }));
  };

  const handlePerformanceDescriptiveAnswer = ({ target }) => {
    setPerformanceConfig(performance => ({
      ...performance,
      open_answer: target.value
    }));
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const loadPreviousCycleCompetenceData = (isChangingPage, page) => {
    if (isChangingPage) setQuestionsLoading(true);

    getPreviousCycleCompetence(
      activeOrganizationId,
      performanceId,
      page,
      !isChangingPage,
      isHistoryView
    )
      .then(response => {
        if (isChangingPage) setCurrentPage(page);
        const { evaluated, performance, questions, manager } = response.data;

        delete response.data['evaluated'];
        delete response.data['performance'];
        delete response.data['questions'];
        delete response.data['manager'];

        setCompetenceConfig(response.data);
        setEvaluatedConfig(evaluated);
        setManager(manager);
        if (!isChangingPage) setPerformanceConfig(performance);
        setQuestionsConfig(questions);

        if (isChangingPage) {
          window.history.pushState(
            'competence',
            'competence',
            `${
              location.pathname.split('/competence')[0]
            }/competence/previous-cycle/${page}`
          );
        }
      })
      .catch(error => {
        if (!isChangingPage) {
          if (error.response.status === 404 || error.response.status === 403) {
            showMessage(
              t('Esta avaliação não está disponível.'),
              'danger',
              3000
            );
            history.push('/performances');
            return;
          }
        }
        history.push('/unexpected-error');
      })
      .finally(() => {
        if (isChangingPage) setQuestionsLoading(false);
        else setLoading(false);
      });
  };

  const changePage = page => loadPreviousCycleCompetenceData(true, page);

  useEffect(() => loadPreviousCycleCompetenceData(false, firstPage), []);

  return (
    <StyledPerformancePreviousCycle>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button
              color="primary"
              className="link"
              onClick={() => history.push(backUrl)}
            >
              <Icon name="back" />
              {t('Voltar')}
            </Button>
          </div>
        </div>
      </CustomHeader>

      <div className="page-content">
        {!loading && (
          <>
            <div className="performance-header">
              <CollaboratorCardPreviousCycle
                name={evaluatedConfig.name}
                avatar={evaluatedConfig.avatar_128x0}
                department={evaluatedConfig.department}
                profile={evaluatedConfig.profile}
                cycle_start_at={competenceConfig.cycle_start_at}
                cycle_finish_at={competenceConfig.cycle_finish_at}
                manager={manager}
              />
            </div>

            <PageControl
              pageTitle={competenceConfig.title}
              pageDescription={competenceConfig.description}
              counterLabel={`${currentPage}/${competenceConfig.count}`}
              onPrevious={() =>
                changePage(
                  currentPage > 1 ? currentPage - 1 : competenceConfig.count
                )
              }
              onNext={() =>
                changePage(
                  currentPage !== competenceConfig.count
                    ? currentPage + 1
                    : firstPage
                )
              }
              disabled={questionsLoading || competenceConfig.count === 1}
            />

            <div className="performance-questions">
              {!questionsLoading &&
                questionsConfig.map((question, index) => (
                  <PerformanceQuestionPreviousCycle
                    key={question.pk}
                    questionNumber={index + 1}
                    statement={question.title}
                    modelImage={performanceConfig.answer_model}
                    options={question.options}
                    answer={question.answer_option}
                    descriptiveAnswer={question.open_answer}
                    openAnswerQuestion={question.open_answer_question}
                    answerByManaged={question.managed_answer_option}
                    descriptiveAnswerByManaged={question.managed_open_answer}
                    canViewAnswers={competenceConfig.can_view_answer}
                    questions={questionsConfig}
                    setQuestions={setQuestionsConfig}
                  />
                ))}

              {!questionsLoading &&
                competenceConfig &&
                competenceConfig.open_answer_question && (
                  <OpenAnswer
                    title={competenceConfig.open_answer_question}
                    descriptiveAnswer={competenceConfig.open_answer}
                    handleDescriptiveAnswer={handleCompetenceDescriptiveAnswer}
                    canViewAnswers={competenceConfig.can_view_answer}
                    descriptiveAnswerByManaged={
                      competenceConfig.managed_open_answer
                    }
                  />
                )}

              {!questionsLoading &&
                performanceConfig &&
                performanceConfig.open_answer_question &&
                currentPage === competenceConfig.count && (
                  <OpenAnswer
                    title={performanceConfig.open_answer_question}
                    descriptiveAnswer={performanceConfig.open_answer}
                    handleDescriptiveAnswer={handlePerformanceDescriptiveAnswer}
                    canViewAnswers={competenceConfig.can_view_answer}
                    descriptiveAnswerByManaged={
                      performanceConfig.managed_open_answer
                    }
                  />
                )}

              {questionsLoading && (
                <CustomLoading type="spin" height={56} width={56} fluid />
              )}
            </div>

            <Fab>
              <Button
                color="dark"
                className="rounded go-to-top"
                onClick={scrollToTop}
              >
                <Icon name="arrow-up" />
              </Button>
            </Fab>
          </>
        )}

        {loading && <CustomLoading type="spin" height={56} width={56} fluid />}
      </div>
    </StyledPerformancePreviousCycle>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PerformancePreviousCycle));
