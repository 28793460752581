import React from 'react';
import ReactCodeInput from 'react-code-input';

import { StyledCodeInput } from './styles';

function CodeInput({
  fields,
  label,
  type = 'text',
  isValid,
  disabled,
  value,
  onChange
}) {
  return (
    <StyledCodeInput>
      <div className="input-wrapper">
        {label && <label>{label}</label>}
        <ReactCodeInput
          type={type}
          value={value}
          isValid={isValid}
          disabled={disabled}
          fields={fields}
          onChange={onChange}
        />
      </div>
    </StyledCodeInput>
  );
}

export default CodeInput;
