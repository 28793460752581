import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';

import { getFeedbacks } from '~/api/feedback';

import { showMessage } from '~/store/ducks/messageBar';
import { openDefaultModal } from '~/store/ducks/default-modal';

import Select from '~/components/form/select';
import EmptyList from '~/components/empty-list';
import PageHeader from '~/components/page-header';
import FeedbackItem from './components/feedback-item';
import CustomLoading from '~/components/custom-loading';
import SearchInput from '~/components/form/search-input';
import InfiniteScroll from '~/components/infinite-scroll';
import FeedbackTargetsModal from './components/feedback-targets-modal/feedback-targets-modal';

import { StyledFeedbackList } from './styles';

function FeedbackList({ organization, history, showMessage }) {
  const { activeOrganizationId } = organization;
  const firstPage = 1;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [feedbackList, setFeedbackList] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('all');
  const [currentTimeOut, setCurrentTimeOut] = useState(null);

  const [order, setOrder] = useState('-feedback__start_at');

  const getFeedbackList = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    getFeedbacks(activeOrganizationId, filters, page, null, null)
      .then(response => {
        setFeedbackList(() => response.data.results);

        setNextPage(response.data.next);
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          history.push('/page-not-found');
          return;
        }

        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      getFeedbackList({
        name: value,
        status: status !== 'all' ? status : '',
        ordering: order
      });
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const handleStatus = event => {
    const { value } = event.target;

    setStatus(value);

    getFeedbackList({
      name: search,
      status: value !== 'all' ? value : '',
      ordering: order
    });
  };

  const handleOrder = event => {
    const { value } = event.target;

    getFeedbackList({
      name: search,
      status: status !== 'all' ? status : '',
      ordering: value
    });

    setOrder(value);
  };

  const openFeedbackTargetsModal = (id, feedbackId, isManager) => {
    dispatch(
      openDefaultModal(
        <FeedbackTargetsModal
          orgId={activeOrganizationId}
          targetId={id}
          feedbackId={feedbackId}
          isManager={isManager}
        />
      )
    );
  };

  const loadMoreFeedbacks = () => {
    setLoadingMore(true);

    const filters = {
      name: search,
      status: status !== 'all' ? status : '',
      ordering: order
    };

    getFeedbacks(activeOrganizationId, filters, currentPage + 1, null, null)
      .then(response => {
        setFeedbackList(lastFeedbackList => [
          ...lastFeedbackList,
          ...response.data.results
        ]);

        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    getFeedbackList({
      name: search,
      status: status !== 'all' ? status : '',
      ordering: order
    });
  }, [activeOrganizationId]);

  return (
    <StyledFeedbackList>
      <PageHeader>
        <div className="text-info">
          <h1>{t('Feedbacks')}</h1>
        </div>
      </PageHeader>

      <div className="filters">
        <SearchInput
          value={search}
          onChange={handleSearch}
          placeholder={t('Buscar feedback por título')}
        />
        <Select value={status} onChange={handleStatus}>
          <option value="all">{t('Todos')}</option>
          <option value="active">{t('Ativos')}</option>
          <option value="finished">{t('Finalizados')}</option>
        </Select>
        <Select value={order} onChange={handleOrder}>
          <option value="-feedback__start_at">{t('Mais recente')}</option>
          <option value="feedback__start_at">{t('Mais antigo')}</option>
        </Select>
      </div>

      {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      <div className="feedback-list">
        {!loading &&
          feedbackList.map(feedback => (
            <FeedbackItem
              key={feedback.pk}
              title={feedback.title}
              status={feedback.status}
              formatedDate={feedback.formated_date}
              onClick={() =>
                openFeedbackTargetsModal(
                  feedback.target_id,
                  feedback.pk,
                  feedback.is_manager
                )
              }
              isManager={feedback.is_manager}
            />
          ))}

        {!loading && feedbackList.length === 0 && (
          <EmptyList message={t('Nenhum feedback encontrado.')} />
        )}
      </div>

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )}

      <InfiniteScroll
        fetchMore={loadMoreFeedbacks}
        disabled={!nextPage || loading || loadingMore}
      />
    </StyledFeedbackList>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeedbackList));
