import api from './api';

export const getInitialFeed = ({ search, task, post, editorial }) => {
  return api.get('/accounts/me/feed', {
    params: {
      search,
      task_id: task,
      post_id: post,
      editorial
    }
  });
};

export const getNextItems = url => api.get(url);
