import axios from 'axios';
import { BASE_ENDPOINT, TOKEN_KEY, I18NEXT_LNG } from '../settings';

import { unregisterAccount } from '~/store/ducks/account';
import { logoff } from '~/services/auth';

const api = axios.create({
  baseURL: BASE_ENDPOINT
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      unregisterAccount();
      logoff();

      window.location.href = '/sign-in';
    }

    return Promise.reject(error);
  }
);

api.interceptors.request.use(async config => {
  const token = localStorage.getItem(TOKEN_KEY);

  if (token) {
    config.headers.Authorization = token;
    config.headers['Accept-Language'] = localStorage.getItem(I18NEXT_LNG);
  }

  const userLanguage =
    localStorage.getItem(I18NEXT_LNG) ||
    navigator.language ||
    navigator.userLanguage;

  if (userLanguage) {
    config.headers['Accept-Language'] = userLanguage;
  }

  return config;
});

export default api;
