import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledCollaboratorCard from './styles';

function CollaboratorCard({
  name,
  avatar,
  department,
  profile,
  manager,
  participantWhoEvaluated,
  children
}) {
  const { t } = useTranslation();
  return (
    <StyledCollaboratorCard>
      <div className="segmentation">
        <p>
          {department} {!manager ? ` • ${profile}` : ''}
        </p>
      </div>
      <div className="profile-info">
        <div className="profiles">
          <div className="managed">
            {avatar && (
              <div className="avatar">
                <img src={avatar} alt="Avatar" />
              </div>
            )}
            <div className={`text ${manager ? 'manager-displayed' : ''}`}>
              <p>{t('Feedback de')}</p>
              <h3>
                {name}
                {participantWhoEvaluated && (
                  <span> {t(`(por ${participantWhoEvaluated})`)}</span>
                )}
              </h3>
              {manager && <p>{profile}</p>}
            </div>
          </div>

          {manager && (
            <div className="manager">
              {manager.avatar_128x0 && (
                <div className="avatar">
                  <img src={manager.avatar_128x0} alt="Avatar" />
                </div>
              )}
              <div className="text">
                <p>{t('Realizado por')}</p>
                <h3>{manager.name}</h3>
                {manager && <p>{manager.profile}</p>}
              </div>
            </div>
          )}
        </div>

        <div className="custom-content">{children}</div>
      </div>
    </StyledCollaboratorCard>
  );
}

export default CollaboratorCard;
