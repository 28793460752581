import React from 'react';

import LimitedTextarea from '~/components/limited-textarea';

import { StyledFeedbackQuestion } from './styles';
import { useTranslation } from 'react-i18next';

function PerformanceFeedbackQuestion({
  currentQuestion,
  questionNumber,
  questions,
  setQuestions,
  disabled
}) {
  const { t } = useTranslation();

  const handleFieldChange = ({ target }) => {
    setQuestions(() => {
      const currentQuestions = [...questions];
      currentQuestions[questionNumber - 1].answer = target.value;
      return currentQuestions;
    });
  };

  return (
    <StyledFeedbackQuestion>
      <div className="field-label">
        <div className="question-number">{questionNumber}</div>
        <p className="question">{currentQuestion.title}</p>
      </div>
      <LimitedTextarea
        limit={2000}
        value={currentQuestion.answer || ''}
        onChange={handleFieldChange}
        placeholder={t('Descreva sua justificativa')}
        disabled={disabled}
      />
    </StyledFeedbackQuestion>
  );
}

export default PerformanceFeedbackQuestion;
