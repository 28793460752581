import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  PublicWrapper,
  PublicHeader,
  PublicBanner,
  PublicFooter
} from '~/components/public';
import Card from '~/components/card';
import { Form, Input, Check } from '~/components/form';
// import FacebookAuth from '~/components/facebook-auth';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Link from '~/components/link';
import Separator from '~/components/separator';
import StoreLinks from '~/components/store-links';
import { showLoading, closeLoading } from '~/store/ducks/loading';
import { showMessage, closeMessage } from '~/store/ducks/messageBar';
import { registerAccount } from '~/store/ducks/account';
import { signUp } from '~/api/auth';
// import { updateProfile } from '~/api/account';
import { login } from '~/services/auth';
import { getError, enableCompleteSignUp } from '~/utils';
import { withSite } from '~/hooks/use-site';

class SignUpScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.site.name !== 'USEFLOW' ? '' : 'Usuário do USEFLOW',
      email: '',
      email_invalid: false,
      password1: '',
      password1_invalid: false,
      password2: '',
      password2_invalid: false,
      useterms: false,
      cookiepolicy: false,
      formDisabled: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target }) {
    const { name, value } = target;

    if (name === 'useterms' || name === 'cookiepolicy') {
      const { checked } = target;

      this.setState({
        [name]: checked
      });

      return;
    }

    this.setState({
      [name]: value,
      [`${name}_invalid`]: false
    });
  }

  handleSubmit() {
    const {
      name,
      email,
      password1,
      password2,
      useterms,
      cookiepolicy
    } = this.state;
    const { showLoading, closeLoading, showMessage, closeMessage } = this.props;

    if (!useterms || !cookiepolicy) {
      showMessage(
        'É necessário estar de acordo com as Políticas de Privacidade, Termos de Uso e Política de Cookies para continuar',
        'danger'
      );
      return;
    }

    if (password1 === password2) {
      showLoading();
      this.setState({ formDisabled: true });

      signUp(name, email, password1)
        .then(res => {
          closeMessage();
          this.props.registerAccount(res.data.account);
          login(res.data.token);
          if (this.props.site.completeSignUp) {
            enableCompleteSignUp();
            this.props.history.push('/complete-sign-up');
          } else {
            this.props.history.push('/');
          }
        })
        .catch(e => {
          getError(e, res => {
            showMessage(res.message, 'danger');
            const newState = { formDisabled: false };

            res.fields.forEach(field => {
              newState[field] = true;
            });

            this.setState(newState);
          });
        })
        .finally(() => {
          this.setState({ formDisabled: false });
          closeLoading();
        });
    } else {
      showMessage('As senhas precisam ser iguais!', 'danger');
      this.setState({
        password1_invalid: true,
        password2_invalid: true
      });
    }
  }

  render() {
    return (
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card">
            {/* <FacebookAuth>Cadastrar com Facebook</FacebookAuth> */}
            <Separator>Acesse sua conta</Separator>
            <Form
              disabled={this.state.formDisabled}
              onSubmit={this.handleSubmit}
            >
              {this.props.site.name !== 'USEFLOW' && (
                <Input
                  name="name"
                  type="text"
                  placeholder="Digite seu nome"
                  autoCorrect="off"
                  autoFocus
                  required
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              )}
              <Input
                name="email"
                type="text"
                placeholder="Digite sua matrícula. Exemplo: F97153823"
                autoCorrect="off"
                required
                invalid={this.state.email_invalid}
                value={this.state.email}
                onChange={this.handleChange}
              />
              <Input
                name="password1"
                type="password"
                placeholder="Digite uma senha"
                autoComplete="off"
                required
                invalid={this.state.password1_invalid}
                value={this.state.password1}
                onChange={this.handleChange}
              />
              <Input
                name="password2"
                type="password"
                placeholder="Repita a senha"
                autoComplete="off"
                required
                invalid={this.state.password2_invalid}
                value={this.state.password2}
                onChange={this.handleChange}
              />
              <Check
                type="checkbox"
                name="useterms"
                checked={this.state.useterms}
                onChange={this.handleChange}
              >
                Li e concordo com a{' '}
                <a href="https://d1asjutgsmztwh.cloudfront.net/flow/use-terms">
                  Política de Privacidade
                </a>{' '}
                e os{' '}
                <a href="https://d1asjutgsmztwh.cloudfront.net/flow/use-terms">
                  Termos de Uso
                </a>
                .
              </Check>
              <Check
                type="checkbox"
                name="cookiepolicy"
                checked={this.state.cookiepolicy}
                onChange={this.handleChange}
              >
                Li e concordo com a{' '}
                <a href="https://d1asjutgsmztwh.cloudfront.net/flow/cookie-policy">
                  Política de Cookies
                </a>
                .
              </Check>
              <Button fill large color="primary">
                <Icon name="check" marginRight />
                <span>Cadastrar</span>
              </Button>
              <Separator />
              <div className="text-center">
                <span>Já é cadastrado? </span>
                <Link to="/sign-in">
                  <span>Faça login</span>
                  <Icon name="arrow-right" marginLeftSmall />
                </Link>
              </div>
            </Form>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner>
            {this.props.site.name !== 'USEFLOW' ? (
              <StoreLinks>
                <span>Baixe nosso app para</span>
                <br />
                <span>interagir com suas comunidades.</span>
                <br />
                <span>Disponível para iOS e Android.</span>
              </StoreLinks>
            ) : (
              <span>Seja bem-vindo!</span>
            )}
          </PublicBanner>

          <div className="hide-on-mobile" style={{ marginBottom: 225 }} />
        </div>
        <PublicFooter />
      </PublicWrapper>
    );
  }
}

export default connect(null, {
  showLoading,
  closeLoading,
  showMessage,
  closeMessage,
  registerAccount
})(withRouter(withSite(SignUpScreen)));
