import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AsyncPaginate } from 'react-select-async-paginate';

import useSite from '~/hooks/use-site';

import api from '~/api/api';

import { createPDI } from '~/api/pdi';

import { closeDefaultModal } from '~/store/ducks/default-modal';
import { showMessage } from '~/store/ducks/messageBar';

import Select from '~/components/form/select';
import Input from '~/components/form/input';
import Button from '~/components/button';
import CustomLoading from '~/components/custom-loading';

import { StyledNewPDIModal } from './styles';
import { useTranslation } from 'react-i18next';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: 0,
    borderColor: '#edf1f2'
  }),
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isFocused || state.isSelected ? 'white' : null,
      '&:active': {
        backgroundColor: '#edf1f2',
        color: 'initial'
      }
    };
  }
};

function NewPDIModal({
  pdiSettings,
  participantId,
  getPDIList,
  organization,
  closeDefaultModal,
  message,
  admin
}) {
  const siteSettings = useSite();
  const { t } = useTranslation();

  const { activeOrganizationId } = organization;
  const initialStateFieldErrors = {
    pillar: {
      hasError: false,
      errorMessage: ''
    },
    competence: {
      hasError: false,
      errorMessage: ''
    },
    gap: {
      hasError: false,
      errorMessage: ''
    },
    action: {
      hasError: false,
      errorMessage: ''
    },
    deadline: {
      hasError: false,
      errorMessage: ''
    }
  };

  const [formState, setFormState] = useState({
    pillar: null,
    competence: '',
    gap: '',
    action: '',
    learning_model_title: pdiSettings.learning_model[0].title,
    learning_model_value: pdiSettings.learning_model[0].value,
    deadline: ''
  });
  const [fieldErrors, setFieldErrors] = useState({
    pillar: {
      hasError: false,
      errorMessage: ''
    },
    competence: {
      hasError: false,
      errorMessage: ''
    },
    gap: {
      hasError: false,
      errorMessage: ''
    },
    action: {
      hasError: false,
      errorMessage: ''
    },
    deadline: {
      hasError: false,
      errorMessage: ''
    }
  });
  const [saveLoading, setSaveLoading] = useState(false);

  const handlePillar = option => {
    setFormState(lastFormState => ({
      ...lastFormState,
      pillar: option
    }));
  };

  const handleCompetence = ({ target }) => {
    setFormState(lastFormState => ({
      ...lastFormState,
      competence: target.value
    }));
  };

  const handleGap = ({ target }) => {
    setFormState(lastFormState => ({
      ...lastFormState,
      gap: target.value
    }));
  };

  const handleAction = ({ target }) => {
    setFormState(lastFormState => ({
      ...lastFormState,
      action: target.value
    }));
  };

  const handleLearningModel = ({ target }) => {
    var values = target.value.split('-');
    setFormState(lastFormState => ({
      ...lastFormState,
      learning_model_title: values[0].trim(),
      learning_model_value: values[1].trim()
    }));
  };

  const handleDeadline = ({ target }) => {
    setFormState(lastFormState => ({
      ...lastFormState,
      deadline: target.value
    }));
  };

  const handleSave = () => {
    setSaveLoading(true);
    setFieldErrors(initialStateFieldErrors);

    const newFormState = {
      ...formState,
      pillar: formState.pillar ? formState.pillar.value : null
    };

    createPDI(activeOrganizationId, participantId, newFormState, admin)
      .then(() => {
        getPDIList();
        closeDefaultModal();
        message.showMessage(t('O PDI foi adicionado.'), 'success', 3000);
      })
      .catch(error => {
        if (error.response.status === 400) {
          setFieldErrors(lastFieldErrors => {
            error.response.data.errors.forEach(error => {
              lastFieldErrors[error.field].errorMessage = error.error;
              lastFieldErrors[error.field].hasError = true;
            });
            return lastFieldErrors;
          });

          return;
        }

        message.showMessage(
          t('Ocorreu um erro ao salvar o PDI.'),
          'danger',
          3000
        );
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  async function loadOptions(search, loadedOptions, { page }) {
    const response = await api.get(
      `/organization/${activeOrganizationId}/pillar`,
      {
        params: {
          page,
          search
        }
      }
    );

    const selectPillarOptions = response.data.results.map(pillar => ({
      value: pillar.pk,
      label: pillar.title
    }));

    return {
      options: selectPillarOptions,
      hasMore: response.data.next,
      additional: {
        page: page + 1
      }
    };
  }

  return (
    <StyledNewPDIModal>
      <h3>{t('PDI')}</h3>

      <div className="fields">
        <div className="form-group">
          <label htmlFor="pillar">{t('Pilar *')}</label>
          <AsyncPaginate
            value={formState.pillar}
            loadOptions={loadOptions}
            onChange={handlePillar}
            additional={{
              page: 1
            }}
            placeholder={t('Selecione...')}
            loadingMessage={() => t('Carregando...')}
            noOptionsMessage={() => t('Nenhum pillar encontrado')}
            styles={customStyles}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: siteSettings.primaryColor,
                primary: siteSettings.primaryColor
              }
            })}
          />
          {fieldErrors.pillar.hasError && (
            <p className="error-message">{fieldErrors.pillar.errorMessage}</p>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="competence">{t('Competência')}</label>
          <Input
            type="text"
            id="competence"
            value={formState.competence}
            onChange={handleCompetence}
            disabled={saveLoading}
          />
          {fieldErrors.competence.hasError && (
            <p className="error-message">
              {fieldErrors.competence.errorMessage}
            </p>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="gap">{t('Ponto a desenvolver')}</label>
          <Input
            type="text"
            id="gap"
            value={formState.gap}
            onChange={handleGap}
            disabled={saveLoading}
          />
          {fieldErrors.gap.hasError && (
            <p className="error-message">{fieldErrors.gap.errorMessage}</p>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="action">{t('Ação')}</label>
          <Input
            type="text"
            textarea
            id="action"
            value={formState.action}
            onChange={handleAction}
            disabled={saveLoading}
          />
          {fieldErrors.action.hasError && (
            <p className="error-message">{fieldErrors.action.errorMessage}</p>
          )}
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="learningModels">{t('Tipo de experiência *')}</label>
            <Select
              name="learningModels"
              id="learningModels"
              onChange={handleLearningModel}
              value={formState.learning_model}
              disabled={saveLoading}
            >
              {pdiSettings.learning_model &&
                pdiSettings.learning_model.map(learningModel => (
                  <option key={learningModel.id} value={learningModel.id}>
                    {learningModel.title} - {learningModel.value}
                  </option>
                ))}
            </Select>
          </div>

          <div className="form-group">
            <label htmlFor="deadline">{t('Prazo em semanas *')}</label>
            <Input
              type="number"
              id="deadline"
              value={formState.deadline}
              onChange={handleDeadline}
              min="1"
              disabled={saveLoading}
            />
            {fieldErrors.deadline.hasError && (
              <p className="error-message">
                {fieldErrors.deadline.errorMessage}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="actions">
        <Button
          color="primary"
          className="outline"
          onClick={() => closeDefaultModal()}
          disabled={saveLoading}
        >
          {t('Cancelar')}
        </Button>
        <Button color="primary" onClick={handleSave} disabled={saveLoading}>
          {!saveLoading && t('Salvar')}
          {saveLoading && (
            <CustomLoading
              type="spin"
              height={16}
              width={16}
              fluid
              color="#FFFFFF"
            />
          )}
        </Button>
      </div>
    </StyledNewPDIModal>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewPDIModal));
