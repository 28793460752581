import React from 'react';

import Dropdown from '../Dropdown';
import Card from '~/components/card';

import { useTranslation } from 'react-i18next';

import { StyledPDIParticipantItem } from './styles';

function PDIParticipantItem({
  avatar,
  name,
  email,
  department,
  profile,
  managers,
  admin,
  isActive,
  links
}) {
  const { t } = useTranslation();

  return (
    <StyledPDIParticipantItem isActive={isActive}>
      <Card className="pdi-item">
        <div className="pdi-item-left">
          <div className="avatar">
            <img src={avatar} alt="Avatar" />
          </div>
          <div className="participant-info">
            <h3 className="participant-name">{name}</h3>
            <p className="description email">{email}</p>
            <p className="description">
              {department} • {profile}
            </p>
            {admin && (
              <p>
                {managers.map((manager, index) => (
                  <span>
                    {manager.name} {index < managers.length - 1 ? '- ' : ''}
                  </span>
                ))}
                {managers.length === 0 && t('Sem líder')}
              </p>
            )}
          </div>
        </div>
        <div className="pdi-item-right">
          <Dropdown links={links} />
        </div>
      </Card>
    </StyledPDIParticipantItem>
  );
}

export default PDIParticipantItem;
