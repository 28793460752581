import styled from 'styled-components';
import Button from '~/components/button';

export const StyledButton = styled(Button)`
  width: 100%;
  background-color: #4746d8;
  padding: 20px 0px;
  border-radius: 100px;
  font-size: 16px;
  margin-bottom: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  .custom-loading {
    max-height: 20px;
  }

  &:hover {
    background-color: #363aa3;
  }

  &.white {
    background-color: #ffffff !important;
    color: #363aa3;
    &:hover {
      opacity: 0.9;
    }
  }
`;
