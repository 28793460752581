import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { deletePDI } from '~/api/performance';

import { closeDefaultModal } from '~/store/ducks/default-modal';
import { showMessage } from '~/store/ducks/messageBar';

import Button from '~/components/button';
import CustomLoading from '~/components/custom-loading';

import { StyledPDIConfirmRemoveModal } from './styles';
import { useTranslation } from 'react-i18next';

function PDIConfirmRemoveModal({
  pdiId,
  feedback,
  targetId,
  feedbackId,
  getPDIList,
  currentPage,
  numberOfRows,
  organization,
  closeDefaultModal,
  message
}) {
  const { activeOrganizationId } = organization;

  const { t } = useTranslation();

  const [removeLoading, setRemoveLoading] = useState(false);

  const handleRemove = () => {
    setRemoveLoading(true);

    deletePDI(activeOrganizationId, targetId, feedbackId, pdiId)
      .then(() => {
        getPDIList(
          currentPage > 1 && numberOfRows === 1 ? currentPage - 1 : currentPage,
          feedback
        );
        closeDefaultModal();
      })
      .catch(() => {
        message.showMessage(
          t('Ocorreu um erro ao excluir o PDI.'),
          'danger',
          3000
        );
      })
      .finally(() => {
        setRemoveLoading(false);
      });
  };

  return (
    <StyledPDIConfirmRemoveModal>
      <h3>{t('Excluir PDI?')}</h3>
      <p>{t('Tem certeza que deseja excluir este item?')}</p>

      <div className="actions">
        <Button
          color="primary"
          onClick={() => closeDefaultModal()}
          disabled={removeLoading}
        >
          {t('Cancelar')}
        </Button>
        <Button
          color="primary"
          className="outline"
          onClick={handleRemove}
          disabled={removeLoading}
        >
          {!removeLoading && t('Excluir')}
          {removeLoading && (
            <CustomLoading
              type="spin"
              height={16}
              width={16}
              fluid
              color="#FFFFFF"
            />
          )}
        </Button>
      </div>
    </StyledPDIConfirmRemoveModal>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PDIConfirmRemoveModal));
