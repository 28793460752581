import React from 'react';
import { MultiSelect } from 'react-multi-select-component';

import { StyledSelectMultiple } from './styles';
import { useTranslation } from 'react-i18next';

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]

// in order to set a "dynamic" allItemsSelectedMsg, I created a prop for that string
// it is being used at account-screen.js
function SelectMultiple({
  options,
  selected,
  setSelected,
  placeholder,
  disabled = false,
  hasSelectAll = true,
  clearSelectedIcon,
  allItemsSelectedMsg
}) {
  const { t } = useTranslation();

  return (
    // passing disabled prop to styled component, in order to set different opacity when disabled
    <StyledSelectMultiple disabled={disabled}>
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy={placeholder || 'Selecione...'}
        overrideStrings={{
          allItemsAreSelected: allItemsSelectedMsg
            ? allItemsSelectedMsg
            : t('Todos os itens selecionados'),
          clearSearch: t('Limpar busca'),
          clearSelected: t('Limpar selecionado'),
          noOptions: t('Não encontrado'),
          search: t('Buscar'),
          selectAll: t('Selecionar tudo'),
          selectAllFiltered: t('Selecionar tudo (filtrado)'),
          selectSomeItems: placeholder || t('Selecione...'),
          create: t('Criar')
        }}
        disabled={disabled}
        hasSelectAll={hasSelectAll}
        ClearSelectedIcon={clearSelectedIcon}
      />
    </StyledSelectMultiple>
  );
}

export default SelectMultiple;
