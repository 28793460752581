import styled from 'styled-components';

const StyledCollaboratorCard = styled.div.attrs(() => ({
  className: 'collaborator-card'
}))`
  width: 100%;
  background: var(--secondary-color);
  border-radius: 4px;

  & > div {
    width: 100%;
    color: var(--white-color);
    padding: 18px 32px;
  }

  .segmentation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--white-color);

    .segmentation-buttons {
      display: flex;
      flex-direction: row;
      column-gap: 5px;

      button.primary {
        color: var(--white-color);

        &:hover {
          color: var(--white-color);
        }
      }
    }
  }

  .profile-info {
    .profiles {
      display: flex;

      & > div {
        display: flex;
        padding-right: 16px;
      }

      .manager {
        padding-left: 16px;
        border-left: 1px solid var(--white-color);
      }
    }

    .text {
      width: max-content;

      h3,
      p {
        max-width: 200px;
      }

      &.manager-displayed {
        h3 {
          max-width: 250px;
        }
      }

      span {
        font-weight: var(--normal);
      }
    }

    .avatar {
      width: 52px;
      height: 52px;
      min-width: 52px;
      min-height: 52px;
      margin-right: 28px;

      img {
        max-width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .custom-content {
    width: 100%;
    margin-left: 16px;
  }

  @media (max-width: 720px) {
    .profile-info {
      flex-direction: column;
      align-items: flex-start;

      .profiles {
        flex-direction: column;
        width: 100%;

        & > div {
          padding: 16px 0px;
        }

        .manager {
          padding-left: 0px;
          border-left: none;
          border-top: 1px solid var(--white-color);
        }
      }

      .custom-content {
        margin: 0px;
      }
    }

    .segmentation {
      .segmentation-buttons {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        button.primary {
          color: var(--white-color);

          &:hover {
            color: var(--white-color);
          }
        }
      }
    }
  }
`;

export default StyledCollaboratorCard;
