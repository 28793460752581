import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../tooltip';
import Icon from '~/components/icon';
import Button from '~/components/button';
import LimitedTextarea from '~/components/limited-textarea';

import StyledOpenAnswer from './styles';

function OpenAnswer({
  title,
  descriptiveAnswer,
  handleDescriptiveAnswer,
  canViewAnswers,
  descriptiveAnswerByManaged,
  disabled
}) {
  const { t } = useTranslation();

  const [showAnswerByManaged, setShowAnswerByManaged] = useState(false);

  return (
    <StyledOpenAnswer>
      <div className="descriptive-answer">
        <div className="open-answer-header">
          <h5>{title}</h5>
          {canViewAnswers && descriptiveAnswerByManaged && (
            <div className="actions">
              {!showAnswerByManaged ? (
                <Button
                  color="primary"
                  onClick={() => setShowAnswerByManaged(!showAnswerByManaged)}
                  disabled={!descriptiveAnswerByManaged}
                >
                  <Icon name="eye" />
                  {t('Ver respostas')}
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => setShowAnswerByManaged(!showAnswerByManaged)}
                >
                  <Icon name="not-eye" />
                  {t('Ocultar respostas')}
                </Button>
              )}

              <Tooltip
                content={
                  disabled
                    ? t('As respostas são do liderado que foi avaliado.')
                    : t('As respostas são do liderado que está sendo avaliado.')
                }
              >
                <Icon name="helper" />
              </Tooltip>
            </div>
          )}
        </div>
        {title && showAnswerByManaged && (
          <div className="descriptive-answer-by-managed">
            <h5>{t('Auto avaliação')}</h5>
            <p>{descriptiveAnswerByManaged}</p>
          </div>
        )}
        <div className="open-answer-input">
          <LimitedTextarea
            limit={1000}
            rows={1}
            value={descriptiveAnswer || ''}
            onChange={handleDescriptiveAnswer}
            placeholder={t('Insira sua resposta')}
            disabled={disabled}
          />
        </div>
      </div>
    </StyledOpenAnswer>
  );
}

export default OpenAnswer;
