import React, { useState, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { withRouter } from 'react-router-dom';
import { getToken, upadateTokenKey } from '~/services/auth';
import { tokenRefresh } from '~/api/auth';

import { setOrganization } from '~/store/ducks/organization';

import Icon from '~/components/icon';

import {
  storeOrganizationId,
  clearOrganizationName,
  findActiveOrganization,
  getRefreshOrganization,
  removeRefreshOrganization
} from '~/utils';

import './styles.scss';

function ToggleOrganization({ organizations, history, onClose }) {
  const dispatch = useDispatch();
  const userToken = getToken();

  let activeOrganization = findActiveOrganization(
    organizations,
    organizations[0].id
  );

  const [activeOrganizationName, setActiveOrganizationName] = useState(
    clearOrganizationName(activeOrganization.name)
  );
  const [activeOrganizationAvatar, setActiveOrganizationAvatar] = useState(
    activeOrganization.avatar_128x0
  );
  const [activeOrganizationId, setActiveOrganizationId] = useState(
    activeOrganization.id
  );
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const toggleOptionsDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const setOrganizationId = organizationId => {
    dispatch(setOrganization(organizationId));
    storeOrganizationId(organizationId);
  };

  const selectOrganization = ({ id, name, avatar_128x0: avatar }) => {
    tokenRefresh({
      organization: id,
      token: userToken
    }).then(response => {
      upadateTokenKey(response.data.token);

      setActiveOrganizationName(clearOrganizationName(name));
      setActiveOrganizationAvatar(avatar);
      setActiveOrganizationId(id);

      setOrganizationId(id);

      toggleOptionsDropdown();

      window.location.href = '/';

      if (onClose) {
        onClose();
      }
    });
  };

  useEffect(() => {
    if (getRefreshOrganization()) {
      removeRefreshOrganization();
      tokenRefresh({
        organization: activeOrganization.id,
        token: userToken
      }).then(response => {
        upadateTokenKey(response.data.token);
        setOrganizationId(activeOrganization.id);
        history.replace({ search: history.location.search });
      });
    }
    setOrganizationId(activeOrganization.id);
  }, []);

  return (
    <header className="nav-header toggle-org">
      <button
        type="button"
        className="active-org no-button"
        onClick={toggleOptionsDropdown}
      >
        <span>
          <img src={activeOrganizationAvatar} alt="Avatar" />
          {activeOrganizationName}
        </span>
        {organizations.length > 1 && (
          <Icon name={dropdownIsOpen ? 'up-arrow' : 'down-arrow'} />
        )}
      </button>
      <div className={`org-options ${dropdownIsOpen ? 'opened' : ''}`}>
        {organizations
          .filter(
            organization =>
              organization.id !== 24 && organization.id !== activeOrganizationId
          )
          .sort((a, b) => {
            if (a.joined_at < b.joined_at) {
              return -1;
            }

            if (a.joined_at > b.joined_at) {
              return 1;
            }

            return 0;
          })
          .map(organization => (
            <button
              type="button"
              key={organization.id}
              onClick={() => selectOrganization(organization)}
              className="no-button"
            >
              <span>
                <img src={organization.avatar_128x0} alt="Avatar" />
                {clearOrganizationName(organization.name)}
              </span>
            </button>
          ))}
      </div>
    </header>
  );
}

export default withRouter(ToggleOrganization);
