import styled from 'styled-components';

export const StyledSSOLogin = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;
