import React from 'react';

import CustomString from './custom-string';
import CustomChoices from './custom-choices';
import CustomBoolean from './custom-boolean';
import CustomDecimal from './custom-decimal';
import CustomMultipleChoice from './custom-multiple-choice';

const customFieldsFactory = (
  id,
  label,
  options,
  answer,
  type,
  readOnly,
  data,
  setData,
  error,
  multiLine,
  maxLenght
) => {
  switch (type) {
    case 'custom_string':
      return (
        <CustomString
          id={id}
          label={label}
          answer={answer}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
          multiLine={multiLine}
          maxLenght={maxLenght}
        />
      );
    case 'custom_choices':
      return (
        <CustomChoices
          id={id}
          label={label}
          answer={answer}
          options={options}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
        />
      );
    case 'custom_multiple_choices':
      return (
        <CustomMultipleChoice
          id={id}
          label={label}
          answer={!!answer ? answer : []}
          options={options}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
        />
      );
    case 'custom_boolean':
      return (
        <CustomBoolean
          id={id}
          label={label}
          answer={answer}
          options={options}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
        />
      );
    case 'custom_decimal':
      return (
        <CustomDecimal
          id={id}
          label={label}
          answer={answer}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
        />
      );
    default:
      return (
        <p style={{ margin: '24px 0px' }}>Ocorreu um erro ao exibir o campo</p>
      );
  }
};

export default customFieldsFactory;
