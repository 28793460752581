import styled from 'styled-components';

export const StyledSwitchButton = styled.div`
  margin-top: -4px;

  .switch {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }

  .switch + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }

  .switch--shadow + label {
    padding: 2px;
    width: 34px;
    height: 20px;
    background-color: #b4b6cb;
    border-radius: 100px;
    transition: all 0.4s;
  }

  .switch--shadow + label:before,
  .switch--shadow + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: '';
  }
  .switch--shadow + label:before {
    right: 1px;
    background-color: var(--grayscale-100);
    border-radius: 100px;
    transition: all 0.4s;
  }
  .switch--shadow + label:after {
    width: 18px;
    background-color: var(--white-color);
    border-radius: 100%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.4s;
  }

  .switch--shadow:checked + label {
    background-color: #4746d8;
  }

  .switch--shadow:checked + label:before {
    background-color: #4746d8;
  }
  .switch--shadow:checked + label:after {
    transform: translateX(14.2px);
  }

  &.disabled {
    .switch + label {
      cursor: initial;
    }

    .switch--shadow + label {
      background-color: var(--gray-color);
    }

    .switch--shadow + label:before {
      background-color: var(--gray-color);
    }

    .switch--shadow:checked + label:before {
      background-color: #4746d8;
      opacity: 40%;
    }
  }
`;
