import React from 'react';

import Button from '~/components/button';
import MarkAsDoneButton from '~/components/mark-as-done-button';

import { StyledNPS } from './styles';

const getNPSOptions = npsData => {
  const NPSOptions = [];

  for (let i = 1; i <= npsData.scale; i += 1) {
    let description = '';

    if (i === 1) {
      description = npsData.min_description;
    }

    if (i === npsData.scale) {
      description = npsData.max_description;
    }

    NPSOptions.push({
      pk: i,
      text: i,
      description
    });
  }

  return NPSOptions;
};

function NPS({
  title,
  markAsDone,
  contentData,
  setContentData,
  isDone,
  isCorrect
}) {
  const NPSOptions = getNPSOptions(contentData);

  const handleSend = () => {
    markAsDone({
      nps: contentData.nps
    });
  };

  const setAnswer = answer => {
    setContentData({
      ...contentData,
      nps: answer
    });
  };

  return (
    <StyledNPS>
      <div className="step-section">
        <div className="title">
          <h3>{title}</h3>
          {isDone && (
            <MarkAsDoneButton
              isDone={isDone}
              onClick={() =>
                markAsDone({
                  ignoreAction: true
                })
              }
            />
          )}
        </div>
      </div>
      <div className="step-section align-left">
        <div className="nps-options">
          {NPSOptions.map(option => (
            <div className="option-wrapper" key={option.pk}>
              <Button
                color="primary"
                className={`
                  outline
                  ${contentData.nps === option.pk ? ' selected' : ''}
                `}
                onClick={() => setAnswer(option.pk)}
                disabled={isDone}
              >
                <p>{option.text}</p>
              </Button>
              <p className="description">{option.description}</p>
            </div>
          ))}
        </div>

        {!isDone && (
          <Button
            color="primary"
            onClick={() => handleSend()}
            disabled={!contentData.nps}
          >
            Enviar
          </Button>
        )}
      </div>
    </StyledNPS>
  );
}

export default NPS;
