import styled from 'styled-components';

export const StyledWriteText = styled.div.attrs(() => ({
  className: 'write-text-step'
}))`
  width: 100%;

  .step-section {
    padding: 32px 42px;
    border-bottom: 1px solid var(--border-color);

    h3,
    h2 {
      font-weight: normal;
    }

    .marker {
      background-color: yellow;
    }

    > p:not([class]) {
      margin-bottom: 20px;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        width: 100%;
        padding-right: 16px;
        font-weight: bold;
      }

      button {
        min-width: max-content;
      }
    }

    .limited-textarea {
      margin-bottom: 32px;
    }
  }

  .align-left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
