import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getToken } from '~/services/auth';

import { StyledDriveModal } from './styles';
import './styles.scss';

function DriveModal({ baseUrl, id, organizationId }) {
  const userToken = getToken();
  const { i18n } = useTranslation();
  const userLanguage = i18n.language;

  const url = `${baseUrl}${
    id ? `/${id}` : ''
  }?orgId=${organizationId}&token=${userToken}&lang=${userLanguage}`;

  return (
    <StyledDriveModal>
      <iframe
        src={url}
        width="100%"
        height="100%"
        title="Drive modal"
        allow="clipboard-write"
      />
    </StyledDriveModal>
  );
}

const mapStateToProps = ({ organization }) => ({
  organization
});

export default connect(mapStateToProps)(DriveModal);
