import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'redux-react-hook';

import { openDefaultModal } from '~/store/ducks/default-modal';
import { registerAccount } from '~/store/ducks/account';
import { showMessage } from '~/store/ducks/messageBar';

import { getConfig } from '~/utils';

import DriveModal from '~/components/drive-modal';

function Drive({ account: { data }, match: { params }, history, showMessage }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const config = getConfig(
      data.organization_is_active_set,
      parseInt(params.organization)
    );

    if (
      !params.id ||
      !params.organization ||
      !config.moduleDrive ||
      !config.moduleDrive.is_active
    ) {
      history.push('/');
      showMessage('Conteúdo indisponível.', 'danger', 10000);
      return;
    }

    dispatch(
      openDefaultModal(
        <DriveModal
          baseUrl={config.moduleDrive.url || 'http://localhost:3000/drive'}
          id={params.id}
          organizationId={params.organization}
        />,
        '100%',
        () => {
          history.push('/');
        },
        false,
        false,
        '12px',
        'drive-custom-modal'
      )
    );
  }, []);

  return '';
}

const mapStateToProps = ({ account, organization, defaultModal }) => ({
  account,
  organization,
  defaultModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerAccount,
      showMessage
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Drive));
