import React, { useState, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { REACT_APP_SITE } from '~/settings';

import { getCategoryDetail, getCourses } from '~/api/training';

import { openDefaultModal } from '~/store/ducks/default-modal';
import { showMessage } from '~/store/ducks/messageBar';

import CustomLoading from '~/components/custom-loading';
import PageHeader from '~/components/page-header';
import SearchInput from '~/components/search-input';
import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import CourseItem from '~/components/course-item';
import Breadcrumb from '~/components/breadcrumb';
import SectionList from '~/components/section-list';
import CourseCategoryCard from '~/components/course-category-card';
import ViewAllCategoriesModal from '~/components/view-all-categories-modal';
import ViewAllCoursesModal from '~/components/view-all-courses-modal';
import InfiniteScroll from '~/components/infinite-scroll';
import CourseCard from '~/components/course-card';
import EmptyList from '~/components/empty-list';
import CourseCategoryBanner from '~/components/course-category-banner';

import { StyledCourseCategory } from './styles';

function CourseCategory({ organization, location, history }) {
  const { activeOrganizationId } = organization;
  const firstPage = 1;
  const categoryId = location.pathname.replace('/training/category/', '');

  const dispatch = useDispatch();

  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);

  const [categoryTitle, setCategoryTitle] = useState('');
  const [categoryImage, setCategoryImage] = useState(null);
  const [categoryDescription, setCategorySubtitle] = useState('');
  const [categoryShowTitle, setCategoryShowTitle] = useState(true);

  const [pageTitle, setPageTitle] = useState('');

  const [categories, setCategories] = useState([]);
  const [categoriesLabel, setCategoriesLabel] = useState('');

  const [coursesLabel, setCoursesLabel] = useState('');

  const [othersCoursesTitle, setOthersCoursesTitle] = useState('');
  const [othersCourses, setOthersCourses] = useState([]);

  const [categoryCourses, setCategoryCourses] = useState([]);
  const [categoryCoursesLoading, setCategoryCoursesLoading] = useState(false);

  // Search states
  const [searchLoading, setSearchLoading] = useState(true);
  const [coursesFound, setCoursesFound] = useState([]);
  const [search, setSearch] = useState('');
  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [searchLoadingMore, setSearchLoadingMore] = useState(false);
  const [searchNextPage, setSearchNextPage] = useState(null);
  const [searchCurrentPage, setSearchCurrentPage] = useState(firstPage);

  const resetCourseListState = () => {
    setCategoryCourses([]);
    setLoading(true);
    setLoadingMore(false);
    setNextPage(null);
    setCurrentPage(firstPage);
  };

  const handleBack = () => {
    resetCourseListState();
    history.goBack();
  };

  const viewAllCategories = () => {
    dispatch(
      openDefaultModal(
        <ViewAllCategoriesModal
          title={categoriesLabel}
          orgId={activeOrganizationId}
          parent={categoryId}
        />
      )
    );
  };

  const viewAllCourses = () => {
    dispatch(
      openDefaultModal(
        <ViewAllCoursesModal
          title={othersCoursesTitle}
          orgId={activeOrganizationId}
          categoryId={categoryId}
          onlyCanSubscribe={1}
        />
      )
    );
  };

  const getCategoryCourseList = (filters, page) => {
    setCategoryCoursesLoading(true);

    getCourses(activeOrganizationId, filters, page)
      .then(response => {
        setCategoryCourses(() => response.data.results);
        setNextPage(response.data.next);
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setCategoryCoursesLoading(false);
      });
  };

  const loadMoreCategoryCourses = () => {
    setLoadingMore(true);

    const filters = {
      name: search,
      category: categoryId
    };

    getCourses(activeOrganizationId, filters, currentPage + 1)
      .then(response => {
        setCategoryCourses(lastCourseList => [
          ...lastCourseList,
          ...response.data.results
        ]);
        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  const loadMoreSearchedCourses = () => {
    setSearchLoadingMore(true);

    const filters = {
      name: search,
      category: categoryId,
      include_children: true
    };

    getCourses(activeOrganizationId, filters, searchCurrentPage + 1)
      .then(response => {
        setCoursesFound(lastCourseList => [
          ...lastCourseList,
          ...response.data.results
        ]);
        setSearchNextPage(response.data.next);
        setSearchCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setSearchLoadingMore(false);
      });
  };

  const searchCourses = (filters, page) => {
    getCourses(activeOrganizationId, filters, page)
      .then(response => {
        setCoursesFound(() => response.data.results);
        setSearchNextPage(response.data.next);
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setSearchLoading(false);
      });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;
    const filters = {
      name: value,
      category: categoryId,
      include_children: true
    };

    if (filters.name === '') {
      setSearchLoading(true);
      setCoursesFound([]);
      setSearch(value);
      setCurrentTimeOut(null);
      setSearchLoadingMore(false);
      setSearchNextPage(null);
      setSearchCurrentPage(firstPage);
      return;
    }

    const timeOut = setTimeout(() => {
      searchCourses(filters);
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const handleCategoryClick = categoryId => {
    resetCourseListState();
    history.push(`/training/category/${categoryId}`);
  };

  const loadContent = () => {
    setLoading(true);

    getCategoryDetail(activeOrganizationId, categoryId)
      .then(response => {
        const {
          title,
          breadcrumb,
          category,
          categories,
          others_courses,
          course_title
        } = response.data;

        const bread = breadcrumb.map(link => {
          link.id = link.pk;
          link.route = `/training/category/${link.pk}`;
          delete link.pk;
          return link;
        });

        bread[0].id = Date.now();
        bread[0].route = '/training';

        setPageTitle(title);
        setBreadcrumbLinks(bread);
        setCategoryTitle(category.title);
        setCategoryImage(category.cover);
        setCategorySubtitle(category.description);
        setCategoriesLabel(categories.title);
        setCategories(categories.objects);
        setCategoryShowTitle(category.show_title);

        setOthersCoursesTitle(others_courses.title);
        setOthersCourses(others_courses.objects);

        setCoursesLabel(course_title);

        getCategoryCourseList({
          only_subscribed: true,
          category: categoryId
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao carregar a conteúdo.', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadContent();
  }, [categoryId]);

  return (
    <StyledCourseCategory>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              Voltar
            </Button>
          </div>
          <div className="right-side"></div>
        </div>
      </CustomHeader>

      <div className="page-content">
        {(!loading || searchLoading) && (
          <PageHeader>
            <div className="text-info">
              <h1>{pageTitle}</h1>
              <SearchInput
                value={search}
                onChange={handleSearch}
                placeholder="Busque por nome"
              />
            </div>
          </PageHeader>
        )}

        {(!loading || searchLoading) && (
          <Breadcrumb
            links={breadcrumbLinks}
            compressionStart={10}
            onClickCallback={resetCourseListState}
          />
        )}

        {!loading && search === '' && categoryImage && (
          <CourseCategoryBanner
            title={categoryTitle}
            showTitle={categoryShowTitle}
            image={categoryImage}
          />
        )}

        {!loading && search === '' && (
          <PageHeader className="category-header">
            {!categoryImage ? (
              <div className="text-info">
                <h1>{categoryTitle}</h1>
              </div>
            ) : (
              categoryShowTitle && (
                <div className="text-info">
                  <h1>{categoryTitle}</h1>
                </div>
              )
            )}
            <p>{categoryDescription}</p>
          </PageHeader>
        )}

        {!loading && categories.length > 0 && search === '' && (
          <SectionList
            title={categoriesLabel}
            viewAllLabel="Ver mais"
            viewAllAction={viewAllCategories}
            className="training-section-list"
          >
            {categories.map(category => (
              <CourseCategoryCard
                key={category.pk}
                title={category.title}
                categoryImage={category.cover}
                showTitle={category.show_title}
                onClick={() => handleCategoryClick(category.pk)}
              />
            ))}
          </SectionList>
        )}

        {!loading && search === '' && othersCourses.length > 0 && (
          <SectionList
            title={othersCoursesTitle}
            viewAllLabel="Ver mais"
            viewAllAction={viewAllCourses}
            className="training-section-list"
          >
            {othersCourses.map(course => (
              <CourseCard
                key={course.pk}
                title={course.title}
                showCourseTitle={course.show_course_title}
                cover={course.cover}
                startAt={course.start_at}
                finishAt={course.finish_at}
                onClick={() => {
                  // Usado para verificar a url de origem ao clicar no botão voltar na página de detalhes do curso
                  localStorage.setItem(
                    `@${REACT_APP_SITE}/previousUrl`,
                    history.location.pathname
                  );
                  history.push(`/training/course/${course.pk}`);
                }}
              />
            ))}
          </SectionList>
        )}

        {!loading && categoryCourses.length > 0 && search === '' && (
          <>
            <h3 className="course-list-title">{coursesLabel}</h3>

            <div className="course-list">
              {!loading &&
                categoryCourses.map(course => (
                  <CourseItem
                    key={course.pk}
                    courseId={course.pk}
                    title={course.title}
                    image={course.cover}
                    startAt={course.start_at}
                    finishAt={course.finish_at}
                    stepCount={course.steps_count}
                    stepLabel={
                      course.steps_count !== 1
                        ? course.step_name_plural
                        : course.step_name
                    }
                    trailCount={course.trails_count}
                    trailLabel={
                      course.trails_count !== 1
                        ? course.trail_name_plural
                        : course.trail_name
                    }
                    isScorm={course.is_scorm}
                  />
                ))}
            </div>
          </>
        )}

        {search !== '' && (
          <div className="courses-found">
            {coursesFound.map(course => (
              <CourseItem
                key={course.pk}
                courseId={course.pk}
                title={course.title}
                image={course.cover}
                startAt={course.start_at}
                finishAt={course.finish_at}
                stepCount={course.steps_count}
                stepLabel={
                  course.steps_count !== 1
                    ? course.step_name_plural
                    : course.step_name
                }
                trailCount={course.trails_count}
                trailLabel={
                  course.trails_count !== 1
                    ? course.trail_name_plural
                    : course.trail_name
                }
                isScorm={course.is_scorm}
              />
            ))}
          </div>
        )}

        {search !== '' && !searchLoading && coursesFound.length === 0 && (
          <EmptyList message="Nenhum item encontrado." />
        )}

        {(loadingMore || categoryCoursesLoading || searchLoadingMore) && (
          <CustomLoading type="spin" height={36} width={36} fluid />
        )}

        {(loading || (search !== '' && searchLoading)) && (
          <CustomLoading
            type="spin"
            height={56}
            width={56}
            fluid
            className="screen-loading"
          />
        )}
      </div>

      <InfiniteScroll
        fetchMore={loadMoreCategoryCourses}
        disabled={!nextPage || loading || loadingMore}
      />

      <InfiniteScroll
        fetchMore={loadMoreSearchedCourses}
        disabled={
          !searchNextPage || searchLoading || searchLoadingMore || search === ''
        }
      />
    </StyledCourseCategory>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(withRouter(CourseCategory));
