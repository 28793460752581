import styled from 'styled-components';

export const StyledEditorialLineIndicator = styled.div.attrs(() => ({
  className: 'editorial-line-indicator'
}))`
  margin-top 16px;
  padding: 8px;
  background-color: var(--primary-color);
  color: var(--primary-inverse-color);
  border-radius: 4px;
  font-size: 1.17rem;
`;
