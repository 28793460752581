import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { closeDefaultModal } from '~/store/ducks/default-modal';

import Icon from '~/components/icon';

import StyledTargetItem from './styles';

function TargetItem({
  name,
  participantWhoEvaluated,
  department,
  profile,
  url,
  targetId,
  feedbackId,
  lastAnswerDate,
  awaitingTargetAnswer = false,
  disabled,
  history,
  closeDefaultModal,
  isManager = true
}) {
  const { t } = useTranslation();

  const handleClick = () => {
    closeDefaultModal();
    history.push({
      pathname: url,
      state: {
        targetId,
        feedbackId,
        isAccessedThroughHistory: false
      }
    });
  };

  return (
    <StyledTargetItem onClick={handleClick} disabled={disabled}>
      <div className="info">
        <h3>
          {name}
          {participantWhoEvaluated && (
            <span> {t(`(por ${participantWhoEvaluated})`)}</span>
          )}
        </h3>
        <p className="description">
          {department} • {profile}
        </p>
        {!!lastAnswerDate && !awaitingTargetAnswer && (
          <p className="description">
            {t('Último envio em: {{ date }}', {
              date: lastAnswerDate
            })}
          </p>
        )}
        {awaitingTargetAnswer && isManager && (
          <p className="description">{t('Aguardando resposta do liderado')}</p>
        )}
      </div>
      <div className="actions">
        {awaitingTargetAnswer && isManager ? (
          <Icon name="history" className="ic  on-watch" />
        ) : (
          <Icon name="next" />
        )}
      </div>
    </StyledTargetItem>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  }
});

export default connect(null, mapDispatchToProps)(withRouter(TargetItem));
