import React, { PureComponent, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import memoize from 'memoize-one';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '~/components/icon';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'redux-react-hook';
import { InputFinalForm, SelectInputFinalForm } from '~/components/form';
import {
  maskValidator,
  requiredValueValidator,
  required,
  brDateMaskedValidator,
  composeValidators
} from '~/components/form/validators';
import './style.scss';
import { getCountriesIndicatives, updateProfile } from '~/api/account';
import {
  unregisterAccount,
  updateProfile as dispatchUpdateProfile
} from '~/store/ducks/account';
import { showMessage } from '~/store/ducks/messageBar';
import { removeRequest } from '~/api/account';
import { logoff } from '~/services/auth';
import AccountMenu from './account-menu';
import Loading from '~/components/loading';
import ProfileImage from './components/profile-image';
import {
  getCitiesByCountryAndState,
  getCountries,
  getStatesByCountry
} from '~/api/countriesApi';
import SelectMultiple from '~/components/select-multiple';

const genderValidator = required('Você deve informar seu gênero');

const nascValidator = composeValidators(
  brDateMaskedValidator,
  required('Você deve informar uma data')
);

const AccountScreen = ({
  initialValues,
  onSubmit,
  onCancel,
  loading,
  history
}) => {
  const { t } = useTranslation();

  const genderOptions = [
    ['male', t('Masculino')],
    ['female', t('Feminino')],
    ['other', t('Outro')]
  ];

  // precisei adicionar suporte a exclusão de conta sem ser via popup
  // dai decidi colocar aqui via hook a gerencia do estado por que fica mais fail
  //
  // mantive no outro component (enhanced) porque é um class based, a ideia é
  // migrar pra facilitar a manutenção

  const [showExcludeAccount, setShowExcludeAccount] = useState(false);
  const [loadingExcludeAccount, setLoadingExcludeAccount] = useState(false);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [availableIndicatives, setAvailableIndicatives] = useState([]);
  const [selectedIndicative, setSelectedIndicative] = useState([]);
  const [telephoneMask, setTelephoneMask] = useState([]);

  const onShowExcludeAccountForm = useCallback(
    () => setShowExcludeAccount(true),
    []
  );

  const dispatch = useDispatch();

  // this useEffect is responsible to check if the user has a country, state, city, or ddi already defined in the profile
  // if that is true, we will populate the selects with those values, checking if they exist in the APIs we are using
  // if they do not exist, the selects will not be populated, giving the user the idea that he has to select his country, state, and city again
  useEffect(() => {
    const initialCountry = initialValues.country;
    const initialState = initialValues.addr_state;
    const initialCity = initialValues.addr_city;
    const initialDdi = initialValues.phone_ddi;

    getCountriesIndicatives().then(response => {
      const countriesDDI = response.data;
      if (countriesDDI) {
        setAvailableIndicatives(countriesDDI);
        if (initialDdi) {
          const country = countriesDDI.find(
            country => country.ddi === initialDdi
          );
          if (country) {
            setSelectedIndicative([
              {
                label: `${country.emoji} ${country.ddi}`,
                value: country.code
              }
            ]);
            setTelephoneMask(generateArrayMask(country.mask));
          }
        }
      }
    });

    // get all countries available
    getCountries().then(response => {
      const countries = response.data;
      if (countries) {
        setAvailableCountries(
          countries.map(country => [country.iso2, country.name])
        );

        // if the user has a country set
        if (initialCountry) {
          // check if the country exists in the available countries
          const country = countries.find(
            country => country.name === initialCountry
          );
          if (country) {
            // populate the select
            setSelectedCountry([
              {
                label: country.name,
                value: country.iso2
              }
            ]);
            // if the user has a state set
            if (initialState) {
              getStatesByCountry(country.iso2).then(response => {
                const states = response.data;
                if (states) {
                  const sortedStates = states
                    .map(state => [state.iso2, state.name])
                    .sort((a, b) => a[1].localeCompare(b[1])); // Sort by state name

                  setAvailableStates(sortedStates);
                  const state = states.find(
                    state => state.name === initialState
                  );
                  if (state) {
                    setSelectedState([
                      {
                        label: state.name,
                        value: state.iso2
                      }
                    ]);
                    // if the user has a city set in his profile
                    if (initialCity) {
                      getCitiesByCountryAndState(country.iso2, state.iso2).then(
                        response => {
                          const cities = response.data;
                          if (cities) {
                            setAvailableCities(
                              cities.map(city => [city.id, city.name])
                            );
                            const city = cities.find(
                              city => city.name === initialCity
                            );
                            if (city) {
                              setSelectedCity([
                                {
                                  label: city.name,
                                  value: city.id
                                }
                              ]);
                            }
                          }
                        }
                      );
                    }
                  }
                }
              });
            }
          }
        }
      }
    });
  }, [
    getCountries,
    initialValues.country,
    initialValues.addr_state,
    initialValues.addr_city,
    initialValues.phone_ddi
  ]);

  const fetchStates = country => {
    getStatesByCountry(country).then(response => {
      const states = response.data;
      if (states) {
        const sortedStates = states
          .map(state => [state.iso2, state.name])
          .sort((a, b) => a[1].localeCompare(b[1])); // Sort by state name (organize)

        setAvailableStates(sortedStates);
        return sortedStates;
      }
    });
  };

  const fetchCities = (country, state) => {
    getCitiesByCountryAndState(country, state).then(response => {
      const cities = response.data;
      if (cities) {
        setAvailableCities(cities.map(city => [city.id, city.name]));
      }
    });
  };

  const handleCountryChange = selected => {
    // clear state/city fields when changing the select to a new country
    setSelectedState([]);
    setSelectedCity([]);
    if (selected.length === 0) {
      setSelectedCountry([]); // no option selected, remove
    } else {
      const country = selected[selected.length - 1]; // setSelected to the last that was selected
      setSelectedCountry([country]); // selectedCountry must be an array, even if there is only one element
      fetchStates(country.value);
    }
    setAvailableCities([]);
  };

  const handleStateChange = selected => {
    // clear city when changing the select to a new state
    setSelectedCity([]);
    if (selected.length === 0) {
      setSelectedState([]); // no option selected, remove
    } else {
      const state = selected[selected.length - 1]; // setSelected to the last that was selected
      setSelectedState([state]); // selectedState must be an array, even if there is only one element
      fetchCities(selectedCountry[0].value, state.value);
    }
    setAvailableCities([]);
  };

  const handleCityChange = selected => {
    if (selected.length === 0) {
      setSelectedCity([]); // no option selected, remove
    } else {
      const city = selected[selected.length - 1];
      setSelectedCity([city]);
    }
  };

  // accepts a string with a mask for a telephone number, example: "+93-##-###-####" converts into "['+','9','3','-',/\d/,/\d/,'-',...]" and returns this array
  const generateArrayMask = stringMask => {
    let arrayMask = [];
    for (let i = 0; i < stringMask.length; i++) {
      if (stringMask[i] === '#') {
        arrayMask.push(/\d/);
      } else {
        arrayMask.push(stringMask[i]);
      }
    }
    return arrayMask;
  };

  const handleIndicativeChange = selected => {
    if (selected.length === 0) {
      setSelectedIndicative([]); // no option selected, remove
      setTelephoneMask([]);
    } else {
      const indicative = selected[selected.length - 1];
      setSelectedIndicative([indicative]);

      // set mask
      const country = availableIndicatives.find(
        country => country['code'] === indicative.value
      );
      if (country) {
        setTelephoneMask(generateArrayMask(country.mask));
      }
    }
  };

  const excludeAccount = useCallback(
    password => {
      if (loadingExcludeAccount) {
        return;
      }

      setLoadingExcludeAccount(true);

      removeRequest(password)
        .then(() => {
          logoff();
          dispatch(unregisterAccount());
          dispatch(showMessage(t('Conta removida com sucesso'), 'danger'));
          history.push('/sign-in');
        })
        .catch(e => {
          setLoadingExcludeAccount(false);

          alert(
            t(
              'Houve um erro ao tentar remover. Verifique se a sua senha está correta.'
            )
          );
        });
    },
    [loadingExcludeAccount]
  );

  // in order to pass the multiselect value to the onSubmit function, I had to do this workaround
  const handleFormSubmit = values => {
    values.country = selectedCountry[0] ? selectedCountry[0].label : '';
    values.addr_state = selectedState[0] ? selectedState[0].label : '';
    values.addr_city = selectedCity[0] ? selectedCity[0].label : '';
    values.phone_ddi =
      selectedIndicative[0] && values.phone
        ? availableIndicatives.find(
            indicative => indicative.code === selectedIndicative[0].value
          ).ddi
        : '';

    onSubmit(values);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, form: { change } }) => (
        <form onSubmit={handleSubmit}>
          <Loading visible={loading} />

          <div className="account-screen">
            <AccountMenu />
            <div className="section-wrapper">
              <h2>{t('Meus Dados')}</h2>
              <p>
                {t(
                  'Mantenha os dados cadastrais e pessoais da sua conta sempre atualizados.'
                )}{' '}
              </p>
            </div>

            <div className="form-section section-wrapper">
              <div>
                <h3 className="form-section-title">{t('Dados Cadastrais')}</h3>
              </div>

              <div>
                <div className="input-group mb10">
                  <div>
                    <label>{t('Imagem do perfil')}</label>

                    <Field name="image" component={ProfileImage} />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label>{t('Nome completo')}</label>
                    <Field
                      name="name"
                      component={InputFinalForm}
                      validate={requiredValueValidator}
                      maxLength={120}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label>{t('Email')}</label>
                    <Field
                      name="email"
                      type="email"
                      component={InputFinalForm}
                      validate={requiredValueValidator}
                      maxLength={200}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-section section-wrapper">
              <div>
                <h3 className="form-section-title">{t('Dados Pessoais')}</h3>
              </div>

              <div>
                <div className="input-group">
                  <div>
                    <label>{t('Data de Nascimento')}</label>
                    <Field
                      name="born_at"
                      parse={null}
                      mask={[
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/
                      ]}
                      component={InputFinalForm}
                      validate={nascValidator}
                    />
                  </div>

                  <div>
                    <label>{t('Gênero')}</label>
                    <Field
                      name="gender"
                      component={SelectInputFinalForm}
                      options={genderOptions}
                      validate={genderValidator}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label>{t('DDI')}</label>
                    <SelectMultiple
                      name="indicative"
                      options={availableIndicatives.map(country => ({
                        label: `${country.emoji} ${country.ddi}`,
                        value: country.code
                      }))}
                      selected={selectedIndicative}
                      setSelected={selected => {
                        change('phone', '');
                        handleIndicativeChange(selected);
                      }}
                      placeholder={t('Selecione o DDI')}
                      hasSelectAll={false}
                    />
                  </div>
                  <div>
                    <label>{t('Telefone')}</label>
                    <Field
                      name="phone"
                      parse={null}
                      mask={telephoneMask}
                      component={InputFinalForm}
                      validate={maskValidator}
                      disabled={telephoneMask.length === 0}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label>{t('Endereço Linha 1')}</label>
                    <Field
                      name="addr_street"
                      parse={null}
                      component={InputFinalForm}
                      maxLength={255}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label>{t('Endereço Linha 2')}</label>
                    <Field
                      name="addr_complement"
                      parse={null}
                      component={InputFinalForm}
                      maxLength={255}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label>{t('País')}</label>
                    <SelectMultiple
                      name="country"
                      options={availableCountries.map(country => ({
                        label: country[1],
                        value: country[0]
                      }))}
                      selected={selectedCountry}
                      setSelected={handleCountryChange}
                      placeholder={t('Selecione o país')}
                      hasSelectAll={false}
                      clearSelectedIcon={<></>}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label>{t('Estado/Província/Região')}</label>
                    <SelectMultiple
                      name="addr_state"
                      options={availableStates.map(state => ({
                        label: state[1],
                        value: state[0]
                      }))}
                      selected={selectedState}
                      setSelected={handleStateChange}
                      placeholder={t('Selecione o estado/província/região')}
                      disabled={availableStates.length === 0}
                      hasSelectAll={false}
                      clearSelectedIcon={<></>}
                      allItemsSelectedMsg={
                        selectedState[0] ? selectedState[0].label : null
                      }
                    />
                  </div>

                  <div>
                    <label>{t('Cidade')}</label>
                    <SelectMultiple
                      name="addr_city"
                      options={availableCities.map(city => ({
                        label: city[1],
                        value: city[0]
                      }))}
                      selected={selectedCity}
                      setSelected={handleCityChange}
                      placeholder={t('Selecione a cidade')}
                      disabled={availableCities.length === 0}
                      hasSelectAll={false}
                      clearSelectedIcon={<></>}
                      allItemsSelectedMsg={
                        selectedCity[0] ? selectedCity[0].label : null
                      }
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label>{t('Cep')}</label>
                    <Field
                      name="addr_cep"
                      parse={null}
                      component={InputFinalForm}
                      validate={maskValidator}
                      maxLength={20}
                    />
                  </div>
                </div>
              </div>
            </div>

            {!showExcludeAccount && (
              <div className="section-wrapper delete-account">
                <button
                  className="button danger link"
                  type="button"
                  onClick={onShowExcludeAccountForm}
                >
                  {t('Excluir minha conta')}
                </button>
              </div>
            )}

            {showExcludeAccount && (
              <Form
                onSubmit={({ password }) => excludeAccount(password)}
                render={({ handleSubmit }) => (
                  <div class="section-wrapper">
                    <div className="input-group delete-account-form">
                      <div>
                        <label>{t('Digite sua senha')}</label>
                        <Field
                          type="password"
                          name="password"
                          placeholder={t(
                            'Para cancelar sua conta, é necessário informar sua senha'
                          )}
                          component={InputFinalForm}
                          validate={requiredValueValidator}
                        />
                      </div>
                      <div>
                        <button
                          className="button danger sub-form-button"
                          type="button"
                          onClick={handleSubmit}
                          disabled={loadingExcludeAccount}
                        >
                          {t('Confirmar exclusão')}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              />
            )}

            <div className="form-section section-wrapper">
              <div className="mobile-buttons-full actions">
                <button
                  onClick={onCancel}
                  type="button"
                  className="outline button secondary"
                >
                  <Icon name="cancel-circle" />
                  {t('Cancelar alterações')}
                </button>

                <button className="button secondary ml10">
                  <Icon name="floppy-disk" />
                  {t('Salvar')}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

class AccountScreenEnhanced extends PureComponent {
  state = {
    loading: false
  };

  initialValues = memoize(
    ({
      name,
      email,
      born_at,
      gender,
      phone,
      phone_ddi,
      addr_cep,
      addr_state,
      addr_city,
      addr_street,
      addr_number,
      addr_complement,
      addr_district,
      country,
      cpf,
      avatar_128x0
    }) => {
      const values = {
        name,
        email,
        born_at,
        gender,
        phone,
        phone_ddi,
        addr_cep,
        addr_state,
        addr_city,
        addr_street,
        addr_number,
        addr_complement,
        addr_district,
        country,
        cpf,
        image: {
          default: avatar_128x0
        }
      };

      if (born_at) {
        values.born_at = moment(born_at, 'YYYY-MM-DD').format('DD/MM/YYYY');
      }

      Object.keys(values).forEach(k => {
        if (values[k] === null) {
          values[k] = '';
        }
      });

      return values;
    }
  );

  onSubmit = values => {
    const { dispatchUpdateProfile, showMessage } = this.props;

    const profileValues = { ...values };

    if (profileValues.image.file) {
      profileValues.avatar = profileValues.image.file;
    }

    delete profileValues.image;

    if (profileValues.born_at) {
      profileValues.born_at = moment(
        profileValues.born_at,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');
    }

    this.setState({ loading: true }, () => {
      updateProfile(profileValues)
        .then(({ data }) => {
          dispatchUpdateProfile(data);
          showMessage('Atualizado com sucesso!', 'secondary');
          window.location.reload();
        })
        .catch(res => {
          showMessage('Error ao atualizar', 'danger');
        })
        .finally(() => this.setState({ loading: false }));
    });
  };

  onCancel = () => {
    if (window.confirm('Tem certeza que deseja cancelar as alterações?')) {
      this.props.history.push('/');
    }
  };

  render() {
    const {
      account: { loaded, data },
      history
    } = this.props;

    const { loading } = this.state;

    if (!loaded) {
      return <Loading visible />;
    }

    return (
      <AccountScreen
        loading={loading}
        initialValues={this.initialValues(data.profile)}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
        history={history}
      />
    );
  }
}

const mapStateToProps = ({ account }) => ({ account });

export default connect(mapStateToProps, {
  dispatchUpdateProfile,
  showMessage,
  unregisterAccount
})(withRouter(AccountScreenEnhanced));
