import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'redux-react-hook';

import { openDefaultModal } from '~/store/ducks/default-modal';

import Button from '~/components/button';
import Card from '~/components/card';
import Icon from '~/components/icon';
import ManagedModalContent from '~/components/managed-modal-content';
import FeedbackTargetsModal from '~/components/feedback-targets-modal';

import { StyledPerformanceCard } from './styles';
import { useTranslation } from 'react-i18next';

function PerformanceCard({
  performanceId,
  status,
  startAt,
  finishAt,
  isManager,
  title,
  department,
  profile,
  isAnswered,
  feedback,
  orgId,
  history
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleManagedProfiles = () => {
    dispatch(
      openDefaultModal(
        <ManagedModalContent orgId={orgId} performanceId={performanceId} />
      )
    );
  };

  const handleFeedbackTargets = () => {
    dispatch(
      openDefaultModal(
        <FeedbackTargetsModal orgId={orgId} targetId={performanceId} />
      )
    );
  };

  return (
    <StyledPerformanceCard>
      <Card className="performance-item">
        <div className="info-side">
          <div className="date-and-status">
            {(status === 'finished' ||
              (status === 'feedback' && !feedback)) && <Icon name="calendar" />}

            {status !== 'finished' && (status !== 'feedback' || feedback) && (
              <span className="status" />
            )}

            <p
              className={
                status === 'finished' || (status === 'feedback' && !feedback)
                  ? 'finished'
                  : ''
              }
            >
              {t('{{startAt}} até {{finishAt}}', {
                startAt: moment(startAt).format('DD/MM/YYYY'),
                finishAt: moment(finishAt).format('DD/MM/YYYY')
              })}
            </p>
          </div>
          <h3 className="title">{title}</h3>
          {!isManager && (
            <p className="description">
              {department} • {profile}
            </p>
          )}

          {status === 'feedback' &&
            (feedback ? (
              <p className="description">
                {t('Feedback: de {{startAt}} até {{finishAt}}', {
                  startAt: moment(feedback.start_at).format('DD/MM/YYYY'),
                  finishAt: moment(feedback.finish_at).format('DD/MM/YYYY')
                })}
              </p>
            ) : (
              <p className="description">
                {t('Período de feedback encerrado')}
              </p>
            ))}
        </div>
        <div className="action-side">
          {status !== 'finished' &&
            status !== 'feedback' &&
            (isManager ? (
              isAnswered ? (
                <Button
                  color="primary"
                  className="outline"
                  onClick={handleManagedProfiles}
                >
                  {t('Ver respostas')}
                </Button>
              ) : (
                <Button color="primary" onClick={handleManagedProfiles}>
                  {t('Avaliar time')}
                </Button>
              )
            ) : (
              <>
                <Button
                  color="primary"
                  onClick={() =>
                    history.push(`/performance/${performanceId}/competence/1`)
                  }
                  disabled={!!isAnswered}
                >
                  {t('Ir para auto avaliação')}
                </Button>
                {isAnswered && (
                  <Button
                    color="primary"
                    className="outline"
                    onClick={() =>
                      history.push(`/performance/${performanceId}/competence/1`)
                    }
                  >
                    {t('Ver resposta')}
                  </Button>
                )}
              </>
            ))}

          {status === 'feedback' && (
            <Button
              color="primary"
              className={`${isAnswered ? 'outline' : null}`}
              onClick={handleFeedbackTargets}
            >
              {isManager ? t('Feedback do time') : t('Meu feedback')}
            </Button>
          )}
        </div>
      </Card>
    </StyledPerformanceCard>
  );
}

export default withRouter(PerformanceCard);
