import { external } from './instagram';

const BASE_URL = 'https://twitter.com/intent';

const twitter = {
  tweet({ id, wololo_target_message, wololo_target_url, isSubtask, onFinish }) {
    const url = `tweet?text=${wololo_target_message}${
      wololo_target_url ? '&url=' + wololo_target_url : ''
    }`.replace(/[#]/g, '%23');

    return external({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  },

  image({
    id,
    wololo_target_message,
    wololo_target_image,
    isSubtask,
    onFinish
  }) {
    const url = `tweet?text=${wololo_target_message}&url=${wololo_target_image}`.replace(
      /[#]/g,
      '%23'
    );

    return external({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  },

  movie({
    id,
    wololo_target_message,
    wololo_target_movie,
    isSubtask,
    onFinish
  }) {
    const url = `tweet?text=${wololo_target_message}&url=${wololo_target_movie}`.replace(
      /[#]/g,
      '%23'
    );

    return external({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  },

  retweet({ id, object_id, isSubtask, onFinish }) {
    const url = `retweet?tweet_id=${object_id}`;

    return external({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  },

  like({ id, object_id, isSubtask, onFinish }) {
    const url = `like?tweet_id=${object_id}`;

    return external({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  }
};

export default twitter;
