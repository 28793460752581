import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getRumor } from '~/api/rumors';
import Rumor from '~/components/feed/rumor';

export const ViewRumorControl = ({ id, render }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getRumor(id)
      .then(({ data }) => {
        setData(data);
      })
      .catch(e => {
        setError(e);
      });
  }, []);

  return render({
    data,
    error,
    onChange: setData
  });
};

const RumorScreen = ({ match }) => {
  return (
    <ViewRumorControl
      id={match.params.id}
      render={({ data, error }) => {
        if (data) {
          return <Rumor {...data} />;
        } else if (error) {
          return <p>Houve um erro ao carregar o post</p>;
        }

        return null;
      }}
    />
  );
};

export default withRouter(RumorScreen);
