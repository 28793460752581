import React from 'react';

import { StyledSlideBalloon } from './styles';

function SlideBalloon({ children }) {
  return (
    <StyledSlideBalloon className="slide-balloon">
      <div className="text">{children}</div>
      <div className="float-border"></div>
    </StyledSlideBalloon>
  );
}

export default SlideBalloon;
