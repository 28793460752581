import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileInfo from '../profile-info';
import TaskExpire from '../task-expire';
import Points from '../points';
import Done from '~/assets/images/done.svg';
import FacebookIcon from '~/assets/images/task-icons/facebook-task.svg';
import InstagramIcon from '~/assets/images/task-icons/instagram-task.svg';
import LinkedinIcon from '~/assets/images/task-icons/linkedin-task.svg';
import PostIcon from '~/assets/images/task-icons/post.svg';
import ProfileIcon from '~/assets/images/task-icons/profile-task.svg';
import RumorIcon from '~/assets/images/task-icons/rumor-post.svg';
import ResearchIcon from '~/assets/images/task-icons/research.svg';
import xIcon from '~/assets/images/task-icons/x-task.svg';
import WhatsappIcon from '~/assets/images/task-icons/whatsapp-task.svg';
import YoutubeIcon from '~/assets/images/task-icons/youtube-task.svg';
import QuestionIcon from '~/assets/images/task-icons/question-icon.svg';
import KalturaIcon from '~/assets/images/task-icons/kaltura.svg';
import './style.scss';
import useVersion from '~/hooks/use-version';
import useOrganization from '~/hooks/use-organization';
import trailIcon from '~/assets/images/trail-icon.png';
import AnonymousIcon from '~/assets/images/task-icons/anonymous.svg';

export const getIcon = (channel, icon_type) => {
  let icon;
  let style = {};

  switch (channel) {
    case 'publishing': {
      icon = icon_type === 'anonymous' ? AnonymousIcon : PostIcon;
      break;
    }

    case 'facebook': {
      icon = FacebookIcon;
      break;
    }

    case 'twitter': {
      icon = xIcon;
      break;
    }

    case 'whatsapp': {
      icon = WhatsappIcon;
      break;
    }

    case 'linkedin': {
      icon = LinkedinIcon;
      break;
    }

    case 'instagram': {
      icon = InstagramIcon;
      break;
    }

    case 'youtube': {
      icon = YoutubeIcon;
      break;
    }

    case 'question': {
      icon = QuestionIcon;
      break;
    }

    case 'kaltura': {
      icon = KalturaIcon;
      break;
    }

    case 'research': {
      icon = icon_type === 'anonymous' ? AnonymousIcon : ResearchIcon;
      break;
    }

    case 'profile': {
      style = {
        position: 'relative',
        top: '1px'
      };

      icon = ProfileIcon;
      break;
    }

    default: {
    }
  }

  if (!icon) {
    switch (icon_type) {
      case 'post': {
        icon = PostIcon;
        break;
      }

      case 'rumor': {
        icon = RumorIcon;
        break;
      }

      default: {
      }
    }
  }

  if (icon) {
    return <img className="right-icon" src={icon} alt="icon" style={style} />;
  }
};

const PostHeader = props => {
  const organization = useOrganization(props.organization);

  const version = useVersion();

  const meTrail = props.meTrail;

  return (
    <header
      className={`post-header task-channel-${props.channel || 'default'}`}
    >
      {!meTrail ? (
        <ProfileInfo
          name={organization ? organization.name : null}
          image={organization ? organization.avatar_128x0 : null}
          info={props.info}
          rightImage={getIcon(props.channel, props.icon_type)}
        />
      ) : (
        <ProfileInfo
          name={meTrail.title}
          image={trailIcon}
          info=""
          rightImage={null}
          isTrailTask
        />
      )}
      <div className="task-info">
        {props.me_create_wololo && (
          <img src={Done} className="mr10" alt="done" />
        )}
        {props.points ? <Points value={props.points} /> : ''}
        {props.expire && version === 'desktop' ? (
          <TaskExpire time={props.expire} />
        ) : (
          ''
        )}
      </div>
    </header>
  );
};

PostHeader.defaultProps = {
  points: 0,
  expire: ''
};

PostHeader.propTypes = {
  points: PropTypes.number,
  expire: PropTypes.string
};

const mapStateToProps = store => ({
  account: store.account
});

export default connect(mapStateToProps)(PostHeader);
