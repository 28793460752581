import React, { useEffect, useRef } from 'react';
import StyledDynamicHeightIframe from './styles';

function DynamicHeightIframe({ src }) {
  const iframeRef = useRef(null);

  useEffect(() => {
    function adjustIframeHeight(event) {
      const {
        data: { height }
      } = event;
      if (height) {
        iframeRef.current.style.height = `${height}px`;
      }
    }

    window.addEventListener('message', adjustIframeHeight);

    return () => {
      window.removeEventListener('message', adjustIframeHeight);
    };
  }, []);

  return (
    <StyledDynamicHeightIframe>
      <iframe
        ref={iframeRef}
        src={src}
        style={{ width: '100%', border: 'none', overflow: 'hidden' }}
        title="DynamicHeightIframe"
      />
    </StyledDynamicHeightIframe>
  );
}

export default DynamicHeightIframe;
