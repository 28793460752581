import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import './style.scss';

const NewPostComment = ({ children, className, onClick }) => (
  // <div
  //   className={`post-comment ${className} ${onClick && 'cursor-pointer'}`}
  //   onClick={onClick}
  // >
  // </div>

  <div className="post-comment" onClick={onClick}>
    <Icon name="chat-4-line" />
    <span>{children}</span>
  </div>
);

NewPostComment.propTypes = {
  children: PropTypes.node.isRequired
};

export default NewPostComment;
