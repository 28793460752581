import styled from 'styled-components';

export const StyledRecoverySent = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #01102d;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 8px;
  padding-top: 64px;
  gap: 8px;

  color: #ffffff;
  text-align: center;
  margin-bottom: 48px;

  .logo {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 132px;
  }

  img {
    margin-bottom: 16px;
    width: 100%;
    max-width: 300px;
  }

  h2 {
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 30px;
  }

  p {
    font-size: 16px;
    max-width: 320px;
  }

  .separator {
    height: 1px;
    width: 100%;
    max-width: 360px;
    &::after,
    &::before {
      background: #363aa3;
    }
  }

  a {
    text-decoration: solid !important;
    width: 100%;
    max-width: 390px;
  }

  .re-sent-button {
    margin-top: 16px;
  }
`;
