import React from 'react';

import Icon from '~/components/icon';

import { StyledCustomInput } from './styles';

function CustomInput({
  id,
  icon,
  label,
  focusedLabel,
  hideLabel = false,
  placeholder = '',
  placeholderAsLabel = false,
  actions,
  type = 'text',
  value = '',
  onChange,
  hasError = false,
  errorMessage,
  disabled,
  className = '',
  removeBreakLines = false,
  onKeyUp,
  theme = '',
  name
}) {
  const handleChange = event => {
    if (removeBreakLines) {
      event.target.value = event.target.value.replaceAll('\n', '');
    }

    if (onChange) {
      onChange(event);
    }
  };

  const renderLabel = () => {
    const labelText =
      label || focusedLabel || (placeholderAsLabel ? placeholder : '');
    const shouldHideLabel =
      hideLabel || (focusedLabel && !value) || (placeholderAsLabel && !value);

    if (!labelText) return '';

    return (
      <label htmlFor={id}>
        <p className={`input-label ${shouldHideLabel ? 'hide' : ''}`}>
          {labelText}
        </p>
      </label>
    );
  };

  return (
    <StyledCustomInput className={`custom-input ${theme} ${className}`.trim()}>
      {renderLabel()}

      <div
        className={`input-content ${hasError ? 'has-error' : ''} ${
          disabled ? 'disabled' : ''
        }`.trim()}
      >
        {icon && <Icon name={icon} />}
        <input
          type={type}
          id={id || ''}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          onKeyUp={onKeyUp}
        />
        <div className="actions">{actions}</div>
      </div>

      {errorMessage && <p className="input-error-message">{errorMessage}</p>}
    </StyledCustomInput>
  );
}

export default CustomInput;
