import React, { useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { withRouter } from 'react-router-dom';

import { closeDefaultModal } from '~/store/ducks/default-modal';

import Button from '../button';
import SwitchButton from '../switch-button';

import { StyledUseTerms } from './styles';
import DynamicHeightIframe from '../dynamic-height-iframe';

import { useTranslation } from 'react-i18next';

function UseTerms({ location }) {
  const dispatch = useDispatch();

  const [check, setCheck] = useState(false);
  const { t } = useTranslation();

  const handleContinue = () => {
    const currentProfileId = localStorage.getItem('@USEFLOW/profile-id');
    localStorage.setItem('@USEFLOW/use-terms-accepted', '1');
    localStorage.setItem('@USEFLOW/use-terms-accepted-user', currentProfileId);
    dispatch(closeDefaultModal());
    window.location.pathname = location.pathname;
  };

  return (
    <StyledUseTerms>
      <div className="terms-text">
        <DynamicHeightIframe src="https://d1asjutgsmztwh.cloudfront.net/flow/use-terms" />
      </div>
      <div className="switch-card">
        <p>{t('Para continuar você deverá aceitar os contratos de uso')}</p>
        <div className="card">
          <SwitchButton
            checked={check}
            onChange={() => setCheck(!check)}
            id="use-terms-switch"
          />
          <p>{t('Aceito os termos de uso e a política de privacidade.')}</p>
        </div>
      </div>
      <div className="actions">
        <Button color="primary" disabled={!check} onClick={handleContinue}>
          {t('Continuar')}
        </Button>
      </div>
    </StyledUseTerms>
  );
}

export default withRouter(UseTerms);
