import React from 'react';

import { Check } from '~/components/form';
import { StyledCustomMultipleChoice } from './styles';

function CustomMultipleChoice({
  id,
  label,
  options,
  answer,
  setData,
  readOnly,
  errorMessage
}) {
  const handleSelect = value => {
    setData(lastData => {
      const newData = [...lastData];
      const currentAnswers = [...answer];

      const answerIndex = answer.indexOf(value);
      if (answerIndex !== -1) {
        currentAnswers.splice(answerIndex, 1);
      } else {
        currentAnswers.push(value);
      }

      const currentCustomFieldIndex = newData.findIndex(
        customField => customField.pk === id
      );

      newData[currentCustomFieldIndex].answer = currentAnswers;
      return newData;
    });
  };

  return (
    <StyledCustomMultipleChoice>
      <label>{label}</label>
      <div className="custom-field-options">
        {options.map(option => (
          <Check
            key={option.key}
            type="checkbox"
            text={option.label}
            checked={answer.some(currentAnswer => currentAnswer === option.key)}
            onChange={() => handleSelect(option.key)}
            disabled={readOnly}
          />
        ))}
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </StyledCustomMultipleChoice>
  );
}

export default CustomMultipleChoice;
