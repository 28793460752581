import React from 'react';

import { useTranslation } from 'react-i18next';

import Card from '~/components/card';
import Icon from '~/components/icon';
import Button from '~/components/button';

import { StyledFeedbackItem } from './styles';

function FeedbackItem({ title, status, formatedDate, onClick, isManager }) {
  const { t } = useTranslation();

  return (
    <StyledFeedbackItem>
      <Card className="feedback-item">
        <div className="info-side">
          <div className="date-and-status">
            {status === 'finished' && <Icon name="calendar" />}

            {status !== 'finished' && <span className="status" />}

            <p className={status === 'finished' ? 'finished' : ''}>
              {formatedDate}
            </p>
          </div>
          <h3 className="title">{title}</h3>
          {status === 'finished' && (
            <p className="description">{t('Período de feedback encerrado')}</p>
          )}
        </div>
        <div className="action-side">
          <Button color="primary" onClick={onClick}>
            {isManager ? t('Feedback do time') : t('Meu feedback')}
          </Button>
        </div>
      </Card>
    </StyledFeedbackItem>
  );
}

export default FeedbackItem;
