import styled from 'styled-components';

const StyledDynamicHeightIframe = styled.div.attrs(() => ({
  className: 'dhi'
}))`
  iframe {
    min-height: 480px;
  }
`;

export default StyledDynamicHeightIframe;
