import styled from 'styled-components';

export const StyledCustomInput = styled.div`
  width: 100%;
  background: var(--white-color);

  .input-label {
    margin-bottom: 6px;
    font-weight: 500;
    color: var(--secondary-text-color);

    &.hide {
      opacity: 0;
      visibility: 0;
    }
  }

  .input-content {
    border: 1px solid #eaeaf0;
    border-radius: 8px;
    padding: 6px 14px;
    min-height: 48px;

    display: flex;
    align-items: center;

    & > .icon {
      color: var(--secondary-text-color);
      margin-right: 6px;
      font-weight: 500;
      font-size: 18px;
    }

    input {
      flex: 1;
      border: none;
      font-size: 18px;
      outline: none;
      padding-top: 2px;
      padding-left: 0;
      padding-right: 0;
      color: var(--text-color);
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
      font-weight: 500;
      width: 100%;

      ::-webkit-input-placeholder {
        /* Edge */
        color: var(--secondary-text-color);
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--secondary-text-color);
      }

      ::placeholder {
        color: var(--secondary-text-color);
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      &[type='time']::-webkit-calendar-picker-indicator {
        display: none;
      }
    }

    &:focus-within {
      border-color: #4746d8;
    }

    &.has-error {
      border-color: var(--failure-color);
    }
  }

  .input-content.disabled {
    background: var(--background-color);
    border-color: var(--background-color);
  }

  .input-content.disabled:focus-within {
    border-color: var(--background-color);
  }

  .input-error-message {
    color: var(--failure-color);
    margin-top: 4px;
  }
`;
