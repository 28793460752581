import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import RestrictedLayout from '~/layouts/restricted';
import { isAuth } from '~/services/auth';
import useSite from '~/hooks/use-site';
import { setRefreshOrganization, storeOrganizationId } from '~/utils';

const Restricted = ({
  component: Component,
  noSidebar,
  noMobileMenu,
  ...rest
}) => {
  const { loginMinutes } = useSite();
  const { redirectTo } = rest;

  useEffect(() => {
    if (rest.location.search.indexOf('org=') !== -1) {
      const org = rest.location.search.split('org=')[1].split('&')[0];
      storeOrganizationId(org);
      setRefreshOrganization(true);
    }
  }, []);

  if (redirectTo) {
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Redirect
            to={{ pathname: redirectTo, state: { from: matchProps.location } }}
          />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={matchProps =>
        isAuth(loginMinutes) ? (
          <RestrictedLayout noSidebar={noSidebar} noMobileMenu={noMobileMenu}>
            <Component {...matchProps} />
          </RestrictedLayout>
        ) : (
          <Redirect
            to={{ pathname: '/sign-in', state: { from: matchProps.location } }}
          />
        )
      }
    />
  );
};

export default Restricted;
