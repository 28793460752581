import React from 'react';
import store from '~/store';
import ViewPost from '~/components/view-post';
import { openPostModal } from '~/store/ducks/post-modal';
import { getPost } from '~/api/post';
import { addWololo } from '~/services/tasks';
import { postLoaded } from '~/store/ducks/actions';
import { openMessageModal } from '~/store/ducks/message-modal';
// import TaskCompletedModal from '~/components/task-completed-modal';

const publishing = {
  read({ id, wololo_target_id, isSubtask, onFinish }) {
    return new Promise((resolve, reject) => {
      getPost(wololo_target_id)
        .then(({ data }) => {
          store.dispatch(postLoaded(data));
          store.dispatch(
            openPostModal(
              <ViewPost data={data} />,
              id,
              true,
              isSubtask,
              onFinish,
              true
            )
          );
          resolve();
        })
        .catch(reject);
    });
  },

  open_url({ id, wololo_target_url, isSubtask, onFinish, me_create_wololo }) {
    return new Promise(resolve => {
      window.open(wololo_target_url);

      if (!me_create_wololo) {
        if (isSubtask) {
          onFinish(id);
        } else {
          addWololo(id);
        }
      }

      resolve();
    });
  },

  checkin({ id, isSubtask, onFinish }) {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            if (isSubtask) {
              onFinish(id, { lat, lng });
              resolve();
            } else {
              addWololo(id, { lat, lng })
                .then(() => {
                  // store.dispatch(
                  //   openMessageModal(<TaskCompletedModal taskId={id} />)
                  // );
                  resolve();
                })
                .catch(error => {
                  reject(error.data.errors[0]);
                  store.dispatch(
                    openMessageModal(
                      <div className="checkin-error-modal">
                        <h3 className="text-center">{error.data.errors[0]}</h3>
                      </div>
                    )
                  );
                });
            }
          },

          error => {
            let message = '';

            switch (error.code) {
              case error.PERMISSION_DENIED:
                message = 'Geolocalização não autorizada.';
                break;
              case error.POSITION_UNAVAILABLE:
                message = 'Localização indisponível.';
                break;
              case error.TIMEOUT:
                message = 'Tempo expirado.';
                break;
              default:
                message = 'Erro!';
                break;
            }

            store.dispatch(
              openMessageModal(
                <div className="checkin-error-modal">
                  <h3 className="text-center">{message}</h3>
                </div>
              )
            );

            reject(message);
          }
        );
      } else {
        const errorMessage =
          'O serviço de geolocalização não está disponível em seu navegador';

        store.dispatch(
          openMessageModal(
            <div className="checkin-error-modal">
              <h3 className="text-center">{errorMessage}</h3>
            </div>
          )
        );

        reject(errorMessage);
      }
    });
  }
};

export default publishing;
