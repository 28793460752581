import LogoImg from './flow-assets/images/logo.svg';
import LogoImg2x from './flow-assets/images/logo.svg';
import LogoReverseImg from './flow-assets/images/logo-monochrome.svg';
import LogoReverseImg2x from './flow-assets/images/logo-monochrome@2x.svg';
import './main.scss';

const FlowSite = {
  name: 'USEFLOW',
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=tech.useflow.talkative',
  appStoreUrl: 'https://apps.apple.com/br/app/useflow/id1514253004',
  showStoreLinks: false,
  LogoImg,
  LogoImg2x,
  LogoReverseImg,
  LogoReverseImg2x,
  completeSignUp: false,
  tutorialDescription: 'Flow description...',
  actionGenderPoints: 150,
  pushApplicationServerKey:
    'BMplX3pQp1CyS43RjWTg3imHX2DWbgCTYwBF5jkcOOYA9hcwVGXp5rCk_EBRV1-ugvQuLKDibRlFoDArWx5O4C4',
  loginMinutes: null,
  primaryColor: '#4cb392'
};

export default FlowSite;
