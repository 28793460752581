import composeValidators from './composeValidators';
import multiCheckRequired from './multiCheckRequired';
import dateValidator from './dateValidator';
import maskValidator from './maskValidator';
import minDigitValidator from './minDigitValidator';
import sameFieldValueValidator from './sameFieldValueValidator';
import cpfValidator from './cpfValidator';

const brdateValidator = dateValidator('DD/MM/YYYY');
const brDateMaskedValidator = composeValidators(brdateValidator, maskValidator);

const required = errorMessage => value => (!value ? errorMessage : undefined);
const requiredValueValidator = required('Você deve informar um valor');

const maxminLength = errorMessage => value =>
  !value || value.length < 1 || value.length > 1000 ? errorMessage : undefined;
const maxminLengthValueValidator = maxminLength(
  'Certifique-se de que este campo tenha entre 1 e 1000 caracteres.'
);

export {
  composeValidators,
  multiCheckRequired,
  required,
  dateValidator,
  maskValidator,
  brdateValidator,
  brDateMaskedValidator,
  requiredValueValidator,
  maxminLengthValueValidator,
  minDigitValidator,
  sameFieldValueValidator,
  cpfValidator
};
