import styled from 'styled-components';

export const StyledCourseDetails = styled.div.attrs(() => ({
  className: 'course-datails-page'
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 32px;

  .custom-header {
    position: relative;
  }

  .course-info-content {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
    align-items: center;
  }

  .course-info-content h3 {
    color: var(--text-color);
    font-weight: normal;
    font-size: 20px;
  }

  .course-credit-content h2 {
    color: var(--text-color);
    font-weight: normal;
    font-size: 14px;
  }

  .course-credit-content {
    display: flex;
    justify-content: flex-start;
    margin: 0px 0px 10px 0px;
  }

  .header-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
      .button {
        background: var(--white-color);
        padding: 0px 8px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;
    }
  }

  .page-header {
    padding-bottom: 0px;
    padding-top: 0px;

    .text-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search-input {
        max-height: 42px;
        width: 40%;
        margin-left: 16px;
        background: var(--white-color);
      }
    }

    h1 {
      margin: 0px;
    }

    p {
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }

  .page-content {
    width: 100%;
    max-width: 800px;
    margin-top: 42px;

    .subscribe-area {
      width: 100%;
      margin: 0px 0px 16px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text-info {
        h3 {
          font-size: 24px;
          color: var(--text-color);
          font-weight: normal;
        }

        p {
          font-size: 14px;
          padding-right: 16px;
        }
      }

      .actions {
        width: 140px;
        display: flex;
        justify-content: flex-end;

        button {
          min-width: max-content;
        }
      }
    }

    .course-progress-label {
      margin-top: 48px;
      flex: 2;
    }

    .progress-side {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px 0px 16px 0px;

      p {
        margin-top: 0px;
      }

      .progress-bar-wrapper {
        min-width: 300px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          width: 100%;
          margin-right: 8px;
        }

        p {
          min-width: max-content;
        }
      }
    }

    .course-progress-card {
      width: 100%;
      padding: 32px;
      background: var(--primary-color);
      margin-bottom: 16px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        display: flex;
        align-items: center;

        .icon {
          width: 50px;
          min-width: 50px;
          height: 50px;
          border: 1px solid var(--white-color);
          border-radius: 50%;
          font-size: 24px;
          margin-right: 32px;
          color: var(--white-color);
          text-align: center;
          line-height: 50px;
        }

        h3 {
          font-size: 20px;
          line-height: 1;
          color: var(--white-color);
        }

        p {
          color: var(--white-color);
        }
      }
    }

    .course-exam-card,
    .course-certificate-card,
    .course-reaction-survey-card {
      width: 100%;
      padding: 32px;
      background: var(--white-color);
      margin: 16px 0px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        display: flex;
        align-items: center;

        h3 {
          font-size: 20px;
          line-height: 1;
          color: var(--text-color);
          font-weight: normal;
        }
      }

      .action {
        button {
          min-width: max-content;
        }
      }

      .doing-certificate {
        .custom-loading {
          margin-bottom: 8px;
        }

        p {
          color: var(--secondary-text-color);
        }
      }
    }

    .content-title {
      color: var(--text-color);
      font-size: 20px;
      font-weight: normal;
      margin-top: 16px;
    }

    .trail-list {
      margin-top: 16px;
      position: relative;
      width: 100%;
    }
  }

  .screen-loading {
    margin-top: 64px;
  }

  .scorm-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  @media (max-width: 720px) {
    .page-header {
      .text-info {
        flex-direction: column;
        align-items: flex-start;

        .search-input {
          margin-left: 0px;
          width: 100%;
        }
      }
    }

    .page-content {
      padding: 16px;

      .course-progress-card {
        padding: 16px;

        .info {
          i.study {
            margin-right: 16px;
          }
        }

        .action {
          button {
            padding: 4px;
          }
        }
      }

      .course-exam-card,
      .course-certificate-card {
        padding: 16px;
      }
    }
  }
`;
