import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { openMediaModal } from '~/store/ducks/media-modal';
import { showMessage } from '~/store/ducks/messageBar';

import './style.scss';
import { preview } from '~/api/tasks';

const available = {
  facebook: ['share', 'image', 'movie', 'watch'],
  linkedin: ['share', 'message', 'image', 'movie'],
  youtube: ['watch'],
  whatsapp: ['movie', 'image']
};

class LinkPreviewMedia extends Component {
  constructor(props) {
    super(props);
    this.contentId = props.id;
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    preview(this.contentId)
      .then(response => {
        this.setState({ open: true });
        const htmlContent = response.data;

        var el = document.createElement('html');
        el.innerHTML = htmlContent;

        var selectedComponent =
          el.querySelector('iframe') || el.querySelector('img');

        const IframeComponent = () => {
          if (
            this.props.channel === 'facebook' &&
            this.props.type === 'share'
          ) {
            return (
              <iframe
                src={selectedComponent.src}
                allowFullScreen={true}
                width={548}
                height={548}
                title="Preview"
                className="media-modal-iframe"
              />
            );
          } else if (
            this.props.channel === 'facebook' &&
            this.props.type === 'watch'
          ) {
            return (
              <iframe
                src={selectedComponent.src}
                allowFullScreen={true}
                title="Preview"
                width={600}
                height={360}
                className="media-modal-iframe"
              />
            );
          } else if (this.props.type === 'movie') {
            return (
              <iframe
                srcDoc={el.innerHTML}
                title="Preview"
                width={600}
                height={360}
                className="media-modal-iframe"
              />
            );
          } else {
            if (selectedComponent) {
              if (this.props.type === 'image') {
                return (
                  <img
                    className="media-modal-image"
                    src={selectedComponent.src}
                    alt="Preview"
                  />
                );
              }
              return (
                <iframe
                  src={selectedComponent.src}
                  allowFullScreen={true}
                  title="Preview"
                  width={600}
                  height={360}
                  className="media-modal-iframe"
                />
              );
            } else {
              return (
                <iframe
                  srcDoc={el.innerHTML}
                  title="Preview"
                  className="media-modal-iframe secondary"
                />
              );
            }
          }
        };

        this.props.openMediaModal(
          <div className="media-modal-iframe-wrapper">
            <IframeComponent />
          </div>
        );
      })
      .catch(() => {
        this.props.showMessage(
          'Ocorreu um erro ao obter a preview da ação',
          'danger',
          3000
        );
      });
  }

  render() {
    const { channel, type, children } = this.props;
    const channelTypes = available[channel];
    const hasMedia =
      channelTypes && channelTypes.filter(t => t === type).length;

    return hasMedia ? (
      <>
        <a
          href={this.previewUrl}
          onClick={this.handleClick}
          target="_blank"
          rel="noopener noreferrer"
          className="link-preview-media"
          title="Preview"
        >
          {children}
        </a>
      </>
    ) : (
      children
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMediaModal, showMessage }, dispatch);

export default connect(null, mapDispatchToProps)(LinkPreviewMedia);
