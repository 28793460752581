import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '~/components/button';
import Icon from '~/components/icon';
import './style.scss';

const NavButton = ({ children, className, onClick }) => {
  return (
    <Button color="white" className={className} onClick={onClick}>
      {children}
    </Button>
  );
};

const NavOption = props => {
  const exact = props.exact;

  const [showSubmenu, setShowSubmenu] = useState(false);
  const [submenuPosition, setSubmenuPosition] = useState({ top: 0, left: 0 });
  const submenuEntered = useRef(false);

  const handleMouseEnter = e => {
    const rect = e.currentTarget.getBoundingClientRect();
    // topRef: Verifica o tipo de submenu e com base nele ajusta a distância do topo da página
    // 84: representa dois subitems e a metade do terceiro; 52: representa um item
    // const topRef = rect.top;

    let topRef = rect.top;

    if (!['pages', 'lhh'].includes(props.submenuId)) {
      topRef = rect.top - 52;
    }

    setSubmenuPosition({ top: topRef, left: rect.right });
    setShowSubmenu(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (!submenuEntered.current) {
        setShowSubmenu(false);
      }
    }, 300);
  };

  const handleSubmenuMouseEnter = () => {
    submenuEntered.current = true;
    setShowSubmenu(true);
  };

  const handleSubmenuMouseLeave = () => {
    submenuEntered.current = false;
    setShowSubmenu(false);
  };

  const content = (
    <div id={props.submenuId ? props.submenuId : ''} className="content-item">
      {!props.image && <Icon name={props.icon} />}
      {props.image && (
        <img className="nav-img" src={props.image} alt="Avatar" />
      )}
      <span className="item-name">{props.name}</span>
      {props.suboptions && (
        <div
          className="submenu default-scroll"
          style={{
            top: submenuPosition.top,
            left: submenuPosition.left,
            display: showSubmenu ? 'block' : 'none'
          }}
          onMouseEnter={handleSubmenuMouseEnter}
          onMouseLeave={handleSubmenuMouseLeave}
        >
          {props.suboptions.map(suboption => (
            <div key={suboption.name}>
              {suboption.url ? (
                <NavLink
                  to={suboption.url}
                  className={classes}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon name="link" />
                  <span className="item-name">{suboption.name}</span>
                </NavLink>
              ) : (
                <>
                  {suboption.page && suboption.page.content ? (
                    <NavLink
                      to={`/page/${suboption.page.id}`}
                      className={classes}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon name="link" />
                      <span className="item-name">{suboption.name}</span>
                    </NavLink>
                  ) : (
                    <NavButton className={classes} onClick={suboption.onClick}>
                      <Icon name="link" />
                      <span className="item-name">{suboption.name}</span>
                    </NavButton>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      )}
      {['pages', 'lhh'].includes(props.submenuId) ? null : (
        <div
          className="custom-tooltip"
          style={{
            top: submenuPosition.top + 62,
            left: submenuPosition.left + 8
          }}
        >
          {'tooltip' in props ? props.tooltip : props.name}
        </div>
      )}
    </div>
  );

  let classes = ['nav-option'];

  if (props.className) {
    classes.push(props.className);
  }

  classes = classes.join(' ');

  return (
    <li
      key={props.url}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.url ? (
        <NavLink
          to={props.url}
          className={classes}
          activeClassName="active"
          isActive={(match, location) => {
            if (location.pathname === props.url) {
              return true;
            }

            if (
              props.url === '/training' &&
              location.pathname.indexOf('/training') !== -1
            ) {
              return true;
            }

            if (
              props.url === '/development' &&
              (location.pathname.indexOf('/pdi') !== -1 ||
                location.pathname.indexOf('/feedback') !== -1 ||
                location.pathname.indexOf('/performances') !== -1)
            ) {
              return true;
            }

            return false;
          }}
        >
          {content}
        </NavLink>
      ) : props.suboptions ? (
        <div className="nav-option">{content}</div>
      ) : (
        <NavButton className={classes} onClick={props.onClick}>
          {content}
        </NavButton>
      )}
    </li>
  );
};

export default NavOption;
