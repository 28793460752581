import React, { useRef, useState, useEffect } from 'react';
import Button from '../button';
import queryString from 'query-string';
import EditorialLine from '../editorial-line/editorial-line';
import Icon from '../icon';

import { StyledEditorialLineCard } from './styles';
import { withRouter } from 'react-router-dom';

function EditorialLineCard({ location, history, orgEditorialLines }) {
  const linesContainer = useRef(null);

  const currentSearchTerm = queryString.parse(location.search).termo || '';

  const [currentEditorialId, setCurrentEditorialId] = useState(
    queryString.parse(location.search).editorial || ''
  );

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const onFilterEditorialLine = editorialId => {
    const queryParams = {};

    localStorage.setItem(
      '@useflow/editorialScrollPosition',
      linesContainer.current.scrollLeft
    );

    if (currentSearchTerm) {
      queryParams['termo'] = currentSearchTerm;
    }
    if (currentEditorialId.toString() !== editorialId.toString()) {
      queryParams['editorial'] = editorialId;
      setCurrentEditorialId(editorialId);
    } else {
      setCurrentEditorialId('');
    }

    const url =
      Object.keys(queryParams).length !== 0
        ? `/?${new URLSearchParams(queryParams).toString()}`
        : '/';

    history.push(url);
  };

  useEffect(() => {
    if (currentEditorialId) {
      const scrollPosition = localStorage.getItem(
        '@useflow/editorialScrollPosition'
      );
      linesContainer.current.scrollLeft += parseInt(scrollPosition);
    } else {
      localStorage.removeItem('@useflow/editorialScrollPosition');
    }
  }, []);

  return (
    <StyledEditorialLineCard orgEditorialLinesCount={orgEditorialLines.length}>
      <div
        className={`editorial-lines ${
          orgEditorialLines.length > 5 ? 'show-controls' : ''
        }`}
      >
        <Button
          className="button-control left"
          color="primary"
          onClick={() => sideScroll(linesContainer.current, 25, 100, -10)}
        >
          <Icon name="previous" />
        </Button>

        <div ref={linesContainer} className="lines-container default-scroll">
          {orgEditorialLines.map(line => {
            return (
              <EditorialLine
                key={line.id.toString()}
                title={line.title}
                icon={line.data_icon}
                selected={line.id.toString() === currentEditorialId.toString()}
                color={line.color}
                onClickEditorialLine={() => onFilterEditorialLine(line.id)}
              />
            );
          })}
        </div>

        <Button
          className="button-control right"
          color="primary"
          onClick={() => sideScroll(linesContainer.current, 25, 100, 10)}
        >
          <Icon name="next" />
        </Button>
      </div>
    </StyledEditorialLineCard>
  );
}

export default withRouter(EditorialLineCard);
