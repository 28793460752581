import styled from 'styled-components';

const StyledMyProfile = styled.div.attrs(() => ({
  className: 'my-profile-page'
}))`
  .input-group {
    margin-bottom: 16px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export default StyledMyProfile;
