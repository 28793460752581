import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMetlifeProfile } from '~/api/organization';

import { showMessage } from '~/store/ducks/messageBar';

import PageHeader from '~/components/page-header';
import MetlifeCard from '~/components/metlife-card';
import CustomLoading from '~/components/custom-loading';
import EmptyScreen from '~/components/empty-screen';

import beImage from '~/assets/images/be-image.png';

import { StyledMetlife } from './styles';

function Metlife({ organization, showMessage, history }) {
  const { activeOrganizationId } = organization;

  const [metlifeProfileData, setMetlifeProfileData] = useState({
    agent: null,
    eb: null
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMetlifeProfile(activeOrganizationId)
      .then(response => {
        setMetlifeProfileData(response.data);
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao carregar o perfil', 'danger');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <StyledMetlife>
      <PageHeader>
        <div className="text-info">
          <h1>Metlife</h1>
        </div>
      </PageHeader>

      <div className="page-content">
        {metlifeProfileData.eb && (
          <MetlifeCard
            className="eb"
            image={beImage}
            title="Corretores"
            description="Entenda o progresso"
            onClick={() =>
              history.push(`/metlife/${metlifeProfileData.eb.type}/departments`)
            }
          />
        )}

        {metlifeProfileData.agent && (
          <MetlifeCard
            className="agent"
            image={beImage}
            title="Consultores"
            description="Entenda o progresso"
            onClick={() =>
              history.push(
                `/metlife/${metlifeProfileData.agent.type}/departments`
              )
            }
          />
        )}
      </div>

      {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      {!metlifeProfileData.eb && !metlifeProfileData.agent && !loading && (
        <EmptyScreen name="default" />
      )}
    </StyledMetlife>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Metlife)
);
