import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { StoreContext } from 'redux-react-hook';
import { Provider } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import store from './store';
import App from './app';
import { APP_ID } from '~/settings';
import * as serviceWorker from './serviceWorker';
import { FacebookProvider } from 'react-facebook-sdk';
import SiteProvider from '~/contexts/site-provider';

import './i18n';

moment.locale('pt-BR');

ReactDOM.render(
  <Suspense fallback="Carregando...">
    <StoreContext.Provider value={store}>
      <Provider store={store}>
        <SiteProvider>
          <FacebookProvider appId={APP_ID}>
            <App />
          </FacebookProvider>
        </SiteProvider>
      </Provider>
    </StoreContext.Provider>
  </Suspense>,
  document.getElementById('root')
);
serviceWorker.unregister();

// ReactDOM.render(
//   <StoreContext.Provider value={store}>
//     <Provider store={store}>
//       <SiteProvider>
//         <FacebookProvider appId={APP_ID}>
//           <App />
//         </FacebookProvider>
//       </SiteProvider>
//     </Provider>
//   </StoreContext.Provider>,
//   document.getElementById('root')
// );
// serviceWorker.unregister();
