import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'redux-react-hook';
import { getAccount, updateProfile } from '~/api/account';
import Button from '~/components/button';
import Card from '~/components/card';
import { CepField } from '~/components/fields/CepField';
import { InputFinalForm, SelectInputFinalForm } from '~/components/form';
import {
  composeValidators,
  cpfValidator as cpfFormValidator,
  maskValidator,
  required
} from '~/components/form/validators';
import { stateUFOptions } from '~/components/profile-task/tasks/addr_cep';
import {
  PublicBanner,
  PublicFooter,
  PublicHeader,
  PublicWrapper
} from '~/components/public';
// import StoreLinks from '~/components/store-links';
import useAccount from '~/hooks/use-account';
import { registerAccount } from '~/store/ducks/account';
import { closeLoading, showLoading } from '~/store/ducks/loading';
import { showMessage } from '~/store/ducks/messageBar';
import { disableCompleteSignUp, isCompleteSignUpEnabled } from '~/utils';
import './style.scss';

const getErrorsFromException = e => {
  // TODO colocar isso em um helper
  if (_.get(e, 'response.data.code') === 'field_error') {
    return _.get(e, 'response.data.errors').reduce((memo, item) => {
      memo[item.field] = item.error;
      return memo;
    }, {});
  }
};

const cpfValidator = composeValidators(
  maskValidator,
  cpfFormValidator,
  required('Você deve informar um cpf')
);

const instituicaoValidator = composeValidators(
  required('Você deve selecionar uma instituição')
);

const cepValidator = composeValidators(required('Você deve informar seu Cep'));

const stateValidator = composeValidators(
  required('Você deve selecionar um estado')
);

const cityValidator = composeValidators(
  required('Você deve informar sua cidade')
);

const streetValidator = composeValidators(
  required('Você deve informar seu endereço')
);

const areaValidator = composeValidators(
  required('Você deve selecionar uma área')
);

const cargoValidator = composeValidators(
  required('Você deve selecionar o cargo ou função')
);

const neighborhoodValidator = composeValidators(
  required('Você deve informar o bairro')
);

const valuesToSelectOptions = values => values.map(v => [v, v]);

const initialValues = {};

const instituicoesOptions = valuesToSelectOptions(['SESI', 'SENAI']);

const areasOptions = valuesToSelectOptions([
  'Rótulos de Linha',
  'Alimentos e Bebidas',
  'Automação',
  'Automação e Mecatrônica',
  'Automotiva',
  'Celulose e Papel',
  'Construção',
  'Educação',
  'Eletroeletrônica',
  'Gemologia e Joalheria',
  'Gestão',
  'Gráfica e Editorial',
  'Logística',
  'Madeira e Mobiliário',
  'Meio Ambiente',
  'Metalmecânica',
  'Metalmecânica - Mecânica',
  'Metrologia',
  'Mineração',
  'Minerais não Metálicos',
  'Outras',
  'Petróleo e Gás',
  'Polímeros',
  'Química',
  'Refrigeração e Climatização',
  'Segurança no Trabalho',
  'Tecnologia da Informação',
  'Telecomunicações',
  'Têxtil e Vestuário',
  'Transporte'
]);

const cargosSesiOptions = valuesToSelectOptions([
  'Aluno',
  'Administrativo',
  'Coordenador Pedagógico',
  'Consultor técnico',
  'Docente',
  'Gestor',
  'Pesquisador',
  'Supervisor',
  'Técnico'
]);

const cargosSenaiOptions = valuesToSelectOptions([
  'Aluno',
  'Administrativo',
  'Coordenador pedagógico',
  'Consultor técnico',
  'Docente',
  'Gestor',
  'Pesquisador',
  'Supervisor técnico',
  'Técnico'
]);

const unidadesOptions = valuesToSelectOptions([
  'DN',
  'CETIQT',
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]);

const sleep = async interval => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, interval);
  });
};

function CompleteSignUpScreen({ history }) {
  useEffect(() => {
    if (!isCompleteSignUpEnabled()) {
      history.push('/');
    }
  }, []);

  const dispatch = useDispatch();

  const account = useAccount();

  const name = _.get(account, 'data.profile.name');
  const email = _.get(account, 'data.profile.email');

  const onSubmit = useCallback(
    async ({
      cpf,
      unidade,
      area,
      cargo,
      instituicao,
      cep,
      state,
      city,
      street,
      complement,
      number,
      neighborhood
    }) => {
      let errors;

      dispatch(showLoading());

      const values = {
        cpf,
        sesi_senai: instituicao,
        office: cargo,
        erg: unidade,
        addr_cep: cep,
        addr_state: state,
        addr_city: city,
        addr_street: street,
        addr_district: neighborhood,
        name,
        email
      };

      // evitar mandar undefined
      if (area) {
        values.area = area;
      }

      if (complement) {
        values.addr_complement = complement;
      }

      if (number) {
        values.addr_number = number;
      }

      try {
        await updateProfile(values);
        await sleep(5000);
        const { data } = await getAccount();

        dispatch(registerAccount(data));
        dispatch(showMessage('Cadastro criado com sucesso', 'success'));
        disableCompleteSignUp();
        history.push('/');
      } catch (e) {
        errors = getErrorsFromException(e);

        if (!errors) {
          dispatch(
            showMessage(
              'Houve um erro ao completar o cadastro, por favor, tente novamente',
              'danger'
            )
          );
        }
      }

      dispatch(closeLoading());
      return errors;
    },
    [name, email]
  );

  return (
    <PublicWrapper>
      <PublicHeader>
        <Card className="public-card no-input-margin">
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              values: { instituicao, cargo, cep },
              form: { change }
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <div>
                    <Field
                      name="cpf"
                      parse={null}
                      placeholder="CPF"
                      mask={[
                        /\d/,
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/
                      ]}
                      component={InputFinalForm}
                      validate={cpfValidator}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <Field
                      name="instituicao"
                      placeholder="Instituição"
                      component={SelectInputFinalForm}
                      validate={instituicaoValidator}
                      options={instituicoesOptions}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <Field
                      name="cargo"
                      placeholder="Função ou cargo"
                      component={SelectInputFinalForm}
                      validate={cargoValidator}
                      options={
                        instituicao === 'SESI'
                          ? cargosSesiOptions
                          : cargosSenaiOptions
                      }
                    />
                  </div>
                </div>

                {cargo === 'Docente' && instituicao === 'SENAI' && (
                  <div className="input-group">
                    <div>
                      <Field
                        name="area"
                        placeholder="Área"
                        component={SelectInputFinalForm}
                        validate={areaValidator}
                        options={areasOptions}
                      />
                    </div>
                  </div>
                )}

                <div className="input-group">
                  <div>
                    <Field
                      name="unidade"
                      placeholder="Unidade de trabalho"
                      component={SelectInputFinalForm}
                      validate={instituicaoValidator}
                      options={unidadesOptions}
                    />
                  </div>
                </div>

                <hr className="hr" />

                <div className="input-group">
                  <div>
                    <CepField
                      value={cep}
                      name="cep"
                      validate={cepValidator}
                      onResolve={({ street, neighborhood, city, state }) => {
                        change('street', street || '');
                        change('neighborhood', neighborhood || '');
                        change('city', city || '');
                        change('state', state || '');
                      }}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="uf-input-wrapper">
                    <Field
                      name="state"
                      placeholder="UF"
                      component={SelectInputFinalForm}
                      validate={stateValidator}
                      options={stateUFOptions}
                    />
                  </div>

                  <div>
                    <Field
                      name="city"
                      placeholder="Cidade"
                      component={InputFinalForm}
                      validate={cityValidator}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <Field
                      name="street"
                      placeholder="Endereço"
                      component={InputFinalForm}
                      validate={streetValidator}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <Field
                      name="neighborhood"
                      placeholder="Bairro"
                      component={InputFinalForm}
                      validate={neighborhoodValidator}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="number-input-wrapper">
                    <Field
                      name="number"
                      placeholder="Número"
                      type="number"
                      component={InputFinalForm}
                    />
                  </div>
                  <div>
                    <Field
                      name="complement"
                      placeholder="Complemento"
                      component={InputFinalForm}
                    />
                  </div>
                </div>

                <Button color="primary" fill type="submit">
                  Enviar
                </Button>
              </form>
            )}
          />
        </Card>
      </PublicHeader>

      <div className="main">
        <PublicBanner>
          <span>Seja bem-vindo!</span>
        </PublicBanner>
        <div className="hide-on-mobile" style={{ marginBottom: 447 }} />
      </div>
      <PublicFooter />
    </PublicWrapper>
  );
}

export default CompleteSignUpScreen;

// export default connect(
//   null,
//   { showLoading, closeLoading, showMessage, closeMessage, registerAccount }
// )(withRouter(
//   ));
