import React from 'react';

import { BASE_ENDPOINT } from '~/settings';

import QRCode from '~/components/qr-code';
import StoreLinks from '~/components/store-links';

import { StyledDownloadInstructionsModal } from './styles';

function DownloadInstructionsModal() {
  const url = `${BASE_ENDPOINT}/app-qr-code-redirect/`;

  return (
    <StyledDownloadInstructionsModal>
      <div>
        <p className="hide-on-mobile">
          Abra o USEFLOW pelo QR Code ou acesse a loja do seu smartphone.
        </p>
        <p className="hide-on-desktop">
          Abra o USEFLOW pela loja do seu smartphone.
        </p>
      </div>

      <QRCode title="USEFLOW" url={url} />

      <StoreLinks />
    </StyledDownloadInstructionsModal>
  );
}

export default DownloadInstructionsModal;
