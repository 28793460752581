import React from 'react';

import Button from '~/components/button';
import MarkAsDoneButton from '~/components/mark-as-done-button';
import LimitedTextarea from '~/components/limited-textarea';

import { StyledWriteText } from './styles';

function WriteText({ title, markAsDone, contentData, setContentData, isDone }) {
  const handleSend = () => {
    markAsDone({
      text: contentData.answer
    });
  };

  const setText = text => {
    setContentData({
      ...contentData,
      answer: text
    });
  };

  return (
    <StyledWriteText>
      <div className="step-section">
        <div className="title">
          <h3>{title}</h3>
          {isDone && (
            <MarkAsDoneButton
              isDone={isDone}
              onClick={() =>
                markAsDone({
                  ignoreAction: true
                })
              }
            />
          )}
        </div>
      </div>
      <div className="step-section align-left">
        <LimitedTextarea
          limit={2000}
          value={contentData.answer}
          onChange={event => {
            setText(event.target.value);
          }}
          placeholder="Insira sua resposta aqui"
          disabled={isDone}
        />

        {!isDone && (
          <Button
            color="primary"
            onClick={() => handleSend()}
            disabled={
              !contentData.answer || contentData.answer.trim().length === 0
            }
          >
            Enviar
          </Button>
        )}
      </div>
    </StyledWriteText>
  );
}

export default WriteText;
