import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { postLoaded } from '~/store/ducks/actions';

import useComments from '~/hooks/use-comments';

import { getPost } from '~/api/post';
import { postPoll } from '~/api/post';

import moment from 'moment';
import Card from '~/components/card';
import PostHeader from '~/components/post-header';
import FakeLink from '~/components/fake-link';
import Tags from '~/components/tags';
import ViewPost from '~/components/view-post';
import Modal from '~/components/modal';
import PostImage from '~/components/post-image';
import Poll from '~/components/poll';
import { PostCommentBox } from '~/components/comments';
import SharePost from '~/components/share-post';
import Button from '~/components/button';
import Icon from '~/components/icon';
import NewPostComment from '~/components/new-post-comment';
import PostReactions from '~/components/post-reactions';

import './styles.scss';
import { abbreviateNumberFormatter } from '~/utils';

export const ViewPostControl = ({ id, render }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getPost(id)
      .then(({ data }) => {
        dispatch(postLoaded(data));
        setData(data);
      })
      .catch(e => {
        setError(e);
      });
  }, []);

  return render({
    data,
    error
  });
};

const CommentCount = ({ type, id }) => {
  const { count } = useComments(type, id);
  return abbreviateNumberFormatter(count);
};

const Post = props => {
  const [showViewPost, setShowViewPost] = useState(false);
  const [pollYesPercent, setPollYesPercent] = useState(
    parseInt(props.poll_yes_percent, 10)
  );
  const [pollNoPercent, setPollNoPercent] = useState(
    parseInt(props.poll_no_percent, 10)
  );
  const [yes, setYes] = useState(
    props.me_poll !== null ? props.me_poll === 'yes' : null
  );
  const [showComments, setShowComments] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const showContent = props.content ? props.content : false;

  const onViewPostClose = useCallback(() => setShowViewPost(null));

  const changePoll = value => {
    postPoll(props.id, value)
      .then(({ data: { poll_yes_percent, poll_no_percent, yes } }) => {
        setPollNoPercent(poll_no_percent);
        setPollYesPercent(poll_yes_percent);
        setYes(yes);
      })
      .catch(e => {
        alert('Erro');
      });
  };

  const hideOptions = () => {
    setShowShare(false);
    setShowComments(false);
  };

  const handleShare = () => {
    setShowShare(true);
    setShowComments(false);
  };

  const viewPost = useCallback(() => {
    setShowViewPost(true);
    setShowComments(false);
  }, []);

  return (
    <>
      {showViewPost && (
        <Modal
          className="modal"
          overlayClassName="modal-overlay"
          onClose={onViewPostClose}
        >
          <ViewPostControl
            id={props.id}
            render={({ data }) => data && <ViewPost data={data} />}
          />
        </Modal>
      )}
      <Card>
        <PostHeader
          info="Publicação"
          icon_type="post"
          organization={props.original_data.organization}
          meTrail={props.me_trail}
        />
        <div className="card-body">
          {showContent && (
            <FakeLink
              to={`/post?id=${props.id}`}
              onClick={viewPost}
              data={{
                id: props.id
              }}
            >
              {props.image && <PostImage url={props.image} />}
              <h3 className="post-title text-center">{props.title}</h3>
            </FakeLink>
          )}
          {!showContent && (
            <>
              {props.image && <PostImage url={props.image} />}
              <h3 className="post-title text-center">{props.title}</h3>
            </>
          )}

          <p className="post-description text-center">{props.description}</p>
          {props.tags && <Tags>{props.tags}</Tags>}

          {!props.content && props.have_poll && (
            <Poll
              onChange={changePoll}
              pollState={{
                poll_yes_percent: pollYesPercent,
                poll_no_percent: pollNoPercent,
                yes: yes
              }}
            >
              {props.poll}
            </Poll>
          )}
        </div>
        <footer className="card-footer">
          <div className="flex">
            {props.can_comment && (
              <NewPostComment
                className="inline-block"
                onClick={() => setShowComments(!showComments)}
              >
                <CommentCount type="post" id={props.id} />
              </NewPostComment>
            )}
            {(props.can_like || props.reactions) && (
              <PostReactions
                className="inline-block"
                postId={props.id}
                reactions={props.reactions}
                userLike={props.me_like}
                likes={props.like_counter}
                unlikes={props.unlike_counter}
              ></PostReactions>
            )}
          </div>

          {props.updated_at && (
            <div style={{ textAlign: 'right' }}>
              <small>
                {showContent && (
                  <FakeLink
                    to={`/post?id=${props.id}`}
                    onClick={viewPost}
                    data={{
                      id: props.id
                    }}
                  >
                    <i>Atualizado em </i>
                    <i>
                      {moment(props.updated_at).format(
                        'D [de] MMM [de] YYYY [às] HH:mm'
                      )}
                    </i>
                  </FakeLink>
                )}
                {!showContent && (
                  <>
                    <i>Atualizado em </i>
                    <i>
                      {' '}
                      {moment(props.updated_at).format(
                        'D [de] MMM [de] YYYY [às] HH:mm'
                      )}
                    </i>
                  </>
                )}
              </small>
            </div>
          )}
        </footer>
        {props.can_comment && showComments && <PostCommentBox id={props.id} />}
        <div className="share-post-wrapper">
          {!showShare && props.can_share && (
            <Button color="primary" onClick={handleShare}>
              <Icon name="share" marginRight />
              Compartilhar
            </Button>
          )}

          {showShare && (
            <div className="share-menu">
              <SharePost post={props.id} link={props.link} />
              <Button outline color="primary" onClick={hideOptions}>
                <Icon name="times" marginRight />
                <span>Cancelar</span>
              </Button>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default Post;
