import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { unregisterAccount } from '~/store/ducks/account';

import { PublicWrapper, PublicHeader, PublicFooter } from '~/components/public';

import StyledError403 from './styles';

function Error403({ history }) {
  return (
    <StyledError403>
      <PublicWrapper>
        <PublicHeader />
        <div className="main">
          <div className="content">
            <h3>Conteúdo indisponível</h3>
            <p>
              Parece que a página que você está tentando acessar não está
              disponível. Verifique a URL e tente novamente.
            </p>

            <div className="actions"></div>
          </div>
        </div>
        <PublicFooter />
      </PublicWrapper>
    </StyledError403>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ unregisterAccount }, dispatch);

export default withRouter(connect(mapDispatchToProps)(Error403));
