import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { showMessage } from '~/store/ducks/messageBar';

import Icon from '~/components/icon';
import Button from '~/components/button';
import Select from '~/components/form/select';
import EmptyList from '~/components/empty-list';
import Breadcrumb from '~/components/breadcrumb';
import PageHeader from '~/components/page-header';
import CustomLoading from '~/components/custom-loading';
import InfiniteScroll from '~/components/infinite-scroll';
import PerformanceHistoricItem from './components/performance-historic-item/performance-historic-item';

import { getPerformanceHistoric } from '~/api/feedback';

import { StyledPerformanceHistoricList } from './styles';

function PerformanceHistoricList({
  organization,
  history,
  showMessage,
  location,
  match
}) {
  const { activeOrganizationId } = organization;
  const firstPage = 1;
  const roleId = match.params.roleId;
  const admin = location.pathname.indexOf('/admin') !== -1;

  const { t } = useTranslation();

  const [performanceHistoricList, setPerformanceHistoricList] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [breadcrumbLinks, setBreadcrumbLinks] = useState([
    { id: '1', title: t('PDIs'), route: '/development/pdi' },
    {
      id: '2',
      title: admin ? t('Equipe geral') : t('Minha equipe'),
      route: admin ? '/development/pdi/admin' : '/development/pdi/my-team'
    }
  ]);

  const [order, setOrder] = useState('latest');

  const getPerformanceHistoricList = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    getPerformanceHistoric(activeOrganizationId, filters, page, roleId)
      .then(response => {
        const { results, next, account } = response.data;

        setPerformanceHistoricList(() => results);

        setNextPage(next);

        if (roleId) {
          setBreadcrumbLinks(lastBreadcrumbLinks => {
            lastBreadcrumbLinks[2] = {
              id: '3',
              title: account,
              route: null
            };
            return lastBreadcrumbLinks;
          });
        }
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          history.push('/page-not-found');
          return;
        }

        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOrder = event => {
    const { value } = event.target;

    getPerformanceHistoricList({
      order: value
    });

    setOrder(value);
  };

  const loadMorePerformanceHistoric = () => {
    setLoadingMore(true);

    const filters = {
      order
    };

    getPerformanceHistoric(
      activeOrganizationId,
      filters,
      currentPage + 1,
      roleId,
      admin
    )
      .then(response => {
        setPerformanceHistoricList(lastPerformanceHistoricList => [
          ...lastPerformanceHistoricList,
          ...response.data.results
        ]);
        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    getPerformanceHistoricList({
      order
    });
  }, [activeOrganizationId, roleId]);

  return (
    <StyledPerformanceHistoricList>
      {roleId && (
        <Button
          color="primary"
          className="link"
          onClick={() =>
            history.push(
              admin ? '/development/pdi/admin' : '/development/pdi/my-team'
            )
          }
        >
          <Icon name="back" />
          {t('Voltar')}
        </Button>
      )}

      <PageHeader>
        <div className="text-info">
          <h1>{t('Avaliações')}</h1>
          {!loading && !!roleId && (
            <Breadcrumb links={breadcrumbLinks} compressionStart={10} />
          )}
        </div>
      </PageHeader>

      <div className="filters">
        <Select value={order} onChange={handleOrder}>
          <option value="latest">{t('Mais recente')}</option>
          <option value="oldest">{t('Mais antigo')}</option>
        </Select>
      </div>

      {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      <div className="historic-list">
        {!loading &&
          performanceHistoricList.map(performanceHistoric => (
            <PerformanceHistoricItem
              key={performanceHistoric.pk}
              title={performanceHistoric.title}
              department={performanceHistoric.department}
              managed={performanceHistoric.managed}
              manager={performanceHistoric.manager}
              onClick={() =>
                history.push({
                  pathname: `/development/pdi/my-team/${performanceHistoric.pk}/performances/${performanceHistoric.pk}`,
                  state: { backUrl: location.pathname }
                })
              }
            />
          ))}

        {!loading && performanceHistoricList.length === 0 && (
          <EmptyList message={t('Nenhuma avaliação encontrada.')} />
        )}
      </div>

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )}

      <InfiniteScroll
        fetchMore={loadMorePerformanceHistoric}
        disabled={!nextPage || loading || loadingMore}
      />
    </StyledPerformanceHistoricList>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PerformanceHistoricList));
