import styled from 'styled-components';

export const StyledFeedbackList = styled.div.attrs(() => ({
  className: 'feedback-list-page'
}))`
  width: 100%;

  .page-header {
    padding-left: 8px;

    gap: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0px;
    }

    p {
      font-size: 14px;
    }
  }

  .feedback-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filters {
    display: flex;
    margin-bottom: 32px;

    .search-input {
      flex: 2;
      margin-right: 16px;
    }

    select {
      flex: 1;
      color: var(--secondary-text-color);
      -webkit-appearance: listbox;
      min-height: 41px;

      &:nth-child(2) {
        margin-right: 16px;
      }

      &:focus {
        outline: none;
        border-color: var(--border-color);
        color: var(--secondary-text-color);
      }
    }
  }

  .feedback-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .custom-loading {
    margin: 32px 0px;
  }

  @media (max-width: 720px) {
    .feedback-list {
      margin-bottom: 56px;
    }

    .filters {
      flex-direction: column;

      .search-input {
        flex: initial;
        margin-right: 0px;
        width: 100%;
      }
    }
  }
`;
