import React from 'react';
import moment from 'moment';

import StyledCollaboratorCardPreviousCycle from './styles';
import { useTranslation } from 'react-i18next';

function CollaboratorCardPreviousCycle({
  name,
  avatar,
  department,
  profile,
  manager,
  cycle_start_at,
  cycle_finish_at
}) {
  const { t } = useTranslation();

  return (
    <StyledCollaboratorCardPreviousCycle>
      <div className="segmentation">
        <p>
          {department} -{' '}
          {t('Ciclo de {{cycle_start_at}} até {{cycle_finish_at}}', {
            cycle_start_at: moment(cycle_start_at).format('DD/MM/YYYY'),
            cycle_finish_at: moment(cycle_finish_at).format('DD/MM/YYYY')
          })}
        </p>
      </div>
      <div className="profile-info">
        <div className="profiles">
          {!!manager ? (
            <div className="manager">
              {manager.avatar_128x0 && (
                <div className="avatar">
                  <img src={manager.avatar_128x0} alt="Avatar" />
                </div>
              )}
              <div className="text">
                <p>{t('Avaliador')}</p>
                <h3>{manager.name}</h3>
                <p>{manager.profile}</p>
              </div>
            </div>
          ) : null}
          <div className="managed">
            {avatar && (
              <div className="avatar">
                <img src={avatar} alt="Avatar" />
              </div>
            )}
            <div className="text">
              <p>{t('Avaliado')}</p>
              <h3>{name}</h3>
              <p>{profile}</p>
            </div>
          </div>
        </div>
      </div>
    </StyledCollaboratorCardPreviousCycle>
  );
}

export default CollaboratorCardPreviousCycle;
