import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Check = ({
  children,
  name,
  text,
  type,
  value,
  checked,
  inline,
  onChange,
  disabled
}) => {
  const data = {
    name,
    type,
    onChange
  };

  if (type === 'checkbox') {
    data.checked = checked;
  } else {
    data.value = value || text;
  }

  return (
    <label
      className={`check-wrapper${inline ? ' inline' : ''} ${disabled &&
        ' disabled'}`}
    >
      <input {...data} disabled={disabled} />
      <span>{children || text || name}</span>
    </label>
  );
};

Check.defaultProps = {
  text: '',
  checked: false,
  inline: false,
  onChange: () => {},
  disabled: false
};

Check.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default Check;
