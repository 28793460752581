import React from 'react';
import { Container, Row, Col } from '../../grid';
import Logo from '../../logo';
import './style.scss';

const PublicHeader = ({ children }) => (
  <div className="header public-header">
    <Container>
      <Row>
        <Col md={5} style={{ textAlign: 'center' }}>
          {/* <span style={{ fontSize: '20px' }}>Seja bem-vindo!</span> */}
          {/* <br /> */}
          <Logo reverse={true} />
        </Col>
        <Col md={7}>
          <div className="card-wrapper">{children}</div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default PublicHeader;
