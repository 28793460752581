import React from 'react';
import { useTranslation } from 'react-i18next';

import LimitedTextarea from '~/components/limited-textarea';

import { StyledResearchQuestion } from './styles';
import { CheckList } from '~/components/form';

function ResearchFormQuestion({
  index,
  currentQuestion,
  setQuestion,
  disabled = false
}) {
  const { t } = useTranslation();
  const hasError = currentQuestion.error.hasError;

  const handleFieldChange = ({ target }) => {
    setQuestion(index, target.value);
  };

  const getNPSOptions = nps => {
    const NPSOptions = [];

    for (let i = 1; i <= nps.scale; i += 1) {
      let description = i;

      if (currentQuestion.nps.nps_has_labels) {
        if (i === 1) {
          description = `${i} - ${nps.first_label || t('Discordo')}`;
        }

        if (i === nps.scale) {
          description = `${i} - ${nps.last_label || t('Concordo')}`;
        }
      }

      NPSOptions.push({
        name: description,
        value: i
      });
    }

    return NPSOptions;
  };

  const buildQuestionInput = () => {
    switch (currentQuestion.category) {
      case 'open_answer':
        return (
          <LimitedTextarea
            limit={1000}
            value={currentQuestion.value || ''}
            rows={3}
            onChange={handleFieldChange}
            placeholder={t('Insira sua resposta')}
            disabled={disabled}
            className={hasError ? 'failure' : ''}
          />
        );
      case 'point_scale':
        const NPSOptions = getNPSOptions(currentQuestion.nps);
        return (
          <div className="nps-options">
            <div className={`option-wrapper ${hasError ? 'failure' : ''}`}>
              <CheckList
                name={`question_nps_${currentQuestion.id}`}
                type="radio"
                value={parseInt(currentQuestion.value)}
                options={NPSOptions}
                onChange={handleFieldChange}
                disabled={disabled}
              />
            </div>
          </div>
        );
      default:
        return <p>Ocorreu um erro ao carregar o conteúdo</p>;
    }
  };

  return (
    <StyledResearchQuestion>
      <div className="field-label">
        <div className="question-number">{index + 1}</div>
        <div className="question-title">
          <p className="question">
            {currentQuestion.title}
            <span>{currentQuestion.is_required ? '*' : ''}</span>
          </p>
        </div>
      </div>
      {buildQuestionInput()}
      {hasError && (
        <span className="error">
          <i>{currentQuestion.error.message}</i>
        </span>
      )}
    </StyledResearchQuestion>
  );
}

export default ResearchFormQuestion;
