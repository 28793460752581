import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '~/components/card';
import Icon from '~/components/icon';

import { StyledFeedbackHistoricItem } from './styles';

function FeedbackHistoricItem({
  title,
  status,
  formatedDate,
  onClick,
  answerDate,
  managerName
}) {
  const { t } = useTranslation();

  return (
    <StyledFeedbackHistoricItem onClick={onClick}>
      <Card className="feedback-item">
        <div className="info-side">
          <div className="date-and-status">
            {status === 'finished' && <Icon name="calendar" />}

            {status !== 'finished' && <span className="status" />}

            <p className={status === 'finished' ? 'finished' : ''}>
              {formatedDate}
            </p>
          </div>
          <h3 className="title">{title}</h3>
          <p>{t('Líder {{ manager }}', { manager: managerName })}</p>
          <p>{t('Enviado em {{ date }}', { date: answerDate })}</p>
        </div>
      </Card>
    </StyledFeedbackHistoricItem>
  );
}

export default FeedbackHistoricItem;
