import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuth } from '~/services/auth';
import useSite from '~/hooks/use-site';
import { setRefreshOrganization, storeOrganizationId } from '~/utils';

const Public = ({ component: Component, inverted = false, ...rest }) => {
  const { loginMinutes } = useSite();

  let pass = !isAuth(loginMinutes);

  if (inverted) {
    pass = !pass;
  }

  useEffect(() => {
    if (rest.location.search.indexOf('org=') !== -1) {
      const org = rest.location.search.split('org=')[1].split('&')[0];
      storeOrganizationId(org);
      setRefreshOrganization(true);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={matchProps =>
        pass ? (
          <Component {...matchProps} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: rest.location } }} />
        )
      }
    />
  );
};

export default Public;
