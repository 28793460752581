import styled from 'styled-components';

export const StyledCoursesHistory = styled.div.attrs(() => ({
  className: 'courses-history-page'
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 32px;

  .custom-header {
    position: relative;
  }

  .header-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
      .button {
        background: var(--white-color);
        padding: 0px 8px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;
    }
  }

  .page-header {
    padding-bottom: 0px;
    padding-top: 0px;

    .text-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        min-width: max-content;
      }

      .actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .search-input {
        max-height: 42px;
        width: 40%;
        margin-left: 16px;
        background: var(--white-color);
      }

      select {
        width: 132px;
        color: var(--secondary-text-color);
        -webkit-appearance: listbox;
        min-height: 41px;
        margin-left: 8px;
        margin-right: 0px !important;

        &:nth-child(2) {
          margin-right: 16px;
        }

        &:focus {
          outline: none;
          border-color: var(--border-color);
          color: var(--secondary-text-color);
        }
      }
    }

    h1 {
      margin: 0px;
    }

    p {
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }

  .page-content {
    width: 100%;
    max-width: 800px;
    margin-top: 42px;

    .course-list {
      position: relative;
      width: 100%;
    }
  }

  .screen-loading {
    margin-top: 64px;
  }

  @media (max-width: 720px) {
    .page-header {
      .text-info {
        flex-direction: column;
        align-items: flex-start;

        .search-input {
          margin-left: 0px;
          width: 100%;
        }
      }
    }

    .page-content {
      padding: 16px;
    }
  }
`;
