import React, { useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { withRouter } from 'react-router-dom';

import { answerSurvey } from '~/api/survey';

import { setSurvey, setCurrentQuestion } from '~/store/ducks/survey';
import { openPostModal, closePostModal } from '~/store/ducks/post-modal';

import { storeSurvey, answerFactory, removeSurvey } from '~/services/survey';

import ModalWrapper from '../modal-wrapper';
import Modal from '~/components/modal';
import Button from '~/components/button';
import CompletedSurveyModal from '~/components/survey/completed-survey-modal';
import CanceledSurveyModal from '~/components/survey/canceled-survey-modal';

import skipImage from '~/assets/images/Illustration-skip-question.svg';

import './style.scss';

function SkipQuestionModal({ onClose, survey, currentQuestionIndex, history }) {
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const checkAllQuestionsSkipped = questions =>
    !questions.some(question => !!question.answer);

  const sendAnswer = survey => {
    const answerData = answerFactory(survey);

    const allQuestionSkipped = checkAllQuestionsSkipped(answerData.answers);

    if (allQuestionSkipped) {
      answerData.answers = null;
      answerData.justification = 'Todas as questões foram puladas';
    }

    setLoading(true);

    answerSurvey(survey.pk, answerData)
      .then(response => {
        removeSurvey(`survey${survey.pk}`);

        if (allQuestionSkipped) {
          dispatch(
            openPostModal(
              <CanceledSurveyModal onClose={() => dispatch(closePostModal())} />
            )
          );
        } else {
          dispatch(
            openPostModal(
              <CompletedSurveyModal
                onClose={() => dispatch(closePostModal())}
              />
            )
          );
        }

        history.push('/survey');
      })
      .catch(() => {
        alert('Ocorreu um erro inesperado!');
        removeSurvey(`survey${survey.pk}`);
        history.push('/survey');
      });
  };

  const storeAnswer = survey => {
    storeSurvey(survey);
    dispatch(setSurvey(survey));
    dispatch(setCurrentQuestion(currentQuestionIndex + 1));
  };

  const handleAnswer = () => {
    survey.questions[currentQuestionIndex].justification = reason;

    if (survey.questions.length === currentQuestionIndex + 1) {
      sendAnswer(survey);
    } else {
      storeAnswer(survey);
    }

    onClose();
  };

  return (
    <ModalWrapper>
      <Modal className="message-modal skip-question" onClose={onClose}>
        <img src={skipImage} alt="Skip question" />

        <h3 className="title">Pular questão</h3>
        <p className="description">
          Tudo bem, vamos pular! Poderia nos informar o motivo do pulo?
        </p>

        <div className="reasons">
          <div className="reasons-form-group">
            <input
              type="radio"
              name="reason"
              id="reason-1"
              onChange={() => setReason('Motivo 1')}
            />
            <label htmlFor="reason-1">Motivo 1</label>
          </div>

          <div className="reasons-form-group">
            <input
              type="radio"
              name="reason"
              id="reason-2"
              onChange={() => setReason('Motivo 2')}
            />
            <label htmlFor="reason-2">Motivo 2</label>
          </div>

          <div className="reasons-form-group">
            <input
              type="radio"
              name="reason"
              id="reason-3"
              onChange={() => setReason('Motivo 3')}
            />
            <label htmlFor="reason-3">Motivo 3</label>
          </div>

          <div className="reasons-form-group">
            <input
              type="radio"
              name="reason"
              id="reason-4"
              onChange={() => setReason('Motivo 4')}
            />
            <label htmlFor="reason-4">Motivo 4</label>
          </div>
        </div>

        <div className="actions">
          <Button color="primary" onClick={handleAnswer}>
            {!loading ? 'Pular Questão' : 'Concluindo...'}
          </Button>
          <Button color="link" onClick={onClose}>
            Continuar respondendo
          </Button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}

export default withRouter(SkipQuestionModal);
