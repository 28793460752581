import React from 'react';
import PropTypes from 'prop-types';
import Link from '~/components/link';
import './style.scss';

const Tags = ({ children }) => {
  const tags = children ? children.split(',') : null;
  return (
    <div className="tags">
      <span>TAGS: </span>

      {tags.map((tag, i) => (
        <Link key={i} to={`/?termo=${tag}`}>
          #{tag}
        </Link>
      ))}
    </div>
  );
};

Tags.propTypes = {
  children: PropTypes.string
};

export default Tags;
