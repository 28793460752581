import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMyTeam } from '~/api/pdi';

import { showMessage } from '~/store/ducks/messageBar';

import PageHeader from '~/components/page-header';
import EmptyList from '~/components/empty-list';
import InfiniteScroll from '~/components/infinite-scroll';
import CustomLoading from '~/components/custom-loading';
import PDIParticipantItem from '~/components/pdi-participant-item';
import Button from '~/components/button';
import Icon from '~/components/icon';
import SearchInput from '~/components/search-input';
import SelectMultiple from '~/components/select-multiple';
import Breadcrumb from '~/components/breadcrumb';

import { StyledPDIMyTeam } from './styles';
import { useTranslation } from 'react-i18next';

function PDIMyTeam({ organization, history, showMessage, location }) {
  const { activeOrganizationId } = organization;
  const firstPage = 1;
  const admin =
    location.pathname.split('/development/pdi/')[1] === 'admin' ? true : false;

  const { t } = useTranslation();

  const [participants, setParticipants] = useState([]);

  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [search, setSearch] = useState('');
  const [currentTimeOut, setCurrentTimeOut] = useState(null);

  const [breadcrumbLinks] = useState([
    { id: '1', title: t('PDIs'), route: '/development/pdi' },
    {
      id: '2',
      title: admin ? t('Equipe geral') : t('Minha equipe'),
      route: admin ? '/development/pdi/admin' : '/development/pdi/my-team'
    }
  ]);

  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [profileList, setProfileList] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const [managerList, setManagerList] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);

  const getPDIMyTeam = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    getMyTeam(activeOrganizationId, filters, page, admin)
      .then(response => {
        const { departments, profiles, managers } = response.data;

        setDepartmentList(
          departments.map(department => ({
            value: department.id,
            label: department.name
          }))
        );
        setProfileList(
          profiles.map(profile => ({
            value: profile.id,
            label: profile.name
          }))
        );
        managers.unshift({ id: 'no-lider', name: t('Sem líder') });
        setManagerList(
          managers.map(manager => ({
            value: manager.id,
            label: manager.name
          }))
        );
        setParticipants(() => response.data.results);
        setNextPage(response.data.next);
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          history.push('/page-not-found');
          return;
        }

        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadMoreParticipants = () => {
    setLoadingMore(true);

    const filters = {
      search: '',
      departments: selectedDepartments
        .map(department => department.value)
        .toString(),
      managers: selectedManagers.map(manager => manager.value).toString(),
      profiles: selectedProfiles.map(profile => profile.value).toString()
    };

    getMyTeam(activeOrganizationId, filters, currentPage + 1, admin)
      .then(response => {
        setParticipants(lastParticipantList => [
          ...lastParticipantList,
          ...response.data.results
        ]);

        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      getPDIMyTeam({
        search: value,
        departments: selectedDepartments
          .map(department => department.value)
          .toString(),
        managers: selectedManagers.map(manager => manager.value).toString(),
        profiles: selectedProfiles.map(profile => profile.value).toString()
      });
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const handleDepartments = selectedDepartmentList => {
    getPDIMyTeam({
      search: search,
      departments: selectedDepartmentList
        .map(department => department.value)
        .toString(),
      managers: selectedManagers.map(manager => manager.value).toString(),
      profiles: selectedProfiles.map(profile => profile.value).toString()
    });
    setSelectedDepartments(selectedDepartmentList);
  };

  const handleProfiles = selectedProfileList => {
    getPDIMyTeam({
      search: search,
      departments: selectedDepartments
        .map(department => department.value)
        .toString(),
      managers: selectedManagers.map(manager => manager.value).toString(),
      profiles: selectedProfileList.map(profile => profile.value).toString()
    });
    setSelectedProfiles(selectedProfileList);
  };

  const handleManagerts = selectedManagertList => {
    getPDIMyTeam({
      search: search,
      departments: selectedDepartments
        .map(department => department.value)
        .toString(),
      managers: selectedManagertList.map(manager => manager.value).toString(),
      profiles: selectedProfiles.map(profile => profile.value).toString()
    });
    setSelectedManagers(selectedManagertList);
  };

  useEffect(() => {
    getPDIMyTeam({
      search: '',
      departments: selectedDepartments
        .map(department => department.value)
        .toString(),
      managers: selectedManagers.map(manager => manager.value).toString(),
      profiles: selectedProfiles.map(profile => profile.value).toString()
    });
  }, [activeOrganizationId]);

  return (
    <StyledPDIMyTeam>
      <Button
        color="primary"
        className="link"
        onClick={() => history.push('/development/pdi')}
      >
        <Icon name="back" />
        {t('Voltar')}
      </Button>

      <PageHeader>
        <div className="text-info">
          <h1>{admin ? t('Equipe geral') : t('Minha equipe')}</h1>
          <Breadcrumb links={breadcrumbLinks} compressionStart={10} />
        </div>
        <Button
          color="primary"
          onClick={() => history.push('/development/pdi')}
        >
          {t('Meu PDI')}
        </Button>
      </PageHeader>

      <div className="filters">
        <SearchInput
          value={search}
          onChange={handleSearch}
          placeholder={t('Busque por nome...')}
        />

        {admin && (
          <SelectMultiple
            options={managerList}
            selected={selectedManagers}
            setSelected={handleManagerts}
            placeholder={t('Selecione os líderes...')}
          />
        )}
        {!admin && (
          <SelectMultiple
            options={departmentList}
            selected={selectedDepartments}
            setSelected={handleDepartments}
            placeholder={t('Selecione os departamentos...')}
          />
        )}

        <SelectMultiple
          options={profileList}
          selected={selectedProfiles}
          setSelected={handleProfiles}
          placeholder={t('Selecione os cargos...')}
        />
      </div>

      {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      <div className="pdi-participant-list">
        {!loading &&
          participants.map(participant => (
            <PDIParticipantItem
              key={participant.pk}
              avatar={participant.avatar}
              name={participant.name}
              email={participant.email}
              department={participant.department}
              profile={participant.profile}
              managers={participant.managers}
              admin={admin}
              isActive={participant.is_active}
              links={[
                {
                  title: 'PDI',
                  route: admin
                    ? `/development/pdi/${participant.pk}/admin`
                    : `/development/pdi/${participant.pk}`
                },
                {
                  title: 'Avaliações',
                  route: admin
                    ? `/development/pdi/my-team/${participant.pk}/performances/admin`
                    : `/development/pdi/my-team/${participant.pk}/performances`
                }
              ]}
            />
          ))}

        {!loading && participants.length === 0 && (
          <EmptyList message={t('Nenhum participante encontrado.')} />
        )}
      </div>

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )}

      <InfiniteScroll
        fetchMore={loadMoreParticipants}
        disabled={!nextPage || loading || loadingMore}
      />
    </StyledPDIMyTeam>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PDIMyTeam));
