import styled from 'styled-components';

export const StyledSelectMultiple = styled.div.attrs(() => ({
  className: 'select-multiple'
}))`
  max-width: 190px;
  height: 41px;

  .rmsc {
    --rmsc-border: var(--border-color);
    --rmsc-hover: var(--background-color);
    --rmsc-gray: ${props =>
      props.disabled ? 'rgba(170, 170, 170, 0.5)' : null};
    min-height: 41px;

    .dropdown-container {
      min-height: 41px;
      border: 1px solid var(--border-color);

      .dropdown-heading {
        min-height: 39px;
      }
    }

    .dropdown-container:focus-within {
      border: 1px solid var(--border-color);
      box-shadow: none;
    }
  }
`;
