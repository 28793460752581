import React from 'react';
import renderHTML from 'react-render-html';

import Icon from '~/components/icon';
import Button from '~/components/button';
import MarkAsDoneButton from '~/components/mark-as-done-button';
import SingleChoiceQuestion from '~/components/single-choice-question';

import { StyledSingleChoice } from './styles';

function SingleChoice({
  title,
  text,
  markAsDone,
  contentData,
  setContentData,
  isDone,
  isCorrect
}) {
  const handleSend = () => {
    markAsDone({
      answers: contentData.answer ? contentData.answer.answered : []
    });
  };

  const setAnswer = answer => {
    setContentData({
      ...contentData,
      answer: {
        ...contentData.answer,
        answered: [answer]
      }
    });
  };

  return (
    <StyledSingleChoice>
      <div className="step-section">
        <div className="title">
          <h3>{title}</h3>
          {isDone && (
            <>
              {isCorrect ? (
                <Icon name="check" marginRight className="correct" />
              ) : (
                <Icon name="times" marginRight className="incorrect" />
              )}
              <MarkAsDoneButton
                isDone={isDone}
                onClick={() =>
                  markAsDone({
                    ignoreAction: true
                  })
                }
              />
            </>
          )}
        </div>
      </div>
      {text ? <div className="step-section">{renderHTML(text)}</div> : ''}
      <div className="step-section align-left">
        <SingleChoiceQuestion
          options={contentData.options}
          answer={contentData.answer ? contentData.answer.answered[0] : null}
          setAnswer={setAnswer}
          correctAnswers={
            contentData.answer && contentData.answer.correct
              ? contentData.answer.correct
              : []
          }
          disabled={isDone}
          isDone={isDone}
        />
        {!isDone && (
          <Button color="primary" onClick={() => handleSend()}>
            Enviar
          </Button>
        )}
      </div>
    </StyledSingleChoice>
  );
}

export default SingleChoice;
