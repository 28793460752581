import runTask from '~/run-task';
const makeUrl = text => 'https://wa.me/?text=' + encodeURIComponent(text);

const whatsapp = {
  message({ id, wololo_target_message, isSubtask, onFinish }) {
    return new Promise(resolve => {
      const url = makeUrl(wololo_target_message);
      runTask(id, url, isSubtask, onFinish).then(resolve);
    });
  },

  image({ id, wololo_target_image, isSubtask, onFinish }) {
    return new Promise(resolve => {
      const url = makeUrl(wololo_target_image);
      runTask(id, url, isSubtask, onFinish).then(resolve);
    });
  },

  movie({ id, wololo_target_movie, isSubtask, onFinish }) {
    return new Promise(resolve => {
      const url = makeUrl(wololo_target_movie);
      runTask(id, url, isSubtask, onFinish).then(resolve);
    });
  }
};

export default whatsapp;
