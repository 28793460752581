import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'redux-react-hook';
import AbstractTask from './abstract-task';
import useAccount from '~/hooks/use-account';
import DefaultTaskHeader from './default-task-header';
import DefaultTaskBody from './default-task-body';
import DefaultTaskFooter from './default-task-footer';
import Icon from '~/components/icon';
import Button from '~/components/button';
import actions from './actions';
import { Login as FacebookLogin } from 'react-facebook-sdk';
import { postFacebook } from '~/api/account';
import { BASE_ENDPOINT } from '~/settings';
import { addWololo } from '~/services/tasks';
import { Share } from 'react-facebook-sdk';
import { facebookConnected } from '~/store/ducks/account';
import TaskDoableOnMobile from '~/components/task-doable-on-mobile';

const noop = () => {};

const useRendered = () => {
  const rendered = useRef(true);

  // componentWillUnmount
  useEffect(() => () => (rendered.current = false), []);

  return rendered;
};

const useLoading = callback => {
  const [loading, setLoading] = useState(false);
  const rendered = useRendered();

  const newCallback = useCallback(
    async (...args) => {
      if (loading) {
        return;
      }

      setLoading(true);

      try {
        await callback(...args);
      } finally {
        if (rendered.current) {
          setLoading(false);
        }
      }
    },
    [callback, loading]
  );

  return [loading, newCallback, setLoading];
};

// recebe um path (oauthtwitter por ex)
// recebe um id de task
//
// retorna o callback para ser chamado e o loading
const useConnect = (path, task) => {
  const [loading, setLoading] = useState(false);

  const account = useAccount();

  const connect = useCallback(() => {
    setLoading(true);
    window.localStorage.task = JSON.stringify(task);
    const id = account.data.profile.account;
    const baseUrl = window.location.origin;
    const callbackUrl = `${baseUrl}/wololo-continue/`;
    const url = `${BASE_ENDPOINT}/accounts/me/${path}/?id=${id}&callback_url=${callbackUrl}`;
    window.location.href = url;
  }, [account, task, path]);

  return [connect, loading];
};

// recebe uma action, um callback de onfinished e a data do action
// retorna um array sendo loading boleano e o callback para ser chamado
// a action deve retornar uma promise
//
const useAction = (action, onFinish, actionData) => {
  const [loading, actionTask] = useLoading(
    useCallback(async () => {
      try {
        const response = await action(actionData);
        // Se response.id existir, então é uma subtask
        onFinish(response.id);
      } catch {
        // quando a action não é completada por qualquer motivo
        // nesse caso não quero fazer nada, mas seria interessante
        // dar uma opção para quem chama isso fazer qualquer coisa no seu estado
      }
    }, [actionData, onFinish])
  );

  return [loading, actionTask];
};

const TwitterAbastractTask = ({
  Body,
  buttonText,
  finishedText,
  action,
  ...props
}) => {
  const finalButtonText = props.me_create_wololo
    ? finishedText || 'Compartilhado'
    : buttonText;

  const [loading, actionTask] = useAction(action, props.onFinish, props);
  const [connect, connectLoading] = useConnect('oauthtwitter', props);

  return (
    <AbstractTask
      isSubtask={props.isSubtask}
      channel={props.channel}
      Header={!props.isSubtask ? <DefaultTaskHeader {...props} /> : null}
      Body={
        <>
          {Body}

          <footer className="card-footer">
            <div className="text-center">
              {props.connected ? (
                <Button
                  disabled={props.me_create_wololo || loading}
                  color={props.channel}
                  title={finalButtonText}
                  onClick={actionTask}
                >
                  <Icon name={props.channel} marginRight />
                  <span>{finalButtonText}</span>
                </Button>
              ) : (
                <Button
                  disabled={connectLoading}
                  color={props.channel === 'twitter' ? 'x' : props.channel}
                  title="Conectar com o X (antigo Twitter)"
                  onClick={connect}
                >
                  <Icon
                    name={props.channel === 'twitter' ? 'x' : props.channel}
                    marginRight
                  />
                  <span>Conectar com o X (antigo Twitter)</span>
                </Button>
              )}
            </div>
          </footer>
        </>
      }
      Footer={<DefaultTaskFooter {...props} />}
    />
  );
};

const TwitterTweetTask = props => (
  <TwitterAbastractTask
    {...props}
    buttonText="Compartilhar post"
    Body={
      <DefaultTaskBody {...props}>
        <div
          className="text-center"
          dangerouslySetInnerHTML={{ __html: props.wololo_target_message }}
        />
      </DefaultTaskBody>
    }
    action={actions.twitter.tweet}
  />
);

const TwitterMovieTask = props => (
  <TwitterAbastractTask
    {...props}
    buttonText="Compartilhar vídeo"
    Body={
      <DefaultTaskBody {...props}>
        <div className="text-center">
          <Button
            className="link"
            color="primary"
            href={props.wololo_target_movie}
          >
            Ver no X (antigo Twitter)
          </Button>
        </div>
      </DefaultTaskBody>
    }
    action={actions.twitter.movie}
  />
);

const TwitterLikeTask = props => (
  <TwitterAbastractTask
    {...props}
    buttonText="Curtir tweet"
    Body={
      <DefaultTaskBody {...props}>
        <div className="text-center">
          <Button
            className="link"
            color="primary"
            href={props.wololo_target_url}
          >
            Ver no X (antigo Twitter)
          </Button>
        </div>
      </DefaultTaskBody>
    }
    action={actions.twitter.like}
  />
);

const TwitterRetweetTask = props => (
  <TwitterAbastractTask
    {...props}
    buttonText="Retuitar"
    Body={
      <DefaultTaskBody {...props}>
        <div className="text-center">
          <Button
            className="link"
            color="primary"
            href={props.wololo_target_url}
          >
            Ver no X (antigo Twitter)
          </Button>
        </div>
      </DefaultTaskBody>
    }
    action={actions.twitter.retweet}
  />
);

const LinkedinAbastractTask = ({ Body, buttonText, action, ...props }) => {
  const finalButtonText = props.me_create_wololo ? 'Compartilhado' : buttonText;

  const [loading, actionTask] = useAction(action, props.onFinish, props);
  const [connect, connectLoading] = useConnect('oauthlinkedin', props);

  return (
    <AbstractTask
      isSubtask={props.isSubtask}
      channel={props.channel}
      Header={!props.isSubtask ? <DefaultTaskHeader {...props} /> : null}
      Body={
        <>
          {Body}

          <footer className="card-footer">
            <div className="text-center">
              {props.connected ? (
                <Button
                  disabled={props.me_create_wololo || loading}
                  color={props.channel}
                  title={finalButtonText}
                  onClick={actionTask}
                >
                  <Icon name={props.channel} marginRight />
                  <span>{finalButtonText}</span>
                </Button>
              ) : (
                <Button
                  disabled={connectLoading}
                  color={props.channel}
                  title="Conectar com o Linkedin"
                  onClick={connect}
                >
                  <Icon name={props.channel} marginRight />
                  <span>Conectar com o Linkedin</span>
                </Button>
              )}
            </div>
          </footer>
        </>
      }
      Footer={<DefaultTaskFooter {...props} />}
    />
  );
};

const InstagramAbastractTask = ({ Body, buttonText, action, ...props }) => {
  const finalButtonText = props.me_create_wololo ? 'Compartilhado' : buttonText;

  const [loading, actionTask] = useAction(action, props.onFinish, props);
  const [connect, connectLoading] = useConnect('oauthinstagram', props);

  return (
    <AbstractTask
      isSubtask={props.isSubtask}
      channel={props.channel}
      Header={!props.isSubtask ? <DefaultTaskHeader {...props} /> : null}
      Body={
        <>
          {Body}

          <footer className="card-footer">
            <div className="text-center">
              {props.connected ? (
                <Button
                  disabled={props.me_create_wololo || loading}
                  color={props.channel}
                  title={finalButtonText}
                  onClick={actionTask}
                >
                  <Icon name={props.channel} marginRight />
                  <span>{finalButtonText}</span>
                </Button>
              ) : (
                <Button
                  disabled={connectLoading}
                  color={props.channel}
                  title="Conectar com o Instagram"
                  onClick={connect}
                >
                  <Icon name={props.channel} marginRight />
                  <span>Conectar com o Instagram</span>
                </Button>
              )}
            </div>
          </footer>
        </>
      }
      Footer={<DefaultTaskFooter {...props} />}
    />
  );
};

const YoutubeAbastractTask = ({
  Body,
  buttonText,
  buttonTextCompleted = 'Assistido',
  action,
  ...props
}) => {
  const finalButtonText = props.me_create_wololo
    ? buttonTextCompleted
    : buttonText;

  const [loading, actionTask] = useAction(action, props.onFinish, props);

  return (
    <AbstractTask
      isSubtask={props.isSubtask}
      channel={props.channel}
      Header={!props.isSubtask ? <DefaultTaskHeader {...props} /> : null}
      Body={
        <>
          {Body}

          <footer className="card-footer">
            <div className="text-center">
              <Button
                disabled={props.me_create_wololo || loading}
                color={props.channel}
                title={finalButtonText}
                onClick={actionTask}
              >
                <Icon name={props.channel} marginRight />
                <span>{finalButtonText}</span>
              </Button>
            </div>
          </footer>
        </>
      }
      Footer={<DefaultTaskFooter {...props} />}
    />
  );
};

const KalturaAbstractTask = ({
  Body,
  buttonText,
  buttonTextCompleted = 'Assistido',
  action,
  ...props
}) => {
  const finalButtonText = props.me_create_wololo
    ? buttonTextCompleted
    : buttonText;

  // A ação Kaltura não é pontuável até o momento
  // props.wololo_points = null;
  props.isSubtask = false;
  const [loading, actionTask] = useAction(action, props.onFinish, props);

  return (
    <AbstractTask
      isSubtask={props.isSubtask}
      channel={props.channel}
      Header={!props.isSubtask ? <DefaultTaskHeader {...props} /> : null}
      Body={
        <>
          {Body}

          <footer className="card-footer">
            <div className="text-center">
              <Button
                disabled={props.me_create_wololo || loading}
                color={props.channel}
                title={finalButtonText}
                onClick={actionTask}
              >
                <Icon name={props.channel} marginRight />
                <span>{finalButtonText}</span>
              </Button>
            </div>
          </footer>
        </>
      }
      Footer={<DefaultTaskFooter {...props} />}
    />
  );
};

const WhatsappAbastractTask = ({
  Body,
  buttonText,
  buttonTextCompleted = 'Compartilhado',
  action,
  ...props
}) => {
  const finalButtonText = props.me_create_wololo
    ? buttonTextCompleted
    : buttonText;

  const [loading, actionTask] = useAction(action, props.onFinish, props);

  return (
    <AbstractTask
      isSubtask={props.isSubtask}
      channel={props.channel}
      Header={!props.isSubtask ? <DefaultTaskHeader {...props} /> : null}
      Body={
        <>
          {Body}

          <footer className="card-footer">
            <div className="text-center">
              <Button
                disabled={props.me_create_wololo || loading}
                color={props.channel}
                title={finalButtonText}
                onClick={actionTask}
              >
                <Icon name={props.channel} marginRight />
                <span>{finalButtonText}</span>
              </Button>
            </div>
          </footer>
        </>
      }
      Footer={<DefaultTaskFooter {...props} />}
    />
  );
};

const FacebookAbastractTask = ({
  Body,
  buttonText,
  buttonTextCompleted = 'Compartilhado',
  action,
  ...props
}) => {
  const finalButtonText = props.me_create_wololo
    ? buttonTextCompleted
    : buttonText;

  const [connectLoading, setConnectLoading] = useState(false);
  const [loading, actionTask] = useAction(action, props.onFinish, props);

  const dispatch = useDispatch();
  const connected = props.connected;

  return (
    <AbstractTask
      isSubtask={props.isSubtask}
      channel={props.channel}
      Header={!props.isSubtask ? <DefaultTaskHeader {...props} /> : null}
      Body={
        <>
          {Body}

          <footer className="card-footer">
            <div className="text-center">
              {connected ? (
                <Button
                  disabled={props.me_create_wololo || loading}
                  color={props.channel}
                  title={finalButtonText}
                  onClick={actionTask}
                >
                  <Icon name={props.channel} marginRight />
                  <span>{finalButtonText}</span>
                </Button>
              ) : (
                <FacebookLogin
                  onResponse={({ tokenDetail: { accessToken } }) => {
                    postFacebook(accessToken)
                      .then(({ data }) => {
                        actionTask();
                        dispatch(facebookConnected(data));
                      })
                      .catch(err => {
                        console.warn(err);
                        setConnectLoading(false);
                        alert('Erro ao autenticar com o facebook!');
                      });
                  }}
                  onError={(...args) => {
                    setConnectLoading(false);
                    alert('Erro ao autenticar com Facebook');
                  }}
                  render={({ onClick }) => (
                    <Button
                      color="facebook"
                      onClick={(...args) => {
                        setConnectLoading(true);
                        onClick(...args);
                      }}
                      disabled={connectLoading}
                    >
                      <Icon name="facebook" marginRight />
                      <span>Conectar com o Facebook</span>
                    </Button>
                  )}
                />
              )}
            </div>
          </footer>
        </>
      }
      Footer={<DefaultTaskFooter {...props} />}
    />
  );
};

const FacebookShareAbastractTask = ({
  Body,
  shareUrl,
  buttonText,
  buttonTextCompleted = 'Compartilhado',
  ...props
}) => {
  const finalButtonText = props.me_create_wololo
    ? buttonTextCompleted
    : buttonText;

  const [connectLoading, setConnectLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const connected = props.connected;

  const dispatch = useDispatch();

  const actionTask = useCallback(() => {
    addWololo(props.id)
      .then(() => {
        props.onFinish();
      })
      .catch(() => {
        setLoading(false);
      });
  }, [props]);

  return (
    <AbstractTask
      isSubtask={props.isSubtask}
      channel={props.channel}
      Header={!props.isSubtask ? <DefaultTaskHeader {...props} /> : null}
      Body={
        <>
          {Body}

          <footer className="card-footer">
            <div className="text-center">
              <Share
                display="popup"
                href={shareUrl}
                onResponse={() => {
                  actionTask();
                }}
                onError={() => {
                  setLoading(false);
                }}
                render={({ onClick }) =>
                  connected ? (
                    <Button
                      disabled={props.me_create_wololo || loading}
                      color={props.channel}
                      title={finalButtonText}
                      onClick={(...params) => {
                        setLoading(true);
                        onClick(...params);
                      }}
                    >
                      <Icon name={props.channel} marginRight />
                      <span>{finalButtonText}</span>
                    </Button>
                  ) : (
                    <FacebookLogin
                      onResponse={({ tokenDetail: { accessToken } }) => {
                        postFacebook(accessToken)
                          .then(({ data }) => {
                            setLoading(true);
                            onClick({
                              preventDefault: noop,
                              stopPropagation: noop
                            });
                            dispatch(facebookConnected(data));
                          })
                          .catch(err => {
                            console.warn(err);
                            setConnectLoading(false);
                            alert('Erro ao autenticar com o facebook!');
                          });
                      }}
                      onError={(...args) => {
                        setConnectLoading(false);
                        alert('Erro ao autenticar com Facebook');
                      }}
                      render={({ onClick }) => (
                        <Button
                          color="facebook"
                          onClick={(...args) => {
                            setConnectLoading(true);
                            onClick(...args);
                          }}
                          disabled={connectLoading}
                        >
                          <Icon name="facebook" marginRight />
                          <span>Conectar com o Facebook</span>
                        </Button>
                      )}
                    />
                  )
                }
              />
            </div>
          </footer>
        </>
      }
      Footer={<DefaultTaskFooter {...props} />}
    />
  );
};

const FacebookWatchTask = props => (
  <FacebookAbastractTask
    {...props}
    buttonText="Assistir"
    buttonTextCompleted="Já Assistiu"
    Body={<DefaultTaskBody {...props} />}
    action={actions.facebook.watch}
  />
);

const FacebookShareTask = props => (
  <FacebookShareAbastractTask
    {...props}
    buttonText="Compartilhar"
    shareUrl={props.wololo_target_url}
    Body={
      <DefaultTaskBody {...props}>
        <div className="text-center">
          <Button
            className="link"
            color="primary"
            href={props.wololo_target_url}
          >
            Ver no facebook
          </Button>
        </div>
      </DefaultTaskBody>
    }
  />
);

const FacebookImageTask = (props, types, channels) => (
  <TaskDoableOnMobile types={types} channels={channels} {...props}>
    <img
      src={props.wololo_target_image}
      style={{ width: '100%' }}
      alt={props.description}
    />
  </TaskDoableOnMobile>
);

const FacebookMovieTask = (props, types, channels) => (
  <TaskDoableOnMobile types={types} channels={channels} {...props}>
    <video
      style={{
        width: '100%'
      }}
      controls
      preload="none"
      src={props.wololo_target_movie}
    />
  </TaskDoableOnMobile>
);

const LinkedinShareTask = props => (
  <LinkedinAbastractTask
    {...props}
    buttonText="Compartilhar"
    Body={<DefaultTaskBody {...props} />}
    action={actions.linkedin.share}
  />
);

const LinkedinMessageTask = props => (
  <LinkedinAbastractTask
    {...props}
    buttonText="Compartilhar mensagem"
    Body={
      <DefaultTaskBody {...props}>
        <div
          className="text-center"
          dangerouslySetInnerHTML={{ __html: props.wololo_target_message }}
        />
      </DefaultTaskBody>
    }
    action={actions.linkedin.message}
  />
);

const LinkedinImageTask = props => (
  <LinkedinAbastractTask
    {...props}
    buttonText="Compartilhar imagem"
    Body={
      <DefaultTaskBody {...props}>
        <img
          src={props.wololo_target_image}
          style={{ width: '100%' }}
          alt={props.description}
        />
      </DefaultTaskBody>
    }
    action={actions.linkedin.image}
  />
);

const LinkedinMovieTask = props => (
  <LinkedinAbastractTask
    {...props}
    buttonText="Compartilhar vídeo"
    Body={
      <DefaultTaskBody {...props}>
        <video
          style={{
            width: '100%'
          }}
          controls
          preload="none"
          src={props.wololo_target_movie}
        />
      </DefaultTaskBody>
    }
    action={actions.linkedin.movie}
  />
);

const InstagramLikeTask = props => (
  <InstagramAbastractTask
    {...props}
    buttonText="Curtir no Instagram"
    buttonTextCompleted="Curtido"
    Body={<DefaultTaskBody {...props} />}
    action={actions.instagram.like}
  />
);

const InstagramCommentTask = props => (
  <InstagramAbastractTask
    {...props}
    buttonText="Comentar no Instagram"
    buttonTextCompleted="Comentado"
    Body={<DefaultTaskBody {...props} />}
    action={actions.instagram.like}
  />
);

const WhatsappMessageTask = props => (
  <WhatsappAbastractTask
    {...props}
    buttonText="Enviar mensagem no Whatsapp"
    buttonTextCompleted="Enviado"
    Body={
      <DefaultTaskBody {...props}>
        <p className="text-center">"{props.wololo_target_message}"</p>
      </DefaultTaskBody>
    }
    action={actions.whatsapp.message}
  />
);

const WhatsappImageTask = props => (
  <WhatsappAbastractTask
    {...props}
    buttonText="Enviar imagem no Whatsapp"
    buttonTextCompleted="Enviado"
    Body={
      <DefaultTaskBody {...props}>
        <img
          src={props.wololo_target_image}
          style={{ width: '100%' }}
          alt={props.description}
        />
      </DefaultTaskBody>
    }
    action={actions.whatsapp.image}
  />
);

const WhatsappMovieTask = props => (
  <WhatsappAbastractTask
    {...props}
    buttonText="Enviar vídeo no Whatsapp"
    buttonTextCompleted="Enviado"
    Body={
      <DefaultTaskBody {...props}>
        <video
          style={{
            width: '100%'
          }}
          controls
          preload="none"
          src={props.wololo_target_movie}
        />
      </DefaultTaskBody>
    }
    action={actions.whatsapp.movie}
  />
);

const YoutubeWatchTask = props => (
  <YoutubeAbastractTask
    {...props}
    buttonText="Assistir vídeo"
    buttonTextCompleted="Assistido"
    Body={<DefaultTaskBody {...props} />}
    action={actions.youtube.watch}
  />
);

const KalturaMovieTask = props => (
  <KalturaAbstractTask
    {...props}
    buttonText="Assistir vídeo"
    buttonTextCompleted="Assistido"
    Body={<DefaultTaskBody {...props} />}
    action={actions.kaltura.watch}
  />
);

// Qual a responsabilidade dessa classe?
//
// Essa classe é responsável por retornar um abastract class
// configurado com as opções da tarefa
class TaskComponentFactory {
  static createTask(props) {
    const { channel, type } = props;

    switch (`${channel}-${type}`) {
      case 'linkedin-share': {
        return <LinkedinShareTask {...props} />;
      }

      case 'linkedin-message': {
        return <LinkedinMessageTask {...props} />;
      }

      case 'linkedin-image': {
        return <LinkedinImageTask {...props} />;
      }

      case 'linkedin-movie': {
        return <LinkedinMovieTask {...props} />;
      }

      case 'twitter-tweet': {
        return <TwitterTweetTask {...props} />;
      }

      case 'twitter-movie': {
        return <TwitterMovieTask {...props} />;
      }

      case 'twitter-like': {
        return <TwitterLikeTask {...props} />;
      }

      case 'twitter-retweet': {
        return <TwitterRetweetTask {...props} />;
      }

      case 'facebook-watch': {
        return <FacebookWatchTask {...props} />;
      }

      case 'facebook-share': {
        return <FacebookShareTask {...props} />;
      }

      case 'facebook-image': {
        return <FacebookImageTask {...props} />;
      }

      case 'facebook-movie': {
        return <FacebookMovieTask {...props} />;
      }

      case 'instagram-like': {
        return <InstagramLikeTask {...props} />;
      }

      case 'instagram-comment': {
        return <InstagramCommentTask {...props} />;
      }

      case 'whatsapp-message': {
        return <WhatsappMessageTask {...props} />;
      }

      case 'whatsapp-image': {
        return <WhatsappImageTask {...props} />;
      }

      case 'whatsapp-movie': {
        return <WhatsappMovieTask {...props} />;
      }

      case 'youtube-watch': {
        return <YoutubeWatchTask {...props} />;
      }

      case 'kaltura-embedded_movie': {
        return <KalturaMovieTask {...props} />;
      }

      // falta colocar, não tem no dev
      // case 'twitter-image': {
      //   return <TwitterRetweetTask {...props} />;
      // }

      default: {
        return null;
      }
    }
  }
}

export default TaskComponentFactory;
