import React from 'react';
import moment from 'moment';

import { StyledTimelineStatus } from './styles';
import { useTranslation } from 'react-i18next';

function TimelineStatus({ owner, type, description, status, createdAt }) {
  const { t } = useTranslation();

  const getFieldLabel = field => {
    if (field === 'deadline') {
      return t('o prazo');
    }
  };

  let message = '';

  if (type === 'add') {
    message = t('registrou esse PDI');
  }

  if (type === 'update') {
    message = `${t('editou {{field}} para {{new_value}}', {
      field: getFieldLabel(description.field),
      new_value: description.new_value
    })} ${t('semana', {
      count: description.new_value
    })}
    `;
  }

  if (type === 'status') {
    message = t('mudou a situação para {{status}}', {
      status
    });
  }

  return (
    <StyledTimelineStatus>
      {type !== 'comment' && (
        <>
          <p className="status-message">
            {owner} {message}
          </p>
          <p className="status-date">
            {moment(createdAt).format('DD/MM/YYYY [-] HH:mm')}
          </p>
        </>
      )}

      {type === 'comment' && (
        <div className="comment">
          <div className="comment-header">
            <p className="status-message">
              <span>{owner}</span> {t('comentou')}
            </p>
            <p className="status-date">
              {moment(createdAt).format('DD/MM/YYYY [-] HH:mm')}
            </p>
          </div>
          <div className="comment-content">{description}</div>
        </div>
      )}
    </StyledTimelineStatus>
  );
}

export default TimelineStatus;
