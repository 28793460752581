import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { showMessage } from '~/store/ducks/messageBar';

import { getFullLibrary, getFolder } from '~/api/library';

import Card from '~/components/card';
import LibraryFolder from '~/components/library-folder';
import LibraryFile from '~/components/library-file';
import LibraryLink from '~/components/library-link';
import LoadingMessage from '~/components/loading-message';
import Breadcrumb from '~/components/breadcrumb';
import Button from '~/components/button';

import './styles.scss';
import Modal from '~/components/modal';
import renderHTML from 'react-render-html';
import CustomLoading from '~/components/custom-loading';

import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

const LibrarySection = ({ sectionTitle, className, children }) => {
  return (
    <>
      {sectionTitle && <h3 className="section-title">{sectionTitle}</h3>}
      <section className={className}>{children}</section>
    </>
  );
};

const initialState = {
  folders: [],
  files: [],
  links: []
};

function Library({ organization: { activeOrganizationId }, showMessage }) {
  const firstPage = 1;

  const { t } = useTranslation();

  const [loadedData, setLoadedData] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [nextPage, setNextPage] = useState(null);
  const [libraryData, setLibraryData] = useState({ ...initialState });
  const [breadcrumbData, setBreadcrumbData] = useState([]);
  const [showViewLink, setShowViewLink] = useState(false);
  const onViewClose = useCallback(() => setShowViewLink(null));
  const [modalContent, setModalContent] = useState(null);
  const distributeLibraryData = (isFirstPage, data, folderId) => {
    let newLibraryData = { ...libraryData };

    if (isFirstPage) {
      newLibraryData = {
        folders: [],
        files: [],
        links: []
      };
    }

    data.results.forEach(item => {
      if (!item.data_content) {
        newLibraryData.folders.push(item);
      } else {
        if (item.data_content.type === 'page') {
          newLibraryData.links.push(item);
        } else {
          newLibraryData.files.push(item);
        }
      }
    });

    const libraryLink = {
      id: null,
      title: 'Biblioteca',
      route: '/library'
    };

    if (folderId) {
      setBreadcrumbData([
        libraryLink,
        ...data.obj.breadcrumb,
        {
          id: folderId,
          title: data.obj.title
        }
      ]);
    } else {
      setBreadcrumbData([libraryLink]);
    }

    setLibraryData(newLibraryData);
  };
  useEffect(() => {
    const folderId = window.location.pathname.substr(9);
    setCurrentPage(firstPage);

    if (activeOrganizationId) {
      setLoadedData(false);

      if (folderId) {
        getFolder(folderId, activeOrganizationId, firstPage)
          .then(({ data }) => {
            distributeLibraryData(true, data, folderId);
            setNextPage(data.next);
          })
          .catch(() => {
            showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
          })
          .finally(() => {
            setLoadedData(true);
          });
      } else {
        getFullLibrary(activeOrganizationId, firstPage)
          .then(({ data }) => {
            distributeLibraryData(true, data);
            setNextPage(data.next);
          })
          .catch(() => {
            showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
          })
          .finally(() => {
            setLoadedData(true);
          });
      }
    }
  }, [window.location.pathname]);

  const loadMoreFiles = () => {
    setLoadingMore(true);

    const folderId = window.location.pathname.substr(9);

    if (folderId) {
      getFolder(folderId, activeOrganizationId, currentPage + 1)
        .then(({ data }) => {
          distributeLibraryData(false, data, folderId);
          setNextPage(data.next);
          setCurrentPage(lastPage => {
            return lastPage + 1;
          });
        })
        .catch(() => {
          showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
        })
        .finally(() => {
          setLoadingMore(false);
        });
    } else {
      getFullLibrary(activeOrganizationId, currentPage + 1)
        .then(({ data }) => {
          distributeLibraryData(false, data);
          setNextPage(data.next);
          setCurrentPage(lastPage => {
            return lastPage + 1;
          });
        })
        .catch(() => {
          showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
        })
        .finally(() => {
          setLoadingMore(false);
        });
    }
  };

  return (
    <>
      {showViewLink && (
        <Modal
          className="modal"
          overlayClassName="modal-overlay"
          onClose={onViewClose}
        >
          {modalContent && (
            <div className="modal-link">
              <h3 className="text-center">{modalContent.title}</h3>
              {/*<p className="text-center">{modalContent.description}</p>*/}
              <div>{renderHTML(modalContent.content)}</div>
            </div>
          )}
        </Modal>
      )}
      <div className="library-screen">
        {!loadedData && <LoadingMessage />}

        {loadedData && (
          <Card>
            <div className="library-header">
              <h1>Biblioteca</h1>
            </div>
            <div className="library-content">
              <Breadcrumb
                links={breadcrumbData.map(item => {
                  if (!item.route) {
                    item.route = `/library/${item.id}`;
                  }
                  return item;
                })}
              />

              {libraryData.folders.length > 0 && (
                <LibrarySection className="folders">
                  {libraryData.folders.map(folder => (
                    <LibraryFolder
                      key={folder.id}
                      title={folder.title}
                      folderId={folder.id}
                    />
                  ))}
                </LibrarySection>
              )}

              {libraryData.files.length > 0 && (
                <LibrarySection className="files">
                  {libraryData.files.map(file => (
                    <LibraryFile
                      key={file.id}
                      title={file.title}
                      extension={file.data_content.extension}
                      size={file.data_content.size}
                      file={file.url_access_content}
                      downloadFile={file.data_content.download_file}
                      image={file.data_content.cover}
                      createdAt={file.created_at}
                    />
                  ))}
                </LibrarySection>
              )}

              {libraryData.links.length > 0 && (
                <LibrarySection className="links">
                  {libraryData.links.map(link => (
                    <LibraryLink
                      key={link.id}
                      title={link.title}
                      url={link.url_access_content}
                      image={link.data_content.cover_128x0}
                      createdAt={link.created_at}
                      setModalContent={setModalContent}
                      setShowViewLink={setShowViewLink}
                    />
                  ))}
                </LibrarySection>
              )}

              {loadedData &&
                libraryData.folders.length === 0 &&
                libraryData.files.length === 0 &&
                libraryData.links.length === 0 && (
                  <h3 className="empty-folder">
                    Não há itens para serem exibidos para você aqui.
                  </h3>
                )}

              {loadingMore && (
                <CustomLoading type="spin" height={36} width={36} fluid />
              )}

              {nextPage && loadedData && !loadingMore && (
                <Button
                  color="white"
                  className="link load-more"
                  onClick={loadMoreFiles}
                >
                  {t('Ver mais')}
                </Button>
              )}
            </div>
          </Card>
        )}
      </div>
    </>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ organization }) => ({ organization });

export default connect(mapStateToProps, mapDispatchToProps)(Library);
