import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import Button from '~/components/button';
import Icon from '~/components/icon';
import CustomLoading from '~/components/custom-loading';

import { StyledCommentField } from './styles';
import { useTranslation } from 'react-i18next';

function CommentField({ value, onChange, onSend, loading, disabled }) {
  const { t } = useTranslation();

  return (
    <StyledCommentField>
      <TextareaAutosize
        value={value}
        onChange={onChange}
        placeholder={t('Insira um comentário')}
        disabled={disabled}
        maxRows={4}
        className="default-scroll"
      />
      <Button
        color="primary"
        className="link"
        onClick={onSend}
        disabled={disabled}
      >
        {!loading ? (
          <Icon name="publishing" />
        ) : (
          <CustomLoading type="spin" height={16} width={16} fluid />
        )}
      </Button>
    </StyledCommentField>
  );
}

export default CommentField;
