import styled from 'styled-components';

export const StyledEditorialLine = styled.div.attrs(() => ({
  className: 'editorial-line'
}))`
  flex-shrink: 0;

  .editorial-line-container {
    display: block;
    cursor: pointer;
    width: 96px;
    text-align: center;
    margin: 0px 4px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .editorial-icon-wrapper {
      border-radius: 50%;
      padding: 4px;
      width: 56px;
      height: 56px;
      border: 3px dotted #e0e0e0;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 26px;
        height: 26px;
        path {
          fill: ${({ iconColor }) => iconColor};
        }
      }
    }

    .editorial-name {
      width: 100%;
      margin-top: 4px;

      p {
        margin: auto;
        text-align: center;
        font-size: 0.8em;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .editorial-name-single-word {
      width: 100%;
      margin-top: 4px;
      p {
        margin: auto;
        text-align: center;
        font-size: 0.8em;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.selected {
      .editorial-icon-wrapper {
        border: 3px solid var(--primary-color);
      }
      .editorial-name {
        color: var(--text-color);
      }
    }
  }
`;
