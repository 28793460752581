import React from 'react';

import { StyledPageHeader } from './styles';
import { isMobileEmbeddedSession } from '~/utils';

function PageHeader({ className, children }) {
  const isEmbedded = isMobileEmbeddedSession();

  if (isEmbedded) {
    return null;
  }

  return <StyledPageHeader className={className}>{children}</StyledPageHeader>;
}

export default PageHeader;
