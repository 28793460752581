import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { closeDefaultModal } from '~/store/ducks/default-modal';

import Close from '../close';

import './style.scss';

ReactModal.setAppElement('#root');

const DefaultModal = ({
  isOpen,
  maxWidth,
  padding,
  className,
  closeDefaultModal,
  children,
  hideCloseButton,
  disableBackgroundClick,
  onClose
}) => (
  <ReactModal
    className={`default-modal ${className}`}
    overlayClassName="default-modal-overlay"
    isOpen={isOpen}
    parentSelector={() => document.getElementById('modal-root')}
    onRequestClose={event => {
      closeDefaultModal(event);
      if (onClose) {
        onClose();
      }
    }}
    style={{
      content: {
        maxWidth: maxWidth
      },
      overlay: {
        padding: padding
      }
    }}
    shouldCloseOnOverlayClick={!disableBackgroundClick}
    shouldCloseOnEsc={!disableBackgroundClick}
  >
    <div className="modal-header">
      {!hideCloseButton && (
        <Close
          onClick={event => {
            closeDefaultModal(event);
            if (onClose) {
              onClose();
            }
          }}
        />
      )}
    </div>
    <div className="modal-content">{children}</div>
  </ReactModal>
);

DefaultModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  }
});

export default connect(null, mapDispatchToProps)(DefaultModal);
