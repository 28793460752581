import React from 'react';
import { useTranslation } from 'react-i18next';

import LimitedTextarea from '~/components/limited-textarea';

import { StyledFeedbackQuestion } from './styles';

function FeedbackFormQuestion({
  currentQuestion,
  questionNumber,
  questions,
  setQuestions,
  questionType,
  isHistoryForm,
  disabled
}) {
  const { t } = useTranslation();

  const handleFieldChange = ({ target }) => {
    setQuestions(() => {
      const currentQuestions = [...questions];
      currentQuestions[questionNumber - 1].answer = target.value;
      return currentQuestions;
    });
  };

  return (
    <StyledFeedbackQuestion>
      <div className="field-label">
        <div className="question-number">{questionNumber}</div>
        <div className="question-title">
          <p className="question">{currentQuestion.title}</p>
          {isHistoryForm &&
            (questionType === 'manager' ? (
              <span>{t('Questão do líder')}</span>
            ) : (
              <span>{t('Questão do liderado')}</span>
            ))}
        </div>
      </div>
      <LimitedTextarea
        limit={2000}
        value={currentQuestion.answer || ''}
        onChange={handleFieldChange}
        placeholder={disabled ? '' : t('Descreva sua justificativa')}
        disabled={disabled}
      />
    </StyledFeedbackQuestion>
  );
}

export default FeedbackFormQuestion;
