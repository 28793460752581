import styled from 'styled-components';

export const StyledPerformanceCard = styled.div`
  width: 100%;

  .card {
    width: 100%;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 46px 54px;
    margin: 0px;
    margin-bottom: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .date-and-status {
      width: max-content;
      padding: 2px 8px;
      border-radius: 4px;
      background: var(--background-color);
      font-size: 16px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;

      span {
        display: block;
        height: 8px;
        width: 8px;
        background: var(--success-color);
        border-radius: 50%;
        margin-right: 8px;
      }

      i {
        margin-right: 8px;
        color: var(--secondary-text-color);
        font-size: 14px;
      }

      p {
        color: var(--text-color);
      }

      p.finished {
        color: var(--secondary-text-color);
      }
    }

    h3 {
      font-size: 20px;
      margin: 0px;
      margin-bottom: 6px;
      max-width: 30ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      color: var(--secondary-text-color);
      text-align: start;
      margin: 0px;
    }

    .action-side {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .button {
        padding: 10px 14px;
        border-radius: 4px;
        min-width: 170px;
      }
    }
  }

  @media (max-width: 720px) {
    .performance-item {
      flex-direction: column;
      padding: 32px;

      & > div {
        width: 100%;
      }

      .action-side {
        margin-top: 16px;
      }
    }
  }
`;
