import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Card from '~/components/card';
import Icon from '~/components/icon';
// import StoreLinks from '~/components/store-links';
import { showLoading, closeLoading } from '~/store/ducks/loading';
import { showMessage } from '~/store/ducks/messageBar';
import { passwordReset } from '~/api/auth';
import { getParam, getError } from '~/utils';
import Carousel from '~/components/carousel';
import { StyledCarouselImage } from '~/components/carousel/styles';
import SlideBalloon from '~/components/new-ui/SlideBalloon';
import NewButton from '~/components/new-ui/Button';
import CustomInput from '~/components/new-ui/CustomInput';

import logoImage from '~/contexts/site-provider/flow-assets/images/logo-composed.svg';
import loginSlide1Image from '~/assets/images/login-slide-1.png';
import loginSlide2Image from '~/assets/images/login-slide-2.png';
import loginSlide3Image from '~/assets/images/slide-4.svg';
import unionBottom from '~/assets/images/union-bottom.svg';
import unionTop from '~/assets/images/union-top.svg';
import { StyledPasswordReset } from './styles';

class PasswordResetScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: getParam('id'),
      key: getParam('key'),
      password1: '',
      password1_invalid: false,
      show_password_1: false,
      password2: '',
      password2_invalid: false,
      show_password_2: false,
      formDisabled: false,
      passwordValidations: {
        hasMinLength: false,
        hasLowerLetter: false,
        hasUpperLetter: false,
        hasNumber: false,
        hasSpecialChar: false
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleToggleShowPassword(passwordNumber) {
    if (passwordNumber === 1) {
      this.setState({ show_password_1: !this.state.show_password_1 });
      return;
    }
    this.setState({ show_password_2: !this.state.show_password_2 });
  }

  handleChange({ target }) {
    const { name, value } = target;

    if (name === 'password1') {
      const validations = { ...this.state.passwordValidations };

      validations.hasMinLength = this.hasMinLength(value);
      validations.hasUpperLetter = this.hasUpperCase(value);
      validations.hasLowerLetter = this.hasLowerCase(value);
      validations.hasNumber = this.hasNumber(value);
      validations.hasSpecialChar = this.hasSpecialCharacter(value);

      this.setState({
        passwordValidations: { ...validations },
        [name]: value,
        password1_invalid: false,
        password2_invalid: false
      });
      return;
    }

    this.setState({
      [name]: value,
      password1_invalid: false,
      password2_invalid: false
    });
  }

  handleSubmit() {
    const { id, key, password1, password2 } = this.state;
    const { showLoading, closeLoading, showMessage } = this.props;

    if (password1 === password2) {
      showLoading();
      this.setState({ formDisabled: true });

      passwordReset(id, key, password1)
        .then(({ data }) => {
          showMessage('Você atualizou sua senha com sucesso', 'secondary');
          this.props.history.push('/sign-in');
        })
        .catch(e => {
          getError(e, res => {
            showMessage(res.message, 'danger');
            const newState = { formDisabled: false };

            res.fields.forEach(field => {
              newState[field] = true;
            });

            this.setState(newState);
          });
        })
        .finally(() => {
          this.setState({ formDisabled: false });
          closeLoading();
        });
    } else {
      // showMessage("As senhas precisam ser iguais!", "danger");
      this.setState({
        password1_invalid: true,
        password2_invalid: true
      });
    }
  }

  hasMinLength(string) {
    return string && string.length >= 8;
  }

  hasUpperCase(string) {
    const regex = /[A-Z]/;
    return regex.test(string);
  }

  hasLowerCase(string) {
    const regex = /[a-z]/;
    return regex.test(string);
  }

  hasNumber(string) {
    var regex = /\d/;
    return regex.test(string);
  }

  hasSpecialCharacter(string) {
    var regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    return regex.test(string);
  }

  hasInvalidParam(obj) {
    let count = 0;

    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        count++;
      }
    }
    return count < 3 || !this.state.passwordValidations.hasMinLength;
  }

  render() {
    return (
      <StyledPasswordReset>
        <div className="content-section">
          <div className="forms-wrapper">
            <div className="recovery-form">
              <Link to="/sign-in">
                <img src={logoImage} alt="USEFLOW" />
              </Link>
              <h2>Recupere seu acesso</h2>

              <Card>
                <h3>Crie uma nova senha</h3>
                <p>Siga as instruções abaixo</p>

                <CustomInput
                  label="Insira sua nova senha"
                  name="password1"
                  icon="lock-line"
                  placeholder="Senha"
                  value={this.state.password1}
                  onChange={this.handleChange}
                  type={this.state.show_password_1 ? 'text' : 'password'}
                  actions={
                    <button
                      className="show-password no-button"
                      onClick={() => this.handleToggleShowPassword(1)}
                    >
                      <Icon
                        name={this.state.show_password_1 ? 'not-eye' : 'eye'}
                      />
                    </button>
                  }
                  id="password1"
                  disabled={this.state.formDisabled}
                  onKeyUp={event => {
                    if (event.key === 'Enter' || event.keyCode === 13) {
                      // handleLegacyLogin();
                    }
                  }}
                />

                <CustomInput
                  label="Repita sua nova senha"
                  name="password2"
                  icon="lock-line"
                  placeholder="Senha"
                  value={this.state.password2}
                  onChange={this.handleChange}
                  type={this.state.show_password_2 ? 'text' : 'password'}
                  actions={
                    <button
                      className="show-password no-button"
                      onClick={() => this.handleToggleShowPassword(2)}
                    >
                      <Icon
                        name={this.state.show_password_2 ? 'not-eye' : 'eye'}
                      />
                    </button>
                  }
                  id="password2"
                  hasError={this.state.password2_invalid}
                  errorMessage={
                    this.state.password2_invalid ? 'Senhas não são iguais' : ''
                  }
                  disabled={this.state.formDisabled}
                  onKeyUp={event => {
                    if (event.key === 'Enter' || event.keyCode === 13) {
                      // handleLegacyLogin();
                    }
                  }}
                />

                <div className="password-checks">
                  <span>
                    <Icon
                      name={
                        this.state.passwordValidations.hasMinLength
                          ? 'circle-check'
                          : 'circle-close'
                      }
                      className={
                        this.state.passwordValidations.hasMinLength
                          ? 'success'
                          : ''
                      }
                    />
                    <p>Mínimo de 8 caracteres</p>
                  </span>
                  <span>
                    <Icon
                      name={
                        this.state.passwordValidations.hasUpperLetter
                          ? 'circle-check'
                          : 'circle-close'
                      }
                      className={
                        this.state.passwordValidations.hasUpperLetter
                          ? 'success'
                          : ''
                      }
                    />
                    <p>1 Letra maiúscula</p>
                  </span>
                  <span>
                    <Icon
                      name={
                        this.state.passwordValidations.hasLowerLetter
                          ? 'circle-check'
                          : 'circle-close'
                      }
                      className={
                        this.state.passwordValidations.hasLowerLetter
                          ? 'success'
                          : ''
                      }
                    />
                    <p>1 Letra minúscula</p>
                  </span>
                  <span>
                    <Icon
                      name={
                        this.state.passwordValidations.hasNumber
                          ? 'circle-check'
                          : 'circle-close'
                      }
                      className={
                        this.state.passwordValidations.hasNumber
                          ? 'success'
                          : ''
                      }
                    />
                    <p>1 Número</p>
                  </span>
                  <span>
                    <Icon
                      name={
                        this.state.passwordValidations.hasSpecialChar
                          ? 'circle-check'
                          : 'circle-close'
                      }
                      className={
                        this.state.passwordValidations.hasSpecialChar
                          ? 'success'
                          : ''
                      }
                    />
                    <p>1 Caractere especial (ex.: &- * /)</p>
                  </span>
                </div>

                <NewButton
                  className="continue-button"
                  onClick={() => this.handleSubmit()}
                  disabled={
                    this.formDisabled ||
                    this.hasInvalidParam(this.state.passwordValidations)
                    // ||
                    // this.state.password2.trim().length === 0
                  }
                >
                  Salvar senha
                </NewButton>
              </Card>
            </div>
          </div>
        </div>
        <div className="carousel-section">
          <img src={unionTop} alt="" className="union union-top" />
          <img src={unionBottom} alt="" className="union union-bottom" />

          <Carousel
            slideElements={[
              <StyledCarouselImage
                className="carousel-image"
                image={loginSlide1Image}
              >
                <SlideBalloon>
                  Ambiente digital para todos da sua organização
                </SlideBalloon>
              </StyledCarouselImage>,
              <StyledCarouselImage
                className="carousel-image"
                image={loginSlide2Image}
              >
                <SlideBalloon>
                  Colaboração e eficiência na conexão digital
                </SlideBalloon>
              </StyledCarouselImage>,
              <StyledCarouselImage
                className="carousel-image"
                image={loginSlide3Image}
              >
                <SlideBalloon>
                  Seguro, simples e acessível para os colaboradores
                </SlideBalloon>
              </StyledCarouselImage>
            ]}
          />
        </div>
      </StyledPasswordReset>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showLoading, closeLoading, showMessage }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(withRouter(PasswordResetScreen));
