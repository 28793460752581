import styled from 'styled-components';

export const StyledFeedbackHistoricItem = styled.div.attrs(() => ({
  className: 'feedback-historic-item'
}))`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  padding: 0px;

  .card {
    width: 100%;

    padding: 46px 54px;
    margin: 0px;
    margin-bottom: 16px;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    transition: all 0.5s;

    .date-and-status {
      width: max-content;

      background: var(--background-color);

      padding: 2px 8px;
      margin-bottom: 16px;

      font-size: 16px;
      border-radius: 4px;

      display: flex;
      align-items: center;

      span {
        width: 8px;
        height: 8px;

        background: var(--success-color);

        border-radius: 50%;
        margin-right: 8px;

        display: block;
      }

      i {
        color: var(--secondary-text-color);
        margin-right: 8px;
        font-size: 14px;
      }

      p {
        color: var(--text-color);
      }

      p.finished {
        color: var(--secondary-text-color);
      }
    }

    .title {
      max-width: 30ch;

      font-size: 20px;

      margin: 0px;
      margin-bottom: 6px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      color: var(--secondary-text-color);
      text-align: start;
      margin: 0px;
    }

    .action-side {
      gap: 8px;
      display: flex;
      flex-direction: column;

      .button {
        min-width: 170px;

        padding: 10px 14px;
        border-radius: 4px;
      }
    }

    &:hover {
      cursor: pointer;
      box-shadow: 1px 1px 16px var(--background-placeholder-color);
    }
  }

  @media (max-width: 720px) {
    .feedback-item {
      flex-direction: column;
      padding: 32px;

      & > div {
        width: 100%;
      }

      .action-side {
        margin-top: 16px;
      }
    }
  }
`;
