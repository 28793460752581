import styled from 'styled-components';

export const StyledTooltip = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }
`;

export const StyledCustomTooltip = styled.div`
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
  animation-fill-mode: forwards;

  background-color: var(--primary-color);
  color: var(--gray);
  width: ${({ width }) => width};
  font-size: 14px;
  border-radius: 6px;
  word-break: break-word;
  padding: 8px;
  margin: 12px;
`;
