import styled from 'styled-components';

export const StyledUseTerms = styled.div`
  .terms-text {
    margin-bottom: 64px;

    h2 {
      font-size: 32px;
      margin-bottom: 54px !important;
    }

    .text {
      font-size: 16px;
      margin-bottom: 16px;
    }

    .topic {
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 32px;
    }

    .list {
      li {
        font-size: 16px;
      }
    }
  }

  .switch-card {
    width: 100%;
    & > p {
      font-size: 14px;
      margin-bottom: 8px;
    }
    .card {
      width: 100%;
      background-color: #f6f6f6;
      border: none;
      border-radius: 12px;
      padding: 32px;
      margin-bottom: 32px;

      display: flex;
      align-items: center;

      p {
        font-size: 18px;
        margin-left: 16px;
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .button {
      width: max-content;
      background-color: #4746d8;
      padding: 20px 32px;
      border-radius: 100px;
      font-size: 16px;
      margin-bottom: 5px;

      display: flex;
      justify-content: center;
      align-items: center;

      .custom-loading {
        max-height: 20px;
      }
    }
  }
`;
