import React, { useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '~/components/icon';

import MoreScreen from '~/components/more-screen';

import './style.scss';
import { isMobileEmbeddedSession } from '~/utils';

const MoreButtonContainer = ({ children }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const onCloseMobileMenu = useCallback(() => {
    setMobileMenuVisible(false);
  }, []);

  const onOpenMobileMenu = useCallback(() => {
    setMobileMenuVisible(true);
  }, []);

  return children({
    mobileMenuVisible,
    onCloseMobileMenu,
    onOpenMobileMenu
  });
};

const MoreButton = ({
  mobileMenuVisible,
  onCloseMobileMenu,
  onOpenMobileMenu,
  organizationIsActiveSet
}) => {
  return (
    <>
      {mobileMenuVisible && (
        <MoreScreen
          onClose={onCloseMobileMenu}
          organizationIsActiveSet={organizationIsActiveSet}
        />
      )}
      <button type="button" className="nav-option" onClick={onOpenMobileMenu}>
        <span>
          <Icon name="more" />
          <span>Mais</span>
        </span>
      </button>
    </>
  );
};

const MobileNav = ({ organization, organizationIsActiveSet }) => {
  const isEmbedded = isMobileEmbeddedSession();
  if (isEmbedded) {
    return null;
  }

  const module_trail = organization
    ? organization.organizationsettings.module_trail
    : false;

  return (
    <MoreButtonContainer>
      {({ mobileMenuVisible, onCloseMobileMenu, onOpenMobileMenu }) => (
        <div className="mobile-nav">
          <NavLink
            to="/"
            className="nav-option"
            activeClassName="active"
            exact
            onClick={onCloseMobileMenu}
          >
            <span>
              <Icon name="home" />
              <span>Home</span>
            </span>
          </NavLink>

          {module_trail && (
            <NavLink
              to="/trails"
              className="nav-option"
              activeClassName="active"
              exact
              onClick={onCloseMobileMenu}
            >
              <span>
                <Icon name="trails" />
                <span>Trilhas</span>
              </span>
            </NavLink>
          )}

          <NavLink
            to="/history"
            className="nav-option"
            activeClassName="active"
            onClick={onCloseMobileMenu}
          >
            <span>
              <Icon name="history" />
              <span>Histórico</span>
            </span>
          </NavLink>

          <MoreButton
            mobileMenuVisible={mobileMenuVisible}
            onCloseMobileMenu={onCloseMobileMenu}
            onOpenMobileMenu={onOpenMobileMenu}
            organizationIsActiveSet={organizationIsActiveSet}
          />
        </div>
      )}
    </MoreButtonContainer>
  );
};

export default MobileNav;
