import styled from 'styled-components';

export const StyledSignIn = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;

  .content-section {
    width: 100%;
    height: 100%;
    z-index: 10;

    .forms-wrapper {
      width: 100%;
      height: 100%;
      background-color: var(--white-color);
      border-radius: 0px 20px 20px 0px;

      display: flex;
      justify-content: center;
      align-items: center;

      .back-button {
        background: none !important;
        color: #4746d8;
        justify-content: flex-start !important;
        text-align: start;
        i {
          margin-right: 8px;
          margin-top: -2px;
        }
      }

      .signin-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 425px;
        padding: 16px;

        form {
          width: 100%;
        }

        .recovery {
          background: none !important;
          color: #4746d8;
          justify-content: flex-end !important;
        }

        .button {
          width: 100%;
          background-color: #4746d8;
          padding: 20px 0px;
          border-radius: 100px;
          font-size: 16px;
          margin-bottom: 5px;

          display: flex;
          justify-content: center;
          align-items: center;

          .custom-loading {
            max-height: 20px;
          }

          &:hover {
            background-color: #363aa3;
          }
        }

        .client-step {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 280px;
            margin-bottom: 54px;
          }

          .client-field {
            margin-bottom: 32px;
          }
        }

        .sso-step {
          width: 100%;

          img {
            position: absolute;
            top: 20px;
            left: 40px;
            width: 132px;
          }

          h2 {
            text-align: center;
            margin-bottom: 54px !important;
          }

          .button {
            margin-bottom: 10px;
          }
        }

        .login-step {
          img {
            position: absolute;
            top: 20px;
            left: 40px;
            width: 132px;
          }

          h2 {
            text-align: center;
            margin-bottom: 54px !important;
          }

          .custom-input {
            margin-bottom: 16px;
          }

          .show-password {
            i {
              font-size: 20px;
              color: #4746d8;
            }
          }
        }
      }
    }
  }

  .carousel-section {
    width: max-content;
    height: 100%;
    margin-left: -20px;
    position: relative;

    .mobile-app-logo {
      position: absolute;
      top: 30px;
      left: 30px;
      z-index: 20;
      width: 144px;
      display: none;
    }

    .union {
      position: absolute;
      z-index: 10;
      width: 25%;

      &.union-top {
        top: 0;
        right: 0;
      }

      &.union-bottom {
        bottom: 0;
        left: 0;
      }
    }

    .carousel {
      min-width: 982px;
      height: 100%;
      max-height: 100%;

      .slider-wrapper {
        height: 100vh !important;
        ul {
          height: 100%;
          .carousel-slide {
            height: 100%;
            min-height: 100%;
            background: red;
          }
        }
      }
    }
  }

  @media (max-width: 1500px) {
    .carousel-section {
      .carousel {
        min-width: 700px;
      }
    }
  }

  @media (max-width: 1200px) {
    .carousel-section {
      .carousel {
        min-width: 500px;
      }
    }
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    height: auto;
    position: relative;

    .content-section {
      position: absolute;
      bottom: 0px;
      height: max-content;
      margin: 0px 16px;
      padding: 0px 16px;
      z-index: 30;

      .forms-wrapper {
        height: max-content;
        padding: 16px;
        border-radius: 20px 20px 0px 0px;

        .signin-form {
          max-width: 100%;
        }

        img {
          display: none;
        }

        h2 {
          display: none;
        }
      }
    }

    .carousel-section {
      height: 100vh;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      overflow: hidden;
      margin: 0;

      .mobile-app-logo {
        display: block;
      }
    }
  }
`;
