import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
// import { useDispatch } from 'redux-react-hook';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getFeedbackTargets } from '~/api/performance';

import { showMessage } from '~/store/ducks/messageBar';
import { closeDefaultModal } from '~/store/ducks/default-modal';

import SearchInput from '~/components/form/search-input';
import EmptyList from '~/components/empty-list';
import CustomLoading from '~/components/custom-loading';
import InfiniteScroll from '~/components/infinite-scroll';
import Managed from '~/components/managed';

import StyledFeedbackTargetsModal from './styles';
import { useTranslation } from 'react-i18next';

function FeedbackTargetsModal({
  orgId,
  targetId,
  message: { showMessage },
  history,
  closeDefaultModal
}) {
  const firstPage = 1;

  // const dispatch = useDispatch();
  const listWrapperRef = useRef(null);
  const listRef = useRef(null);
  const { t } = useTranslation();

  const [managedProfiles, setManagedProfiles] = useState([]);
  const [search, setSearch] = useState('');
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [count, setCount] = useState(0);
  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const getProfiles = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    getFeedbackTargets(orgId, targetId, filters, page)
      .then(response => {
        const { results } = response.data;

        if (
          results.length === 1 &&
          results[0].can_answer &&
          filters.name === ''
        ) {
          history.push(`/performance/${targetId}/feedback/${results[0].pk}`);
          closeDefaultModal();
          return;
        }

        setCount(response.data.count);
        setManagedProfiles(() => response.data.results);
        setNextPage(response.data.next);
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      getProfiles({
        name: value
      });
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const loadMoreProfiles = () => {
    setLoadingMore(true);

    const filters = {
      name: search
    };

    getFeedbackTargets(orgId, targetId, filters, currentPage + 1)
      .then(response => {
        setManagedProfiles(lastProfileList => [
          ...lastProfileList,
          ...response.data.results
        ]);
        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    getProfiles({
      name: search
    });
  }, []);

  return (
    <StyledFeedbackTargetsModal>
      <h3 className="title">{t('Feedback')}</h3>
      <p className="counter">
        <span>{count}</span>{' '}
        {t('colaborador', {
          count
        })}
      </p>

      <div className="content-wrapper">
        <SearchInput
          placeholder={t('Buscar por nome')}
          value={search}
          onChange={handleSearch}
        />

        {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

        <div
          className="managed-list-wrapper default-scroll"
          ref={listWrapperRef}
        >
          <div className="managed-list" ref={listRef}>
            {!loading &&
              managedProfiles.map(item => (
                <Managed
                  key={item.pk}
                  name={item.name}
                  department={item.department}
                  profile={item.profile}
                  performanceUrl={`/performance/${targetId}/feedback/${item.pk}`}
                  hasAnswer={item.has_answer}
                  disabled={!item.can_answer}
                />
              ))}

            {!loading && managedProfiles.length === 0 && (
              <EmptyList message={t('Nenhum colaborador encontrado.')} />
            )}
          </div>
        </div>

        {loadingMore && (
          <CustomLoading type="spin" height={36} width={36} fluid />
        )}

        <InfiniteScroll
          fetchMore={loadMoreProfiles}
          disabled={!nextPage || loading || loadingMore}
          scrollElement={listWrapperRef.current}
        />
      </div>
    </StyledFeedbackTargetsModal>
  );
}

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     {
//       showMessage
//     },
//     dispatch
//   );

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(FeedbackTargetsModal));
