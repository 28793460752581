export const Types = {
  OPEN: 'default-modal/OPEN',
  CLOSE: 'default-modal/CLOSE'
};

const defaultState = {
  open: false,
  content: '',
  maxWidth: '598px',
  onClose: null,
  hideCloseButton: false,
  disableBackgroundClick: false,
  padding: '40px 40px 0px',
  className: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.OPEN: {
      return {
        open: true,
        content: action.content,
        maxWidth: action.maxWidth,
        onClose: action.onClose,
        hideCloseButton: action.hideCloseButton,
        disableBackgroundClick: action.disableBackgroundClick,
        padding: action.padding,
        className: action.className
      };
    }
    case Types.CLOSE:
      return defaultState;
    default:
      return state;
  }
}

export const openDefaultModal = (
  content,
  maxWidth = '598px',
  onClose,
  hideCloseButton,
  disableBackgroundClick,
  padding = '40px 40px 0px',
  className = ''
) => ({
  type: Types.OPEN,
  content,
  maxWidth,
  padding,
  onClose,
  hideCloseButton,
  disableBackgroundClick,
  className
});

export const closeDefaultModal = () => ({
  type: Types.CLOSE
});
