import React from 'react';
import renderHTML from 'react-render-html';

import { StyledEditorialLine } from './styles';

function EditorialLine({ title, icon, selected, color, onClickEditorialLine }) {
  const worldsLenght = title.trim().split(/\s+/).length;

  return (
    <StyledEditorialLine iconColor={color}>
      <div
        onClick={onClickEditorialLine}
        className={`editorial-line-container ${selected ? 'selected' : ''}`}
        title={title}
      >
        <div className="editorial-icon">
          <div className="editorial-icon-wrapper">{renderHTML(icon)}</div>
        </div>
        <div
          className={
            worldsLenght === 1 ? 'editorial-name-single-word' : 'editorial-name'
          }
        >
          <p>{title}</p>
        </div>
      </div>
    </StyledEditorialLine>
  );
}

export default EditorialLine;
