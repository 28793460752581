import React, { useState, useEffect } from 'react';
import renderHTML from 'react-render-html';

import MarkAsDoneButton from '~/components/mark-as-done-button';

import { StyledReadText } from './styles';

function ReadText({
  title,
  text,
  isDone,
  canManuallyAnswer,
  markAsDone,
  stepsNeedValidation
}) {
  const [requestTimeout, setRequestTimeout] = useState(null);

  useEffect(() => {
    let timeout = null;
    if (!isDone && !stepsNeedValidation) {
      timeout = setTimeout(() => {
        markAsDone({
          ignoreLoading: true,
          ignoreAction: true
        });
      }, 3000);
      setRequestTimeout(timeout);
    }

    if (!isDone && stepsNeedValidation) {
      timeout = setTimeout(() => {
        markAsDone({
          ignoreLoading: true
        });
      }, 3000);
      setRequestTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <StyledReadText>
      <div className="step-section">
        <div className="title">
          <h3>{title}</h3>
          <MarkAsDoneButton
            isDone={isDone}
            canManuallyAnswer={canManuallyAnswer}
            onClick={() => {
              clearTimeout(requestTimeout);
              markAsDone({
                ignoreAction: true
              });
            }}
          />
        </div>
      </div>
      <div className="step-section">{renderHTML(text)}</div>
    </StyledReadText>
  );
}

export default ReadText;
