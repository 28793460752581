import styled from 'styled-components';

export const StyledEditorialLineCard = styled.div.attrs(() => ({
  className: 'editorial-line-card'
}))`
  width: 100%;
  max-width: 626px;
  min-height: 110px;
  top: var(--header-height);
  padding: 15px;
  border-radius: 0 0 4px 4px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 2;
  display: flex;
  align-items: center;

  .editorial-lines {
    position: relative;
    display: flex;
    padding: 0;
    overflow-x: scroll;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .button-control {
      background: var(--white-color);
      color: var(--primary-color);
      box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 14px;
      z-index: 3;
      padding: 0;
      min-height: 28px;
      min-width: 28px;
      border-radius: 50%;
      display: none;

      i {
        margin: 0;
      }

      &.left {
        left: 4px;
      }

      &.right {
        right: 4px;
      }
    }

    .lines-container {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
    }
    .lines-container::-webkit-scrollbar {
      display: none;
    }

    &.show-controls {
      padding: 0 36px;
      .button-control {
        display: block;
      }
    }
  }

  @media (max-width: 988px) {
    max-width: 100%;
  }

  @media (max-width: 956px) {
    max-width: 100%;

    .editorial-lines {
      padding: ${({ orgEditorialLinesCount }) =>
        orgEditorialLinesCount > 10 ? '0 36px !important' : '0 !important'};
      .button-control {
        display: ${({ orgEditorialLinesCount }) =>
          orgEditorialLinesCount > 10 ? 'block !important' : 'none !important'};
      }
    }
  }

  @media (max-width: 880px) {
    max-width: 100%;

    .editorial-lines {
      padding: ${({ orgEditorialLinesCount }) =>
        orgEditorialLinesCount > 9 ? '0 36px !important' : '0 !important'};
      .button-control {
        display: ${({ orgEditorialLinesCount }) =>
          orgEditorialLinesCount > 9 ? 'block !important' : 'none !important'};
      }
    }
  }

  @media (max-width: 804px) {
    max-width: 100%;

    .editorial-lines {
      padding: ${({ orgEditorialLinesCount }) =>
        orgEditorialLinesCount > 8 ? '0 36px !important' : '0 !important'};
      .button-control {
        display: ${({ orgEditorialLinesCount }) =>
          orgEditorialLinesCount > 8 ? 'block !important' : 'none !important'};
      }
    }
  }

  @media (max-width: 728px) {
    max-width: 100%;

    .editorial-lines {
      padding: ${({ orgEditorialLinesCount }) =>
        orgEditorialLinesCount > 7 ? '0 36px !important' : '0'};
      .button-control {
        display: ${({ orgEditorialLinesCount }) =>
          orgEditorialLinesCount > 7 ? 'block !important' : 'none'};
      }
    }
  }

  @media (max-width: 652px) {
    max-width: 100%;

    .editorial-lines {
      padding: ${({ orgEditorialLinesCount }) =>
        orgEditorialLinesCount > 6 ? '0 36px !important' : '0'};
      .button-control {
        display: ${({ orgEditorialLinesCount }) =>
          orgEditorialLinesCount > 6 ? 'block !important' : 'none'};
      }
    }
  }

  @media (max-width: 576px) {
    max-width: 100%;

    .editorial-lines {
      padding: ${({ orgEditorialLinesCount }) =>
        orgEditorialLinesCount > 5 ? '0 36px !important' : '0'};
      .button-control {
        display: ${({ orgEditorialLinesCount }) =>
          orgEditorialLinesCount > 5 ? 'block !important' : 'none'};
      }
    }
  }

  @media (max-width: 500px) {
    max-width: 100%;

    .editorial-lines {
      padding: ${({ orgEditorialLinesCount }) =>
        orgEditorialLinesCount > 4 ? '0 36px !important' : '0'};
      .button-control {
        display: ${({ orgEditorialLinesCount }) =>
          orgEditorialLinesCount > 4 ? 'block !important' : 'none'};
      }
    }
  }
`;
