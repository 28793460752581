import React from 'react';
import Card from '~/components/card';
import { useTranslation } from 'react-i18next';

const Unknown = props => {
  const { t } = useTranslation();

  return (
    <Card>
      <div className="card-body">
        <span>
          {t('Por favor, limpe o cache do seu navegador e atualize a página.')}
        </span>
      </div>
    </Card>
  );
};

export default Unknown;
