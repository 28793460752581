import React, { PureComponent } from 'react';
import Card from '~/components/card';
import { TaskHeader, TaskBody } from '~/components/task-helpers';
import { Form, Field } from 'react-final-form';
import { required } from '~/components/form/validators';
import Button from '~/components/button';
import Icon from '~/components/icon';
import { CommentBoxAndButtons, TaskCommentBox } from '~/components/comments';
import { addWololo } from '~/services/tasks';
import { WololoRewardLine } from '~/components/rewards';
import { convertFileToBase64 } from '~/services/convert';

const imageRequired = required('Você deve informar uma imagem');

class ImageTask extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      commentCounter: this.props.comment_counter
    };

    this.incrementCommentCounter = this.incrementCommentCounter.bind(this);
    this.answer = this.answer.bind(this);
  }

  incrementCommentCounter() {
    this.setState({ commentCounter: this.state.commentCounter + 1 });
  }

  setImage = image => {
    const { id, onFinish } = this.props;

    convertFileToBase64(image).then(imageInBase64 => {
      onFinish(id, {
        image: imageInBase64
      });
    });
  };

  answer = image => {
    const { id } = this.props;

    this.setState({ loading: true });

    const dataToWololo = new FormData();
    dataToWololo.append('image', image);

    addWololo(id, dataToWololo)
      .then(() => this.props.onFinish())
      .catch(() => this.setState({ loading: false }));
  };

  render() {
    const { isSubtask } = this.props;

    return (
      <Card className={`${isSubtask ? 'subtask' : ''}`}>
        {!isSubtask && (
          <TaskHeader
            {...this.props}
            icon_type={this.props.anonymous_answers ? 'anonymous' : 'post'}
          />
        )}
        <TaskBody {...this.props} />
        {!this.props.me_create_wololo && (
          <Form
            onSubmit={({ image }) => this.answer(image)}
            render={({ handleSubmit }) => (
              <div className="check-task-wrapper">
                <Field
                  name="image"
                  textarea
                  validate={imageRequired}
                  render={({
                    meta: { touched, error },
                    input: { onChange },
                    ...props
                  }) => (
                    <>
                      <div className="text-center">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={e =>
                            isSubtask
                              ? this.setImage(e.target.files[0])
                              : onChange(e.target.files[0])
                          }
                        />
                      </div>
                      {touched && error ? (
                        <p className="text-center danger-message">{error}</p>
                      ) : null}
                    </>
                  )}
                />

                {!isSubtask && (
                  <div className="text-center">
                    <Button
                      onClick={handleSubmit}
                      color={this.props.channel}
                      title="Enviar Imagem"
                      disabled={this.state.loading}
                    >
                      <Icon name={this.props.channel} marginRight />
                      <span>Enviar Imagem</span>
                    </Button>
                  </div>
                )}
              </div>
            )}
          />
        )}

        {this.props.can_comment ? (
          <CommentBoxAndButtons
            {...this.props}
            commentCounter={this.state.commentCounter}
            incrementCommentCounter={this.incrementCommentCounter}
            childCommentsProps="taskcomment_set"
            CommentBoxComponent={TaskCommentBox}
          />
        ) : null}

        {this.props.wololo_reward && <WololoRewardLine {...this.props} />}
      </Card>
    );
  }
}

export default ImageTask;
