import React from 'react';
import Tippy from '@tippyjs/react';

import { StyledTooltip, StyledCustomTooltip } from './styles';

const CustomTooltip = ({ content }) => {
  return (
    <StyledCustomTooltip className="custom-tooltip">
      <span>{content}</span>
    </StyledCustomTooltip>
  );
};

const Tooltip = ({
  children,
  content,
  arrow = true,
  offset = 10,
  trigger = 'mouseenter focus',
  placement = 'top',
  className = '',
  width = 'max-content'
}) => {
  return (
    <StyledTooltip className={`tooltip ${className}`.trim()} width={width}>
      <Tippy
        placement={placement}
        arrow={arrow}
        offset={offset}
        trigger={trigger}
        content={<CustomTooltip content={content} />}
      >
        {children}
      </Tippy>
    </StyledTooltip>
  );
};

export default Tooltip;
