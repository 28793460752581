import React from 'react';
import PostHeader from '~/components/post-header';

const types = {
  share: 'Compartilhar',
  comment: 'Comentar',
  like: 'Curtir',
  tweet: 'Tweet',
  retweet: 'Retweet',
  watch: 'Assistir',
  message: 'Envio de texto',
  image: 'Envio de imagem',
  movie: 'Vídeo',
  read: 'Ler',
  open_url: 'Abrir url',
  drive: 'Abrir url do drive',
  poll: 'Enviar resposta',
  multipoll: 'Enviar resposta',
  checkin: 'Checkin',
  questionnaire: 'Questionário',
  anonymous: 'Pesquisa anônima'
};

// const channels = {
//   profile: 'Perfil',
//   facebook: 'Facebook',
//   whatsapp: 'Whatsapp',
//   instagram: 'Instagram',
//   linkedin: 'Linkedin',
//   twitter: 'Twitter',
//   youtube: 'Youtube',
//   publishing: 'Publicação',
//   questionnaire: 'Questionário'
// };

const TaskHeader = ({
  channel,
  type,
  wololo_points,
  organization,
  close_at,
  me_create_wololo,
  icon_type,
  me_trail
}) => {
  return (
    <PostHeader
      info={types[type]}
      points={wololo_points}
      organization={organization}
      meTrail={me_trail}
      expire={close_at}
      me_create_wololo={me_create_wololo}
      icon_type={icon_type}
      channel={channel}
    />
  );
};

export default TaskHeader;
