import { useState, useEffect } from 'react';

import { getPages } from '~/api/organizations';

const usePages = (organizationId, modulePages) => {
  const [pages, setPages] = useState(null);

  useEffect(() => {
    if (organizationId && modulePages && modulePages.is_active) {
      getPages(organizationId)
        .then(response => {
          setPages(response.data);
        })
        .catch(() => {
          setPages([]);
        });
    }

    if (!modulePages || !modulePages.is_active) {
      setPages([]);
    }
  }, [organizationId]);

  return pages;
};

export default usePages;
