import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { getPDI, getTimeline, updatePDI, createComment } from '~/api/pdi';

import { showMessage } from '~/store/ducks/messageBar';

import Button from '../button';
import StatusSelect from '~/components/status-select';
import EditableField from '~/components/editable-field';
import EmptyList from '~/components/empty-list';
import TimelineStatus from '~/components/timeline-status';
import SkeletonLoading from '~/components/skeleton-loading';
import CustomLoading from '~/components/custom-loading';
import InfiniteScroll from '~/components/infinite-scroll';
import CommentField from '~/components/comment-field';

import { StyledPDIDetailsModal } from './styles';

function PDIDetailsModal({
  orgId,
  pdiId,
  participantId,
  admin,
  changeContentCallback,
  showMessage,
  isAdminOrManager
}) {
  const firstPage = 1;
  const commentCharLimit = 1000;

  const listWrapperRef = useRef(null);
  const { t } = useTranslation();

  const [PDIData, setPDIData] = useState({});
  const [firstRenderloading, setFirstRenderLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [timeline, setTimeline] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [timelineLoading, setTimelineLoading] = useState(true);
  const [timelineLoadingMore, setTimelineLoadingMore] = useState(false);

  const [newStatus, setNewStatus] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({});
  const [statusChanged, setStatusChanged] = useState(false);

  const [deadline, setDeadline] = useState(0);
  const [deadlineInEdit, setDeadlineInEdit] = useState(false);
  const [deadlineIsEditable, setDeadlineIsEditable] = useState(false);

  const [comment, setComment] = useState('');
  const [commentLoading, setCommentLoading] = useState(false);

  const loadMoreTimelineItems = () => {
    setTimelineLoadingMore(true);

    getTimeline(orgId, pdiId, currentPage + 1)
      .then(response => {
        setTimeline(lastTimelineList => [
          ...lastTimelineList,
          ...response.data.results
        ]);
        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao fazer a busca'), 'danger', 3000);
      })
      .finally(() => {
        setTimelineLoadingMore(false);
      });
  };

  const loadTimeline = page => {
    setTimelineLoading(true);

    getTimeline(orgId, pdiId, page)
      .then(response => {
        setTimeline(response.data.results);
        setNextPage(response.data.next);
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao exibir a timeline'), 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
        setTimelineLoading(false);
      });
  };

  const loadContent = () => {
    setLoading(true);

    getPDI(orgId, pdiId, participantId)
      .then(response => {
        const {
          pillar,
          competence,
          gap,
          action,
          learning_model,
          deadline,
          status,
          status_list,
          is_late,
          is_deadline_editable,
          finish_at
        } = response.data;

        setPDIData({
          pillar,
          competence,
          gap,
          action,
          learning_model,
          is_late,
          finish_at
        });
        setDeadline(deadline);
        setDeadlineIsEditable(is_deadline_editable);
        setCurrentStatus({
          status: status.value,
          description: status.text
        });
        setNewStatus({
          status: status.value,
          description: status.text
        });
        setStatusList(() => [
          ...status_list.map(item => ({
            status: item.value,
            description: item.text
          })),
          {
            status: status.value,
            description: status.text
          }
        ]);

        loadTimeline(firstPage);
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao exibir o PDI'), 'danger', 3000);
        setLoading(false);
      })
      .finally(() => {
        setFirstRenderLoading(false);
      });
  };

  const changePDI = data => {
    setLoading(true);

    updatePDI(orgId, pdiId, participantId, data, admin)
      .then(() => {
        loadContent();
        setStatusChanged(false);
        changeContentCallback();
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao atualizar o PDI'), 'danger', 3000);
        setLoading(false);
      });
  };

  const createNewComment = data => {
    setCommentLoading(true);

    createComment(orgId, pdiId, data, admin)
      .then(() => {
        loadContent();
        changeContentCallback();
        setComment('');
      })
      .catch(() => {
        showMessage(
          t('Ocorreu um erro ao registrar o comentário'),
          'danger',
          3000
        );
        setCommentLoading(false);
      })
      .finally(() => {
        setCommentLoading(false);
      });
  };

  const handleChangeStatus = () => {
    changePDI({
      status: newStatus.status,
      deadline
    });
  };

  const changeStatus = newStatus => {
    setStatusChanged(false);
    setNewStatus(newStatus);

    if (currentStatus.status !== newStatus.status) {
      setStatusChanged(true);
    }
  };

  const changeDeadline = () => {
    changePDI({
      status: currentStatus.status,
      deadline: deadline === '' ? 0 : deadline
    });
  };

  const handleDeadlineChange = event => {
    const { value } = event.target;
    setDeadline(value);
  };

  const newComment = newComment => {
    if (comment.length <= 0 || comment.trim().length <= 0) {
      setComment('');
      return;
    }
    createNewComment({
      description: newComment
    });
  };

  const handleComment = event => {
    if (event.target.value.length <= commentCharLimit) {
      setComment(event.target.value);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <StyledPDIDetailsModal>
      <h3>{t('Detalhes do PDI')}</h3>

      <div className="details-table">
        <header className="details-header">
          <div>
            <div>
              <StatusSelect
                currentStatus={newStatus}
                statusList={statusList.filter(
                  status => status.status !== newStatus.status
                )}
                onChangeStatus={changeStatus}
                loading={loading}
              />
              {PDIData.is_late && currentStatus.status !== 'canceled' && (
                <p className="is-late-message">{t('Atrasado')}</p>
              )}
            </div>
            {statusChanged && (
              <Button
                color="primary"
                onClick={handleChangeStatus}
                disabled={loading}
              >
                {t('Salvar')}
              </Button>
            )}
          </div>
          {statusChanged && !isAdminOrManager ? (
            <p>
              {t(
                'Verifique se a alteração de status foi realizada corretamente pois, uma vez salva, esta ação é irreversível.'
              )}
            </p>
          ) : (
            statusChanged &&
            isAdminOrManager &&
            newStatus.status === 'canceled' && (
              <p>
                {t(
                  'Verifique se a alteração de status foi realizada corretamente pois, uma vez salva, esta ação é irreversível.'
                )}
              </p>
            )
          )}
        </header>
        <div className="details-content">
          <div className="pdi-details-side default-scroll">
            <div className="detail-info">
              <h3>{t('Pilar')}</h3>
              {firstRenderloading && <SkeletonLoading type="text" />}
              {!firstRenderloading && <p>{PDIData.pillar}</p>}
            </div>

            <div className="detail-info">
              <h3>{t('Competência')}</h3>
              {firstRenderloading && <SkeletonLoading type="text" />}
              {!firstRenderloading && <p>{PDIData.competence || '-'}</p>}
            </div>

            <div className="detail-info">
              <h3>{t('Ponto a desenvolver')}</h3>
              {firstRenderloading && <SkeletonLoading type="text" />}
              {!firstRenderloading && <p>{PDIData.gap || '-'}</p>}
            </div>

            <div className="detail-info">
              <h3>{t('Ação')}</h3>
              {firstRenderloading && <SkeletonLoading type="paragraph" />}
              {!firstRenderloading && <p>{PDIData.action || '-'}</p>}
            </div>

            <div className="detail-info-line">
              <div className="detail-info">
                <h3>{t('Tipo de experiência')}</h3>
                {firstRenderloading && <SkeletonLoading type="short-line" />}
                {!firstRenderloading && <p>{PDIData.learning_model}</p>}
              </div>

              <div className="detail-info">
                <h3>{t('Prazo em semanas')}</h3>
                {firstRenderloading && <SkeletonLoading type="short-line" />}
                {!firstRenderloading && (
                  <EditableField
                    value={deadline}
                    inEdit={deadlineInEdit}
                    setInEdit={setDeadlineInEdit}
                    inputType="number"
                    inputPlaceholder={t('Digite o novo prazo')}
                    step="1"
                    min="0"
                    onChange={handleDeadlineChange}
                    onBlur={changeDeadline}
                    disabled={!deadlineIsEditable}
                  />
                )}
              </div>
            </div>

            <div className="detail-info">
              <h3>{t('Fim do PDI')}</h3>
              {firstRenderloading && <SkeletonLoading type="text" />}
              {!firstRenderloading && (
                <p>{moment(PDIData.finish_at).format('DD/MM/YYYY')}</p>
              )}
            </div>
          </div>
          <div className="timeline-side">
            <div
              className="timeline-list-wrapper default-scroll"
              ref={listWrapperRef}
            >
              <div className="timeline">
                {!timelineLoading && timeline.length === 0 && (
                  <EmptyList message={t('Nenhuma atividade')} hideIcon />
                )}

                {!timelineLoading &&
                  timeline.map(timelineItem => (
                    <TimelineStatus
                      key={timelineItem.id}
                      owner={timelineItem.owner}
                      type={timelineItem.type}
                      description={timelineItem.description}
                      status={timelineItem.status.text}
                      createdAt={timelineItem.created_at}
                    />
                  ))}

                {(timelineLoading || timelineLoadingMore) && (
                  <CustomLoading type="spin" height={36} width={36} fluid />
                )}
              </div>
              <InfiniteScroll
                fetchMore={loadMoreTimelineItems}
                disabled={!nextPage || timelineLoading || timelineLoadingMore}
                scrollElement={listWrapperRef.current}
              />
            </div>

            <CommentField
              value={comment}
              onChange={handleComment}
              onSend={() => newComment(comment)}
              loading={commentLoading}
              disabled={loading || commentLoading}
            />
          </div>
        </div>
      </div>
    </StyledPDIDetailsModal>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PDIDetailsModal);
