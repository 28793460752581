import React, { useRef, useEffect, useState } from 'react';
import NavOption from '~/components/nav-option';
import './style.scss';
import { useScrollTop } from '../search-area/search-aside';
import ToggleOrganization from '~/components/toggle-organization';
// import avatar from '../../assets/images/avatar.png';
import { getOrganizationStored } from '~/utils';
const useWindowHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const resize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return height;
};

const NavMenu = ({ name, options, organizations }) => {
  const scrollTop = useScrollTop();
  const lastScrollTop = useRef();
  const navMenu = useRef();
  const currentMarginTop = useRef(0);
  const windowHeight = useWindowHeight();
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [navMenuHeight, setNavMenuHeight] = useState(null);

  const handleNavMenuResize = entries => {
    setNavMenuHeight(entries[0].contentRect.height);
  };

  // I needed to create a ResizeObserver to keep actively "listening" to changes on the navMenu height,
  // in order to compare it to screen height and set "reduced-nav" class on navMenu if needed
  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleNavMenuResize);

    if (navMenu.current) {
      resizeObserver.observe(navMenu.current);
    }

    // clean up function
    // ResizeObserver no longer observes navMenu
    return () => {
      if (navMenu.current) {
        resizeObserver.unobserve(navMenu.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  // if windowHeight (screen height) or navMenuHeight change
  // and if windowHeight minus footer height is less than navMenuHeight
  // set "reduced-nav" to nav-menu, in order to set a scroll to it
  useEffect(() => {
    if (windowHeight - 120 < navMenuHeight) {
      document.querySelector('.nav-menu-aside').classList.add('reduced-nav');
    } else {
      document.querySelector('.nav-menu-aside').classList.remove('reduced-nav');
    }
  }, [windowHeight, navMenuHeight]);

  useEffect(() => {
    let diff;

    if (!lastScrollTop.current) {
      diff = scrollTop;
    } else {
      diff = lastScrollTop.current - scrollTop;
    }

    // TODO encontrar uma forma de pegar esses valores
    // pq se alterar o css e não alterar aqui, pode dar ruim.
    const headerHeight = 64;
    const marginBottom = 10;

    const visibleAreaHeight = windowHeight - headerHeight - marginBottom;
    const maxMargin = Math.max(
      navMenu.current.offsetHeight - visibleAreaHeight,
      0
    );

    currentMarginTop.current = Math.min(
      Math.max(currentMarginTop.current + diff, -maxMargin),
      0
    );
    lastScrollTop.current = scrollTop;
    navMenu.current.style.marginTop = currentMarginTop.current + 'px';
  }, [scrollTop, windowHeight]);

  useEffect(() => {
    const storedOrg = getOrganizationStored(organizations);

    if (!storedOrg) return;

    const { module_trail, module_forum } = storedOrg.organizationsettings;

    setFilteredOptions(() => {
      let filteredOptions = [...options];

      if (!module_trail) {
        filteredOptions = options.filter(opt => opt.name !== 'Trilhas');
      }

      if (!module_forum) {
        filteredOptions = filteredOptions.filter(
          opt => opt.name !== 'Grupos de Trabalho'
        );
      }
      let org = getOrganizationStored(organizations);
      if (org != null) {
        if (org.id !== 57) {
          filteredOptions = filteredOptions.filter(
            opt => opt.name !== 'Câmbio'
          );
        }
      }

      return filteredOptions;
    });
  }, [options]);

  return (
    <div className="nav-menu" ref={navMenu}>
      <ul className="nav">
        <li>
          <ToggleOrganization organizations={organizations} />
        </li>
        {/* <li>
          <header className="nav-header">
            <img src={avatar} alt="Avatar" />
            {name}
          </header>
        </li> */}
        {filteredOptions.map((opt, i) => (
          <NavOption key={`${opt.name}${i}`} {...opt} />
        ))}
      </ul>
    </div>
  );
};

export default NavMenu;
