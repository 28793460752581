import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';

import { getFeedback, updateFeedback, sendFeedback } from '~/api/feedback';

import { showMessage } from '~/store/ducks/messageBar';
import {
  openDefaultModal,
  closeDefaultModal
} from '~/store/ducks/default-modal';

import { deepClone } from '~/utils';

import Fab from '~/components/fab';
import Card from '~/components/card';
import Icon from '~/components/icon';
import Button from '~/components/button';
import ConfirmModal from '~/components/confirm-modal';
import CustomHeader from '~/components/custom-header';
import SuspendedMenu from '~/components/suspended-menu';
import CustomLoading from '~/components/custom-loading';
import CollaboratorCard from './components/collaborator-card';
import FeedbackFormQuestion from '~/components/feedback-form-question';
import PendingModificationModal from '~/components/pending-modification-modal';

import feedbackHeaderImage from '~/assets/images/feedback-header-image.svg';

import { StyledFeedbackForm } from './styles';

let changeControl = {};

function FeedbackForm({
  organization,
  message: { showMessage },
  closeDefaultModal,
  location,
  history
}) {
  const { activeOrganizationId } = organization;
  const {
    targetId = null,
    feedbackId = null,
    isAccessedThroughHistory = false
  } = location.state || {};

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Feedback states
  const [feedbackData, setFeedbackData] = useState({});
  const [questions, setQuestions] = useState([
    { pk: 1, title: t('Pontos positivos'), can_answer: true, answer: '' },
    { pk: 2, title: t('Pontos negativos'), can_answer: true, answer: '' },
    { pk: 3, title: t('Fraquezas'), can_answer: true, answer: '' },
    { pk: 4, title: t('Oportunidades'), can_answer: true, answer: '' },
    {
      pk: 5,
      title: t('Observações - Líder'),
      can_answer: true,
      answer: ''
    },
    { pk: 6, title: t('Observações - Liderado'), can_answer: false, answer: '' }
  ]);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [feedbackReadOnly, setFeedbackReadOnly] = useState(true);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handlePendingChanges = route => {
    dispatch(
      openDefaultModal(
        <PendingModificationModal onAction={() => history.push(route)} />
      )
    );
  };

  const handleBack = () => {
    const currentState = [...questions];
    const backUrl = '/development/feedback';

    if (JSON.stringify(currentState) !== JSON.stringify(changeControl)) {
      handlePendingChanges(backUrl);
      return;
    }

    if (isAccessedThroughHistory) {
      history.goBack();
    }

    history.push(backUrl);
  };

  const getSaveData = () => {
    const questionList = questions.map(question => ({
      question_id: question.pk,
      text: question.answer || ''
    }));

    return {
      answersquestions: questionList
    };
  };

  const handleSave = () => {
    setSaveLoading(true);

    const data = getSaveData();

    updateFeedback(activeOrganizationId, targetId, feedbackId, data)
      .then(() => {
        showMessage(t('Feedback salvo!'), 'success', 1000);

        changeControl = deepClone([...questions]);
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao salvar o feedback.'), 'danger', 3000);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const confirmSend = () => {
    dispatch(
      openDefaultModal(
        <ConfirmModal
          title={t('Enviar feedback?')}
          description={t('Tem certeza que deseja enviar este feedback?')}
          confirmButtonText={t('Enviar')}
          onConfirm={handleSend}
        />
      )
    );
  };

  const handleSend = () => {
    setSendLoading(true);
    const data = getSaveData();
    closeDefaultModal();

    updateFeedback(activeOrganizationId, targetId, feedbackId, data)
      .then(() => {
        changeControl = deepClone([...questions]);
        sendFeedback(activeOrganizationId, targetId, feedbackId)
          .then(() => {
            showMessage(t('Seu feedback foi enviado.'), 'success');
            history.push('/development/feedback');
          })
          .catch(error => {
            if (error.response.status === 400) {
              showMessage(error.response.data.errors[0], 'danger');
              return;
            }

            history.push('/unexpected-error');
          })
          .finally(() => {
            setSendLoading(false);
          });
      })
      .catch(() => {
        showMessage(t('Ocorreu um erro ao enviar o feedback.'), 'danger', 3000);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  useEffect(() => {
    getFeedback(
      activeOrganizationId,
      targetId,
      feedbackId,
      isAccessedThroughHistory
    )
      .then(response => {
        const { questions: questionList } = response.data;

        setQuestions(questionList);

        changeControl = deepClone(questionList);

        delete response.data['questions'];
        setFeedbackData(response.data);
        setFeedbackReadOnly(!response.data.can_edit);
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          showMessage(t('Este feedback não está disponível.'), 'danger', 3000);
          history.push('/development/feedback');
          return;
        }

        history.push('/unexpected-error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <StyledFeedbackForm>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              {t('Voltar')}
            </Button>
          </div>
          {!feedbackReadOnly && (
            <div className="right-side">
              <Button
                color="primary"
                className="outline"
                disabled={loading || saveLoading || sendLoading}
                onClick={handleSave}
              >
                {!saveLoading && t('Salvar')}
                {saveLoading && (
                  <CustomLoading type="spin" height={16} width={16} fluid />
                )}
              </Button>
              <Button
                color="primary"
                disabled={loading || saveLoading || sendLoading}
                onClick={confirmSend}
              >
                {!sendLoading && t('Enviar')}
                {sendLoading && (
                  <CustomLoading
                    type="spin"
                    height={16}
                    width={16}
                    fluid
                    color="#FFFFFF"
                  />
                )}
              </Button>

              <SuspendedMenu>
                <Button
                  color="primary"
                  className="outline"
                  disabled={loading || saveLoading || sendLoading}
                  onClick={handleSave}
                >
                  {!saveLoading && t('Salvar')}
                  {saveLoading && (
                    <CustomLoading type="spin" height={16} width={16} fluid />
                  )}
                </Button>
                <Button
                  color="primary"
                  className="outline"
                  disabled={loading || saveLoading || sendLoading}
                  onClick={confirmSend}
                >
                  {!sendLoading && t('Enviar')}
                  {sendLoading && (
                    <CustomLoading
                      type="spin"
                      height={16}
                      width={16}
                      fluid
                      color="#FFFFFF"
                    />
                  )}
                </Button>
              </SuspendedMenu>
            </div>
          )}
        </div>
      </CustomHeader>

      <div className="page-content">
        {!loading && (
          <>
            <div className="feedback-header">
              {/* TODO: alterar levando em consideração o tipo avulso */}
              {/* {!feedbackReadOnly && (
                <p className="finish-date">
                  {t('Envio disponível até')}{' '}
                  {moment(feedbackData.finish_at, 'DD/MM/YYYY').format(
                    'DD/MM/YYYY'
                  )}
                </p>
              )} */}

              <CollaboratorCard
                name={feedbackData.managed.name}
                avatar={feedbackData.managed.avatar_128x0}
                department={feedbackData.department}
                profile={feedbackData.managed.profile}
                manager={feedbackData.manager}
              >
                {!isAccessedThroughHistory &&
                  feedbackData.can_view_maneged_answer && (
                    <div className="feedback-historic">
                      <Button
                        className="outline"
                        to={`/development/feedback/${targetId}/historic/`}
                      >
                        {t('Ver histórico')}
                      </Button>
                    </div>
                  )}
              </CollaboratorCard>
            </div>

            <Card className="feedback-form">
              <div className="custom-form-header">
                <img src={feedbackHeaderImage} alt="Feedback" />
                <div className="text-info">
                  <h1 className="title">{t('Registre seu feedback')}</h1>
                  <p className="description">
                    {t(
                      'Preencha detalhadamente as suas considerações em cada item e realize um feedback construtivo.'
                    )}
                  </p>
                </div>
              </div>

              {questions.map((question, index) => (
                <FeedbackFormQuestion
                  key={question.pk}
                  currentQuestion={question}
                  questionNumber={index + 1}
                  questions={questions}
                  setQuestions={setQuestions}
                  questionType={question.type}
                  isHistoryForm={isAccessedThroughHistory}
                  disabled={!question.can_answer || feedbackReadOnly}
                />
              ))}
            </Card>

            <Fab>
              <Button
                color="dark"
                className="rounded go-to-top"
                onClick={scrollToTop}
              >
                <Icon name="arrow-up" />
              </Button>
            </Fab>
          </>
        )}

        {loading && <CustomLoading type="spin" height={56} width={56} fluid />}
      </div>
    </StyledFeedbackForm>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeedbackForm));
