import { useState, useEffect } from 'react';

// this hook helps to block navigation when the user is filling a form and didn't submit
// example of usage at src/screens/my-profile

export function useFormDirtyCheck(history) {
  const [isDirty, setIsDirty] = useState(false);
  const [pageToNavigate, setPageToNavigate] = useState(null);

  // if form is not dirty (not changed) and pageToNavigate has a value, navigate!
  useEffect(() => {
    if (isDirty === false && pageToNavigate) {
      history.push(pageToNavigate);
      setPageToNavigate(null);
    }
  }, [isDirty, pageToNavigate]);

  // prevent user from reload / close page if form isDirty
  useEffect(() => {
    const unloadListener = event => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', unloadListener);

    return () => {
      window.removeEventListener('beforeunload', unloadListener);
    };
  }, [isDirty]);

  return {
    isDirty,
    setIsDirty,
    setPageToNavigate
  };
}
