import api from './api';

export const getFeedbacks = (orgId, filters, page) => {
  const url = `/feedback/organization/${orgId}/me/`;

  return api.get(url, {
    params: {
      search: filters.name,
      status: filters.status,
      ordering: filters.ordering,
      page
    }
  });
};

export const getFeedbacksHistoric = (orgId, targetId, filters, page) => {
  const url = `/feedback/organization/${orgId}/target/${targetId}/historic/`;

  return api.get(url, {
    params: {
      search: filters.name,
      feedback__status: filters.status,
      ordering: filters.ordering,
      page
    }
  });
};

export const getFeedbackPreTargets = (
  orgId,
  targetId,
  feedbackId,
  filters = {},
  page
) =>
  api.get(`/feedback/${feedbackId}/organization/${orgId}/target/${targetId}/`, {
    params: {
      ordering: filters.ordering,
      search: filters.name,
      status: filters.status,
      page
    }
  });

export const getPerformanceHistoric = (orgId, filters, page, roleId) => {
  const url = `/organization/${orgId}/performance/${roleId}/historic/`;
  return api.get(url, {
    params: {
      departments: filters.departments,
      order: filters.order,
      page
    }
  });
};

export const getFeedback = (
  orgId,
  targetId,
  feedbackId,
  isAccessedThroughHistory
) => {
  if (isAccessedThroughHistory) {
    return api.get(
      `/feedback/${feedbackId}/organization/${orgId}/target/${targetId}/preview/`
    );
  }
  return api.get(
    `/feedback/${feedbackId}/organization/${orgId}/target/${targetId}/answer/`
  );
};

export const updateFeedback = (orgId, targetId, feedbackId, data) =>
  api.put(
    `/feedback/${feedbackId}/organization/${orgId}/target/${targetId}/answer/`,
    data
  );

export const sendFeedback = (orgId, targetId, feedbackId) =>
  api.post(
    `/feedback/${feedbackId}/organization/${orgId}/target/${targetId}/answer/`
  );
