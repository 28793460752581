import React from 'react';

import { StyledButton } from './styles';

function Button({ children, className, theme = '', ...rest }) {
  return (
    <StyledButton
      className={`new-button ${theme} ${className}`.trim()}
      {...rest}
    >
      {children}
    </StyledButton>
  );
}

export default Button;
