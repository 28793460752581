import React from 'react';
import renderHTML from 'react-render-html';

import Button from '~/components/button';
import Icon from '~/components/icon';

import StyledPageControl from './styles';

function PageControl({
  pageTitle,
  pageDescription,
  counterLabel,
  onNext,
  onPrevious,
  nextDisabled,
  previousDisabled,
  disabled
}) {
  return (
    <StyledPageControl>
      <div className="page-control-header">
        <div className="title">
          <h1>{pageTitle}</h1>
        </div>
        <div className="control">
          <p>{counterLabel}</p>
          <div className="actions">
            <Button
              color="primary"
              className="outline"
              onClick={onPrevious}
              disabled={disabled || previousDisabled}
            >
              <Icon name="previous" />
            </Button>

            <Button
              color="primary"
              className="outline"
              onClick={onNext}
              disabled={disabled || nextDisabled}
            >
              <Icon name="next" />
            </Button>
          </div>
        </div>
      </div>
      {pageDescription && (
        <div className="description">{renderHTML(pageDescription)}</div>
      )}
    </StyledPageControl>
  );
}

export default PageControl;
