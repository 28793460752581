import axios from 'axios';

const countriesApi = axios.create({
  baseURL: process.env.REACT_APP_COUNTRIES_API_BASE_URL,
  headers: {
    'X-CSCAPI-KEY': process.env.REACT_APP_COUNTRIES_API_KEY
  }
});

export const getCountries = () => countriesApi.get('/v1/countries');

export const getStatesByCountry = countryIso =>
  countriesApi.get(`/v1/countries/${countryIso}/states`);

export const getCitiesByCountryAndState = (countryIso, stateIso) =>
  countriesApi.get(`/v1/countries/${countryIso}/states/${stateIso}/cities`);
