import i18next from 'i18next';
import queryString from 'query-string';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';
import { isAuth, login, logoff } from '~/services/auth';
import { setOrganization } from '~/store/ducks/organization';
import { setMobileEmbeddedSession, storeOrganizationId } from '~/utils';

const MobileRedirectScreen = ({ history, location: { search } }) => {
  const dispatch = useDispatch();

  const setOrganizationId = organizationId => {
    dispatch(setOrganization(organizationId));
    storeOrganizationId(organizationId);
  };

  useEffect(() => {
    const { token, org_id, to, embedded, lang } = queryString.parse(search);

    // no required params? no way!
    if (!token || !org_id || !to) {
      history.push('/');
      return;
    }

    // auth user with token received
    if (isAuth()) {
      logoff();
    }
    login(token);

    // set current organization
    setOrganizationId(org_id);

    // "embedded session" is a magic constant that if it is active,
    // some elements from UI will be stripped to better integrate with mobile
    if (embedded) {
      setMobileEmbeddedSession(embedded);
    }

    // manually attempt to set language, it should be available
    if (lang) {
      i18next.changeLanguage(lang);
    }

    // send user to route
    history.push(to);
  }, []);

  return null;
};

export default withRouter(MobileRedirectScreen);
