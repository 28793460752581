import React, { useState } from 'react';

import downloadFile from '~/services/download';

import { PublicFooter, PublicHeader, PublicWrapper } from '~/components/public';

import './style.scss';

function AppDownload() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const download = fileUrl => {
    setLoading(true);
    setError(false);

    downloadFile(fileUrl)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Flow.apk');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(e => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PublicWrapper>
      <PublicHeader />
      <div className="download-screen">
        <div className="text-content">
          <h1>Obrigado por baixar o Flow!</h1>
          {error && <p>Falha ao realizar o download. Tente novamente.</p>}
          {!loading && (
            <p>
              {' '}
              <button
                className="link"
                onClick={() =>
                  download('https://s3.amazonaws.com/lhh-storage/Flow-LHH.apk')
                }
              >
                Clique aqui
              </button>{' '}
              para baixar o app.
            </p>
          )}
          {loading && <p>Fazendo download...</p>}
        </div>
      </div>
      <PublicFooter />
    </PublicWrapper>
  );
}

export default AppDownload;
